























































import { Navigation } from "@/models/navigation";
import { Rating } from "@/models/rating";
import { ScrollState } from "@/models/scroll-state";
import { TasteSession } from "@/models/taste-session";
import { UrlParam } from "@/models/url-param";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import ProgressBar from "./ProgressBar.vue";

@Component({
  components: {
    ProgressBar
  }
})
export default class ProgressBarWrapper extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  private ratings: Rating[] = [];

  private readonly PROGRESSBAR_FINISHED_AT = 80;
  private readonly VALUE_OF_LIKE = 25;
  private readonly MAX_PROGRESS_WITH_DISLIKES = 50;

  mounted() {
    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      this.ratings = ratings.filter(it => !it.isNeutral());
    });
  }

  public progressBarFinished() {
    return this.progress() >= this.PROGRESSBAR_FINISHED_AT;
  }

  public progress(): number {
    let progress = 5; //design reason not to start at 0

    progress =
      progress +
      this.ratings.filter(it => it.liked()).length * this.VALUE_OF_LIKE;
    progress =
      progress +
      this.functionDivergesTo(
        this.ratings.filter(it => it.disliked()).length,
        this.MAX_PROGRESS_WITH_DISLIKES
      );

    if (progress > 100) {
      return 100;
    }
    return Math.round(progress);
  }

  //https://www.desmos.com/calculator/weteyzldgg
  functionDivergesTo(x: number, divergesTo: number, weight = 0.2) {
    return 1 - (Math.exp(-x * weight) / 1) * divergesTo + (divergesTo - 1);
  }

  public reset() {
    this.$confirmDialogService
      .show({
        title: this.$t("navigation.restart.confirmQuestion").toString(),
        message: this.$t("navigation.restart.confirmHinttext").toString(),
        okButton: this.$t("navigation.restart.confirmAccept").toString(),
        cancelButton: this.$t("navigation.back").toString()
      })
      .then(ok => {
        if (ok) {
          this.$mtmService.sendEvent(
            "Click",
            "RESET",
            this.tasteSession.category,
            "plp"
          );
          this.$emit("reset");
        }
      });
    // If you throw an error, the method will terminate here unless you surround it wil try/catch
  }

  redirectToTastePage() {
    this.$mtmService.sendEvent(
      "Click",
      "SHOW_TASTE_SUGGESTIONS",
      this.tasteSession.category,
      "tastesuggestions"
    );

    UrlParam.updateSearchParam(
      UrlParam.URL_PARAM_NAME_PLP_SCROLL_STATE,
      ScrollState.ofAnyVisibleProducttile()
    );

    Navigation.gotoTasteSuggestionsPage(this.tasteSession, this.$router);
  }

  random(max: number) {
    return Math.random() * (max - 0) + 0;
  }
}
