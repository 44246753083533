











import { Component } from "vue-property-decorator";
import IconButton from "../IconButton.vue";
import InnoFindVue from "../InnoFindVue";

import IconSurveyLikeNoBorder from "./../icons/survey/IconSurveyLikeNoBorder.vue";
import IconSurveyDislikeNoBorder from "./../icons/survey/IconSurveyDislikeNoBorder.vue";

@Component({
  components: { IconButton, IconSurveyLikeNoBorder, IconSurveyDislikeNoBorder }
})
export default class PlpSurveyPageYesNo extends InnoFindVue {
  voted(liked: boolean) {
    this.$emit("voted", liked);
  }
}
