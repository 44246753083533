

































//@ts-ignore
import * as $globalStyle from "@/assets/css/_styles.scss";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";
import ErrorPage from "@/components/pages/ErrorPage.vue";
import { GoogleTagManagerService } from "@/google-tag-manager/google-tag-manager-service";
import { ConfirmDialogService } from "@/services/confirmdialog-service";
import { MtmService } from "@/services/mtm-service";
import { PredictService } from "@/services/predict-service";
import { PropType } from "vue";
import VueI18n from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AbTestConfig } from "./abtesting/abtest-config";
import httpCommons from "./http-commons";
import { i18nConfig } from "./i18n/messages";
import { ApiError, ErrorCode } from "./models/api-error";
import { Category } from "./models/category";
import { UrlParam } from "./models/url-param";
import { WidgetConfig } from "./models/widget-config/widget-config";
import router from "./router";
import { IteminfosService } from "./services/iteminfos-service";
import { User } from "./user/user";
import { Vendor } from "./vendor/vendor";
//import { InnoFindResizeObserver } from "./helper/resize.observer";
Vue.use(VueI18n);

//import "./gtm_debug_script.ts";

@Component({
  name: "app",
  i18n: new VueI18n(i18nConfig),
  components: { ErrorPage, ConfirmDialogue },
  router
})
export default class App extends Vue {
  private static DEFAULT_ERROR_CALLBACK_FUNCTION = function() {
    console.log(
      "no callback function passed to handle widget errors. Function can be passed with argument callback, eg. errorcallback='myErrorHandler()'"
    );
  };

  @Prop({ required: true })
  private category?: string;
  @Prop({ required: true })
  private errorcallback?: string;
  @Prop({ required: true })
  private itemid?: string;
  @Prop({ required: true })
  private vendor?: string;
  @Prop({ required: true })
  private vendorinfo?: string;

  @Prop({ required: true })
  private entrypoint?: string;

  @Prop({ required: true })
  private initialSortOrder?: string;

  @Prop({ required: true })
  private landingpageurl?: string;

  @Prop()
  private lang?: string;

  @Prop()
  private forcePlp?: boolean;

  @Prop({ type: String as PropType<string> })
  private initialPage?: "pdp" | "define" | "plp";

  @Prop({ required: false })
  private categoryMenu?: Category[] | undefined;

  @Prop({ required: false })
  private defaultCategory?: string;

  private error = false;
  private loading = true;

  private DEFAULT_TRANSITION = "fade";
  private transitionName = "";

  //private appResizeObserver?: InnoFindResizeObserver = undefined;

  beforeMount() {
    if (this.$style) {
      for (const key in $globalStyle) {
        this.$style[key] = $globalStyle[key];
      }
    } else {
      this.$style = $globalStyle;
    }
  }

  beforeDestroy() {
    // Workaround Ochsner! TODO remove as soon as not two widgets are intergrated on same page
    if (this.$vendor.isOchsnershoes() && this.entrypoint === "plp-popup") {
      // hack for ochsnershoes because they integrated it twice in one page (popup and plp_end).
      // Override entrypoint after closing popup back to plp-end
      console.log("setback ep to plp_end");
      this.$user.entrypoint = "plp-plp_end";
    }

    // this.appResizeObserver?.destroyObserver();
  }

  beforeCreate() {
    Vue.prototype.$globalStyle = $globalStyle;
  }

  widgetVisibilityChanged(visible: boolean) {
    if (!visible) {
      return;
    }
    this.$abtestConfigs?.widgetIsVisibleSendToServer(this.$user);
  }

  async mounted() {
    Vue.prototype.$errorCallback = (apiError: ApiError) =>
      this.handleError(apiError);

    if (!this.vendor) {
      this.$errorCallback(
        new ApiError(
          ErrorCode.VENDOR_REQUIRED,
          "vendor is mandatory",
          "please add attribute vendor"
        )
      );
      return;
    }

    // $errorCallback-Funktion kann so in allen Komponenten als Funktion aufgerufen werden

    Vue.prototype.$predictService = new PredictService();
    Vue.prototype.$iteminfosService = new IteminfosService();
    Vue.prototype.$mtmService = new MtmService();
    Vue.prototype.$abtestConfigs = new AbTestConfig();
    Vue.prototype.$googleTagManagerService = new GoogleTagManagerService();
    Vue.prototype.$confirmDialogService = new ConfirmDialogService(
      this.$refs.confirmDialog as ConfirmDialogue
    );
    Vue.prototype.$vendor = Vendor.of(
      this.vendor,
      this.entrypoint,
      this.vendorinfo
    ); // use yourhome as default because they use an old widget docu

    Vue.prototype.$user = await User.initUser(
      this.entrypoint,
      this.lang,
      this.$mtmService,
      this.$vendor
    );

    Vue.prototype.$landingpageurl = this.landingpageurl;

    httpCommons.defaults.headers.common[
      "if-vendor-prefix"
    ] = this.$vendor.prefix.replace("-", "");

    //httpCommons.defaults.headers.common["if-origin"] = window?.location?.href;

    // if (UnitoAppApi.shouldUseUnitoAppCommunication(this.$vendor)) {
    //   this.appResizeObserver = new InnoFindResizeObserver(
    //     this.$refs.innofindMainApp as HTMLElement
    //   );

    //   this.appResizeObserver.observable().subscribe((appHeight: number) => {
    //     UnitoAppApi.updateAppHeight(appHeight);
    //   });
    // }

    if (this.lang) {
      this.$i18n.locale = this.lang;
    }

    this.$abtestConfigs.init(
      this.$user,
      this.$vendor,
      this.$mtmService,
      this.$googleTagManagerService,
      this.entrypoint
    );

    if (
      !!this.category &&
      (this.$vendor.ignoreCategories || []).indexOf(this.category) !== -1
    ) {
      console.warn(
        "hide InnoFind widget because category",
        this.category,
        "is not allowed"
      );
      return;
    }

    if (
      this.$vendor.onlyAllowCategories &&
      this.category &&
      this.$vendor.onlyAllowCategories.indexOf(this.category) == -1
    ) {
      this.$errorCallback(
        new ApiError(
          ErrorCode.CATEGORY_UNKNOWN,
          "category not allowed",
          "category " + this.category + " not allowed for " + this.vendor
        )
      );
      return;
    }

    if (this.$vendor.gtmTrackingId) {
      if (this.$vendor.gtmLoadGtagScript) {
        // used for solovivo
        console.log("load gtag script");
        const script = document.createElement("script");
        script.src =
          "https://www.googletagmanager.com/gtag/js?id=" +
          this.$vendor.gtmTrackingId;
        document.head.appendChild(script);
      }
      //this.$googleTagManagerService.pushToDatalayer("js", new Date());
      this.$googleTagManagerService.initConfig(this.$vendor.gtmTrackingId);
    }

    this.$googleTagManagerService.anyInnoFindArtifactLoaded(this.category);

    let defaultCategory = this.defaultCategory || this.$vendor.defaultCategory;

    if (this.categoryMenu) {
      //If categoryMenu is passed but no default category, manually select first passed category as default category as fallback
      defaultCategory = this.categoryMenu[0].value;
    }

    WidgetConfig.of(
      this.categoryMenu || this.$vendor.defaultCategoryMenu,
      defaultCategory,
      this.initialSortOrder,
      this.$vendor,
      this.$iteminfosService
    ).subscribe((widgetConfig: WidgetConfig) => {
      Vue.prototype.$widgetConfig = widgetConfig;
      this.loading = false;
    });

    this.$vendor.init();

    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || this.DEFAULT_TRANSITION;

      next();
    });

    this.initialRedirect();
  }

  private initialRedirect() {
    const internalPathNameFromUrl = UrlParam.getUrlParam(
      UrlParam.URL_PARAM_NAME_INTERNAL_ROUTING_NAME
    );

    if (internalPathNameFromUrl === "tastesuggestions") {
      router.push({
        name: "tastesuggestions",
        query: {
          sessionid: UrlParam.getUrlParam(UrlParam.URL_PARAM_NAME_SESSION_ID),
          likes: UrlParam.getUrlParam(UrlParam.URL_PARAM_NAME_LIKES),
          dislikes: UrlParam.getUrlParam(UrlParam.URL_PARAM_NAME_DISLIKES),
          category:
            this.forcePlp && !this.category
              ? this.$vendor.defaultCategory
              : this.category,
          tastesuggestionsScrollState: UrlParam.getUrlParam(
            UrlParam.URL_PARAM_NAME_TASTESUGGESTIONS_SCROLL_STATE
          )
        }
      });
      return;
    }

    switch (this.initialPage) {
      case "define":
        router.push({
          name: "define",
          params: {
            //@ts-ignore
            category: this.category
          }
        });
        break;

      case "plp":
        router.push({
          name: "plp",
          params: {
            //@ts-ignore
            category:
              this.forcePlp && !this.category
                ? this.$vendor.defaultCategory
                : this.category
          },
          query: {
            likes: UrlParam.getUrlParamAsArray(
              UrlParam.URL_PARAM_NAME_LIKES,
              "."
            ),
            dislikes: UrlParam.getUrlParamAsArray(
              UrlParam.URL_PARAM_NAME_DISLIKES,
              "."
            ),
            visibleProductsAmount: UrlParam.getUrlParam(
              UrlParam.URL_PARAM_NAME_VISIBLE_PRODUCTS_AMOUNT
            ),
            plpScrollState: UrlParam.getUrlParam(
              UrlParam.URL_PARAM_NAME_PLP_SCROLL_STATE
            ),
            forcepms: UrlParam.getUrlParam(
              UrlParam.URL_PARAM_NAME_FORCE_PREDICT_MODEL_STRATEGY
            ),
            sessionid: UrlParam.getUrlParam(UrlParam.URL_PARAM_NAME_SESSION_ID),
            forceplp: this.forcePlp + ""
          }
        });
        break;

      case "pdp":
        //@ts-ignore
        router.push({
          name: "pdp",
          params: {
            //@ts-ignore
            category: this.category,
            //@ts-ignore
            itemid: this.itemid
          },
          query: {}
        });
        break;
    }
  }

  private handleError(apiError: ApiError) {
    this.error = true;
    console.error("can not show InnoFind Widget because", apiError);

    const clientErrorCallback = (window as { [key: string]: any })[
      this.errorcallback?.replace("()", "") || ""
    ];
    const errorCallbackFunction =
      clientErrorCallback || App.DEFAULT_ERROR_CALLBACK_FUNCTION;
    errorCallbackFunction(apiError.errorCode, apiError.title);
  }
}
