export class Rating {
  public static readonly POS_RATING = 1;
  public static readonly NEG_RATING = 2;

  constructor(
    readonly imgId: string,
    readonly rating: number // 1 = like | 2 = dislike
  ) {}

  isFor(imageId: string): boolean {
    return this.imgId === imageId;
  }

  liked(): boolean {
    return this.rating === 1;
  }

  disliked(): boolean {
    return this.rating === 2;
  }

  isNeutral(): boolean {
    return !this.liked() && !this.disliked();
  }

  public static toRatings(imgIds: string[], rating: 1 | 2): Rating[] {
    return imgIds.map((imgId: string) => new Rating(imgId, rating));
  }
}
