





















































































import { Navigation } from "@/models/navigation";
import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { Component, Prop } from "vue-property-decorator";
import IconButton from "./IconButton.vue";
import CrossIconButton from "./icons/CrossIconButton.vue";
import DislikeMooris from "./icons/DislikeMooris.vue";
import HunnThumbIcon from "./icons/HunnThumbIcon.vue";
import LikeMooris from "./icons/LikeMooris.vue";
import InnoFindVue from "./InnoFindVue";
import ProductImage from "./ProductImage.vue";

@Component({
  components: {
    ProductImage,
    CrossIconButton,
    IconButton,
    HunnThumbIcon,
    LikeMooris,
    DislikeMooris
  }
})
export default class Favourites extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  private ratings: Rating[] = [];
  private likedImageIds: string[] = [];

  private scrollContainer: any;

  mounted() {
    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      this.ratings = ratings.filter(it => !it.isNeutral());

      this.likedImageIds = ratings
        .filter(rating => rating.liked())
        .map(rating => rating.imgId);

      this.scrollContainer = this.$refs.scrollable;
      this.scroll();
    });
  }

  private showThumbIcon() {
    return this.$vendor.isHunn();
  }

  public reset() {
    this.$confirmDialogService
      .show({
        title: this.$t("navigation.restart.confirmQuestion").toString(),
        message: this.$t("navigation.restart.confirmHinttext").toString(),
        okButton: this.$t("navigation.restart.confirmAccept").toString(),
        cancelButton: this.$t("navigation.back").toString()
      })
      .then(ok => {
        if (ok) {
          this.$mtmService.sendEvent(
            "Click",
            "RESET",
            this.tasteSession.category,
            "plp"
          );

          this.$emit("reset");
        }
      });
    // If you throw an error, the method will terminate here unless you surround it wil try/catch
  }

  compareButtonText() {
    const amount = this.likedImageIds.length;
    return this.$tc("navigation.comparebuttonShowProducts", amount, {
      count: amount
    });
  }

  scroll() {
    if (this.scrollContainer == null) {
      return;
    }
    if (typeof this.scrollContainer.scroll !== "undefined") {
      setTimeout(() => {
        if (
          this.scrollContainer.scrollWidth > this.scrollContainer.offsetWidth
        ) {
          this.scrollContainer.classList.add("no-negative-margin");
        }
        this.scrollContainer.scroll({
          left: this.scrollContainer.scrollWidth,
          behavior: "smooth"
        });
      }, 100);
    } else {
      //IE Fallback
      setTimeout(() => {
        this.scrollContainer.scrollLeft = this.scrollContainer.offsetWidth;
      }, 100);
    }
  }
  redirectToLikesPage() {
    this.$mtmService.sendEvent(
      "Click",
      "SHOW_LIKES_FROM_FAVORITEBAR",
      this.tasteSession.category,
      "likes"
    );

    Navigation.gotoLikesPage(this.tasteSession, this.$router);
  }

  remove(imgId: string) {
    this.tasteSession.removeRating(imgId);
    this.$mtmService.sendEvent(
      "Click",
      "REMOVE_RATING",
      this.tasteSession.category,
      "plp"
    );
  }

  detailPageLinkClicked(imageId: string) {
    Navigation.gotoPdp(
      imageId,
      this.tasteSession,
      this.$vendor,
      this.$user,
      this.$route,
      this.$googleTagManagerService,
      this.$iteminfosService,
      "favorites",
      undefined
    );
  }

  detailPageUrl(imageId: string) {
    return Navigation.detailPageRedirectUrl(
      imageId,
      this.tasteSession,
      this.$user,
      this.$vendor,
      "favorites",
      undefined
    );
  }
}
