import { nextNumberDivisibleBy } from "@/helper/utils";

import { Vendor } from "@/vendor/vendor";
import { plpStateStore } from "@/store/plp.state";

export interface PlpSlideInElementsConfig {
  likebarInterval?: number;
  rateHintIndex?: number;
  surveyIndex?: number;
}

export class PlpSlideInElements {
  public static SHOW_SURVEY_AFTER_NUMBER_OF_RATINGS = 3;
  public static SHOW_SURVEY_AFTER_SEEN_NUMBER_OF_PRODUCTS = 100;

  private likebarInterval?: number;

  private rateHintIndex?: number;

  private surveyIndex?: number = undefined;

  private constructor(
    config: PlpSlideInElementsConfig,
    private readonly vendor: Vendor
  ) {
    this.likebarInterval = config.likebarInterval;
    this.rateHintIndex = config.rateHintIndex;
    this.surveyIndex = config.surveyIndex;

    this.saveSlideInPositionsForSession();
  }

  public static new(vendor: Vendor, rateable: boolean): PlpSlideInElements {
    const savedSlideInElementsConfigFromPageBack =
      plpStateStore.getters.plpSlideInElementsConfig;

    return new PlpSlideInElements(
      savedSlideInElementsConfigFromPageBack || {
        likebarInterval: vendor.plpLikeBannerInterval,
        rateHintIndex: rateable ? 24 : undefined,
        surveyIndex: undefined
      },
      vendor
    );
  }

  displaySurvey(indexOfLastLiked: number, productsPerRow: number) {
    if (this.surveyIndex) {
      return; // survey already displayed in list
    }

    indexOfLastLiked = Math.max(
      indexOfLastLiked,
      10 // don't show survey before 10 products have been seen (e.g. user liked first three products)
    );

    const indexForSurvey = nextNumberDivisibleBy(
      indexOfLastLiked + 1, // + 1 because index is 0 based
      productsPerRow
    );

    this.surveyIndex = indexForSurvey;
    this.saveSlideInPositionsForSession();
  }

  // if user comes back by browser back they have to see the exaxt same plp to ensure that they see the same part of the productlist after comming back
  saveSlideInPositionsForSession() {
    plpStateStore.commit("updatePlpSlideInElementsConfig", this.asConfigDto());
  }

  asConfigDto(): PlpSlideInElementsConfig {
    return {
      likebarInterval: this.likebarInterval,
      rateHintIndex: this.rateHintIndex,
      surveyIndex: this.surveyIndex
    };
  }

  hasElementOnIndex(i: number): boolean {
    return (
      this.showLikebarOnIndex(i) ||
      this.showRateHintOnIndex(i) ||
      this.showSurveyOnIndex(i)
    );
  }

  showRateHintOnIndex(index: number): boolean {
    return index === this.rateHintIndex;
  }

  showLikebarOnIndex(index: number): boolean {
    return (
      this.vendor.plpShowLikeBanner &&
      !!this.likebarInterval &&
      index % this.likebarInterval == 0 &&
      index > 0
    );
  }

  showSurveyOnIndex(index: number): boolean {
    return index === this.surveyIndex;
  }
}
