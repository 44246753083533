







import { Component } from "vue-property-decorator";
import ReloadIcon from "./icons/ReloadIcon.vue";
import InnoFindVue from "./InnoFindVue";

@Component({
  components: {
    ReloadIcon
  }
})
export default class ButtonNewPredictions extends InnoFindVue {
  clicked() {
    this.$emit("clicked");
  }
}
