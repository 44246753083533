





















import { ConfirmDialogueOpts } from "@/services/confirmdialog-service";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import PopupModal from "./PopupModal.vue";

@Component({
  components: {
    PopupModal
  }
})
export default class ConfirmDialogue extends InnoFindVue {
  // Private variables
  private resolvePromise: any;
  private rejectPromise: any;

  isMounted = false; // we have to wait for the mounted hook because otherwise $styles does not contain the globalStyle like .heading2

  private opts: ConfirmDialogueOpts = {} as ConfirmDialogueOpts;

  mounted() {
    this.isMounted = true;
  }

  show(opts: ConfirmDialogueOpts): Promise<boolean> {
    this.opts = opts;

    // Once we set our config, we tell the popup modal to open
    //@ts-ignore
    this.$refs.popup.open();
    // Return promise so the caller can get results
    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  }

  _confirm() {
    //@ts-ignore
    this.$refs.popup.close();
    this.resolvePromise(true);
  }

  _cancel() {
    //@ts-ignore
    this.$refs.popup.close();
    this.resolvePromise(false);
    // Or you can throw an error
    // this.rejectPromise(new Error('User cancelled the dialogue'))
  }
}
