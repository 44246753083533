var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$vendor.isHunn() ? [_vm.$style.productInfosHunn] : '',
    _vm.$style.productInfos,
    _vm.size == 'small' ? [_vm.$style.small] : '',
    _vm.noBorder ? [_vm.$style.noBorder] : ''
  ]},[_c('div',{class:_vm.$style.producttitle},[_c('text-or-loading-skeleton',{attrs:{"text":_vm.iteminfo ? _vm.iteminfo.title : undefined}}),(_vm.iteminfo && _vm.iteminfo.priceSalesPercentage)?_c('div',[_c('div',{class:_vm.$style.priceSalesPercentage},[_vm._v(" - "+_vm._s(_vm.iteminfo.priceSalesPercentage)+"% ")])]):_vm._e()],1),_c('div',{class:_vm.$style.priceContainer},[_c('span',{class:( _obj = {}, _obj[_vm.$style.price] = true, _obj[_vm.$style.withPriceOld] = _vm.hasOldPrice(), _obj )},[(_vm.$vendor.showProductPriceFrom && !!_vm.iteminfo)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("productPriceFrom"))+" ")]):_vm._e(),(_vm.$vendor.isHunn())?_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("CHF ")]):_vm._e(),_c('text-or-loading-skeleton',{attrs:{"text":_vm.iteminfo ? _vm.getFormattedPrice(_vm.iteminfo.price) : undefined,"expectedWidth":"40%"}})],1),_c('span',{class:_vm.$style.priceOldWrapper},[(
          _vm.$vendor.widgetTexte.productInfoPriceOld &&
            !!_vm.iteminfo &&
            _vm.hasOldPrice()
        )?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.$vendor.widgetTexte.productInfoPriceOld))+" ")]):_vm._e(),_c('span',{class:( _obj$1 = {}, _obj$1[_vm.$style.priceOld] = true, _obj$1[_vm.$style.priceOldMarginRight] = _vm.hasOldPrice(), _obj$1 )},[_c('text-or-loading-skeleton',{attrs:{"text":_vm.getOldPrice(),"expectedWidth":"40%"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }