













import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";

@Component({
  components: {}
})
export default class ErrorPage extends InnoFindVue {}
