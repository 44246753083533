import { GoogleTagManagerService } from "@/google-tag-manager/google-tag-manager-service";
import { IteminfosService } from "@/services/iteminfos-service";
import { MtmService } from "@/services/mtm-service";
import { User } from "@/user/user";
import Sheego from "@/vendor/sheego/sheego";
import { combineLatest, forkJoin, from, Observable } from "rxjs";
import VueRouter, { Route } from "vue-router";
import http from "./../http-commons";
import { Vendor } from "./../vendor/vendor";
import { ScrollState } from "./scroll-state";
import { TasteSession } from "./taste-session";
import { UrlParam } from "./url-param";
import { UnitoAppApi } from "../vendor/unito/unitoapp.api";

export class Navigation {
  static gotoPLP(
    vendor: Vendor,
    tasteSession: TasteSession | null,
    user: User,
    mtmService: MtmService,
    $router: VueRouter,
    googleTagManagerService: GoogleTagManagerService,
    originEventName:
      | "define-like"
      | "define-cta"
      | "define-all-liked"
      | "pdp-cta"
      | "pdp-like",
    forcePredictModelStrategy?: "strict" | "smart"
  ): void {
    if (!tasteSession) {
      throw "tasteSession not yet initialized!";
    }

    try {
      const gotoplp$ = mtmService.sendEvent(
        "Click",
        "GOTO_PLP",
        tasteSession.category,
        "plp",
        originEventName
      );

      const gtagEvent = googleTagManagerService.gotoPlp(tasteSession.category);

      combineLatest([gotoplp$, from(gtagEvent)]).subscribe(_ => {
        this.redirectToPlp(
          tasteSession,
          vendor,
          user,
          forcePredictModelStrategy
        );
      });
    } catch (e) {
      this.redirectToPlp(tasteSession, vendor, user, forcePredictModelStrategy);
    }
  }

  private static redirectToPlp(
    tasteSession: TasteSession,
    vendor: Vendor,
    user: User,
    forcePredictModelStrategy?: "strict" | "smart"
  ) {
    const params: URLSearchParams = new URLSearchParams([
      [UrlParam.URL_PARAM_NAME_LIKES, tasteSession.likedImageIds.join(".")],
      [
        UrlParam.URL_PARAM_NAME_DISLIKES,
        tasteSession.dislikedImageIds.join(".")
      ]
    ]);

    params.append(UrlParam.URL_PARAM_NAME_CATEGORY, tasteSession.category);
    if (user.entrypoint) {
      params.append(UrlParam.URL_PARAM_NAME_REF, user.entrypoint);
    }

    if (forcePredictModelStrategy) {
      params.append(
        UrlParam.URL_PARAM_NAME_FORCE_PREDICT_MODEL_STRATEGY,
        forcePredictModelStrategy
      );
    }

    if (user.lang) {
      params.append("lang", user.lang);
    }

    params.append(UrlParam.URL_PARAM_NAME_SESSION_ID, tasteSession.sessionid);

    let redirectUrl =
      vendor.getInspirationPageUrl(
        tasteSession.$landingpageurl,
        tasteSession.category
      ) +
      "?" +
      params.toString();

    const utmParamsString = vendor.plpPageUtmParamsForCategory(
      tasteSession.category
    );
    if (utmParamsString) {
      redirectUrl = redirectUrl + "&" + utmParamsString;
    }

    if (UnitoAppApi.shouldUseUnitoAppCommunication(vendor)) {
      return UnitoAppApi.goToPLP(params.toString());
    }
    window.location.href = redirectUrl;
  }

  static gotoPdp(
    itemId: string,
    tasteSession: TasteSession,
    $vendor: Vendor,
    $user: User,
    $route: Route,
    $googleTagManagerService: GoogleTagManagerService,
    $iteminfosService: IteminfosService,
    detailClickOrigin?: string,
    detailClickEventProductPositionIndex?: string
  ) {
    if ($vendor.isSheego()) {
      Sheego.instance.trackDetailclick(detailClickOrigin);
    }

    UrlParam.updateSearchParam(
      UrlParam.URL_PARAM_NAME_LIKES,
      tasteSession.likedImageIds.join(".")
    );

    UrlParam.updateSearchParam(
      UrlParam.URL_PARAM_NAME_DISLIKES,
      tasteSession.dislikedImageIds.join(".")
    );

    if ($route.name) {
      UrlParam.updateSearchParam(
        UrlParam.URL_PARAM_NAME_INTERNAL_ROUTING_NAME,
        $route.name
      );
    }

    ScrollState.updateUrlParam(
      itemId,
      (document.querySelector(
        `[data-producttile="${itemId}"]`
      ) as HTMLElement)?.getBoundingClientRect()?.top,
      detailClickOrigin === "taste-suggestions" ? "tastesuggestions" : "plp"
    );

    if ($vendor.isSheego()) {
      const gaObservable: Observable<any> = from(
        $googleTagManagerService.detailclick(tasteSession?.category)
      );

      const detailUrlObservable: Observable<string> = $iteminfosService.loadDetailurlAndTrackDetailclick(
        itemId,
        tasteSession,
        $user,
        detailClickOrigin,
        detailClickEventProductPositionIndex
      );
      forkJoin([gaObservable, detailUrlObservable]).subscribe(
        ([_, detailUrl]) => {
          window.location.href = detailUrl;
        }
      );
    } else {
      $googleTagManagerService.detailclick(tasteSession?.category).then(_ => {
        if (UnitoAppApi.shouldUseUnitoAppCommunication($vendor)) {
          return UnitoAppApi.goToPDP(itemId);
        }

        window.location.href = Navigation.detailPageRedirectUrl(
          itemId,
          tasteSession,
          $user,
          $vendor,
          detailClickOrigin,
          detailClickEventProductPositionIndex
        );
      });
    }
  }

  public static gotoLikesPage(tasteSession: TasteSession, $router: VueRouter) {
    $router.push({
      name: "likes",
      query: {
        sessionid: tasteSession.sessionid,
        likes: tasteSession.likedImageIds.join("."),
        category: tasteSession.category,
        plpScrollState: ScrollState.ofAnyVisibleProducttile()
      }
    });
  }

  public static gotoTasteSuggestionsPage(
    tasteSession: TasteSession,
    $router: VueRouter
  ) {
    $router.push({
      name: "tastesuggestions",
      query: {
        sessionid: tasteSession.sessionid,
        likes: tasteSession.likedImageIds.join("."),
        dislikes: tasteSession.dislikedImageIds.join("."),
        category: tasteSession.category,
        plpScrollState: ScrollState.ofAnyVisibleProducttile() // pass scroll state it will be passed back if user comes back to plp
      }
    });
  }

  static detailPageRedirectUrl(
    itemId: string,
    tasteSession: TasteSession,
    $user: User,
    $vendor: Vendor,
    detailClickOrigin?: string,
    detailClickEventProductPositionIndex?: string
  ) {
    const params: URLSearchParams = Navigation.detailUrlQueryParams(
      tasteSession,
      $user,
      detailClickOrigin,
      detailClickEventProductPositionIndex
    );

    return (
      http.defaults.baseURL +
      "/detail/" +
      itemId +
      "?" +
      params.toString() +
      "&" +
      $vendor.detailPageUtmParamsForCategory(tasteSession.category)
    );
  }
  /** us it for direct redircet (/detail/:item and also for loading detailurl (:item/detail because these informations are used for tracking purposes)) */
  static detailUrlQueryParams(
    tasteSession: TasteSession,
    $user: User,
    detailClickOrigin?: string,
    detailClickEventProductPositionIndex?: string
  ) {
    const params: URLSearchParams = new URLSearchParams();

    if ($user.lang) {
      params.append("lang", $user.lang);
    }

    params.append("sessionid", tasteSession.sessionid);
    params.append("userid", $user.userId);

    params.append("ratings", tasteSession.nrOfRatings.toString());
    params.append("cat", tasteSession.category);

    if (detailClickOrigin) {
      params.append("en", detailClickOrigin);
    }

    if (detailClickEventProductPositionIndex) {
      params.append(
        "productPositionIndex",
        detailClickEventProductPositionIndex.toString()
      );
    }
    return params;
  }

  static scrollToInnoFindTop() {
    window.scroll({
      //@ts-ignore
      top: (document.getElementById("innofind-app")?.offsetTop || 0) - 10,
      left: 0,
      behavior: "smooth"
    });
  }
}
