export enum DeviceType {
  MOBILE = "MOBILE",
  TABLET = "TABLET",
  DESKTOP = "DESKTOP",
  APP = "APP"
}

export class Device {
  public static get deviceType(): DeviceType {
    const userAgent = navigator.userAgent;
    if (
      userAgent.toLowerCase().includes("app=true") &&
      userAgent.toLowerCase().includes("appname=sheego")
    ) {
      return DeviceType.APP;
    }

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return DeviceType.TABLET;
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      return DeviceType.MOBILE;
    }
    return DeviceType.DESKTOP;
  }
}
