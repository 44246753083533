import App from "@/App.vue";
//@ts-ignore
import * as $globalStyle from "@/assets/css/_styles.scss";
import { PropType } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ResponsiveDirective } from "vue-responsive-components";
import { UrlParam } from "./models/url-param";
import { Ackermann } from "./vendor/ackermann/main-ackermann";
import { Ottoversand } from "./vendor/ottoversand/main-ottoversand";
import { Solovivo } from "./vendor/solovivo/solovivo-main";
import router from "./router";
import { Connox } from "./vendor/connox/connox-main";
import { Category } from "./models/category";

@Component({
  components: { App },
  directives: {
    responsive: ResponsiveDirective
  }
})
export default class WebComponentRoot extends Vue {
  private disableWidget = false; // used in vue template

  @Prop({ type: String as PropType<string> })
  private category?: string;
  @Prop({ type: String as PropType<string> })
  private errorcallback?: string;
  @Prop({ type: String as PropType<string> })
  private itemid?: string;
  @Prop({ type: String as PropType<string> })
  private vendor?: string;
  @Prop({ type: String as PropType<string> })
  private vendorinfo?: string;

  @Prop({ type: String as PropType<string> })
  private entrypoint?: string; // @deprecated use entrypage and position instead
  @Prop({ type: String as PropType<string> })
  private entrypage?: string;
  @Prop({ type: String as PropType<string> })
  private position?: string;

  @Prop({ type: String as PropType<string> })
  private initialSortOrder?: string;
  @Prop({ type: String as PropType<string> })
  private listurl?: string; //@deprecated use landingpageurl instead currently only used for widget adapter (sheego) - @deprecated
  @Prop({ type: String as PropType<string> })
  private landingpageurl?: string; //new version for listurl

  @Prop({ type: Boolean as PropType<boolean> })
  private forceplp?: boolean; // should only be used for demo on innofind.ch,

  @Prop({ type: String as PropType<string> })
  private lang?: string;

  @Prop({ type: String as PropType<string> })
  private categoryMenu?: string;

  @Prop({ type: String as PropType<string> })
  private defaultCategory?: string;

  beforeMount() {
    // unsicher warum das gebraucht wird, aber wahrscheinlich v-responsive klassen
    if (this.$style) {
      for (const key in $globalStyle) {
        this.$style[key] = $globalStyle[key];
      }
    } else {
      this.$style = $globalStyle;
    }

    if (this.vendor === "ackermann") {
      Ackermann.instance.initListeners(this, router, this.entrypointCalculated);
    }
    if (this.vendor === "ottoversand") {
      Ottoversand.instance.initListeners(
        this,
        router,
        this.entrypointCalculated
      );
    }
  }

  beforeDestroy() {
    if (this.vendor === "ackermann") {
      Ackermann.instance.removeListeners();
    }
    if (this.vendor === "ottoversand") {
      Ottoversand.instance.removeListeners();
    }
  }

  created() {
    // unito hack for ackermann and ottoversand. If category is not set for entrypoint plp-unten or plp-mitte -> hide widget
    this.init();
  }

  public init() {
    this.checkIfWidgetShouldBeDisabled();
  }

  private checkIfWidgetShouldBeDisabled() {
    if (
      (this.vendor === "ackermann" || this.vendor === "ottoversand") &&
      (this.entrypoint === "plp-unten" || this.entrypoint === "plp-mitte") &&
      !this.categoryCalculated()
    ) {
      console.log("no category extracted, hide widget!");
      this.disableWidget = true;
    } else {
      this.disableWidget = false;
    }
  }

  get entrypointCalculated(): string | undefined {
    // TODO long term: remove entrypoint and only user entrypage and position
    let result = this.entrypage
      ? this.entrypage + "-" + this.position
      : this.entrypoint;

    // override entrypoint by innofind-ref url param
    result = UrlParam.getUrlParam(UrlParam.URL_PARAM_NAME_REF) || result;

    if (this.vendor === "ackermann") {
      return Ackermann.instance.overrideEntrypoint(result);
    }

    if (this.vendor === "ottoversand") {
      return Ottoversand.instance.overrideEntrypoint(result);
    }
    return result;
  }

  categoryCalculated(): string | undefined {
    const result = this.decodeCategory(
      //@ts-ignore
      UrlParam.getUrlParam(UrlParam.URL_PARAM_NAME_CATEGORY) || this.category,
      //@ts-ignore
      this.vendor
    );
    return result;
  }

  private decodeCategory(encodedCategory: string, vendor: string): string {
    if (vendor === "solovivo") {
      return Solovivo.decodeCategory(encodedCategory);
    }
    if (vendor === "connox") {
      return Connox.decodeCategory(encodedCategory);
    }

    if (vendor === "trenddeko" && encodedCategory === "poster") {
      return "3369"; // to ensure that old links we sent in sales email still work!
    }

    if (
      this.vendor === "ackermann" &&
      (this.entrypoint === "plp-unten" || this.entrypoint === "plp-mitte")
    ) {
      return encodedCategory || Ackermann.instance.extractCategoryFromUrl(); // for ackermann use category extracted from url if no category provided
    }

    if (
      this.vendor === "ottoversand" &&
      (this.entrypoint === "plp-unten" || this.entrypoint === "plp-mitte")
    ) {
      return encodedCategory || Ottoversand.instance.extractCategoryFromUrl(); // for ottoversand use category extracted from url if no category provided
    }

    return encodedCategory;
  }

  get forcePlpCalculated(): boolean | undefined {
    return (
      UrlParam.hasUrlParam(UrlParam.URL_PARAM_NAME_FORCE_PLP) || this.forceplp
    );
  }

  get initialPage(): "plp" | "pdp" | "define" {
    if (this.entrypoint === "pdp") {
      return "pdp";
    }

    if (
      this.forceplp ||
      UrlParam.hasUrlParam(UrlParam.URL_PARAM_NAME_LIKES) ||
      UrlParam.hasUrlParam(UrlParam.URL_PARAM_NAME_DISLIKES)
    ) {
      return "plp";
    } else {
      return "define";
    }
  }

  private itemIdCalculated(): string | undefined {
    if (this.initialPage !== "pdp") {
      return undefined;
    }

    if (this.vendor === "ackermann") {
      return Ackermann.instance.extractPDPItemIdFromFromUrl() || "0"; //0 as Default Value, so Widget is rendered even when the URL-Param should be missing;
    }

    if (this.vendor === "ottoversand") {
      return Ottoversand.instance.extractPDPItemIdFromFromUrl();
    }

    return this.itemid;
  }

  private langCalculated(): string | null {
    return (
      this.lang ||
      document.documentElement.lang ||
      new URL(window.location.href)?.searchParams?.get("lang")
    );
  }

  public categoryMenuCalculated(): Category[] | undefined {
    if (!this.categoryMenu) {
      return undefined;
    }
    try {
      const categoryObject = JSON.parse(this.categoryMenu.replaceAll("'", '"'));
      return Object.entries<string>(categoryObject).map(
        ([key, value]) => new Category(value, key)
      );
    } catch (e) {
      console.warn("can not parse category menu property", this.categoryMenu);
    }
  }
}
