







import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import LoadingSkeleton from "./LoadingSkeleton.vue";

@Component({ components: { LoadingSkeleton } })
export default class TextOrLoadingSkeleton extends InnoFindVue {
  @Prop()
  private text?: string;

  @Prop({ default: "100%" })
  private expectedWidth?: string;

  hasText(): boolean {
    return this.text !== undefined && this.text !== null;
  }
}
