













































import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { Component, Prop } from "vue-property-decorator";
import IconButton from "./IconButton.vue";
import InnoFindVue from "./InnoFindVue";

@Component({
  components: {
    IconButton
  }
})
export default class GoToPlpButton extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  @Prop()
  private buttonClass?: string;

  public nrOfLikes = 0;
  private animation = false;

  private disabled = false;
  private buttonClickedWhileDisabled = false;

  mounted() {
    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      const newNrOfLikes = ratings.filter((it: Rating) => it.liked()).length;

      if (newNrOfLikes > this.nrOfLikes) {
        this.animation = true;
        setTimeout(() => (this.animation = false), 1000);
      }

      this.nrOfLikes = newNrOfLikes;

      //If the user can rate on the plp, the button is never disabled
      this.disabled = newNrOfLikes === 0 && !this.$vendor.plpRateable;
    });
  }

  gotoPLP() {
    if (this.disabled) {
      this.buttonClickedWhileDisabled = true;
    }
    if (!this.disabled) {
      this.$emit("clicked");
    }
  }

  multipleLikesAllowed(): boolean {
    return !this.$vendor.autoRedirectToPlpAfterLike;
  }
}
