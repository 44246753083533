import { render, staticRenderFns } from "./WebComponentRoot.vue?vue&type=template&id=6b81103d&"
import script from "./WebComponentRoot.vue.ts?vue&type=script&lang=ts&"
export * from "./WebComponentRoot.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports