























import { Component, Prop } from "vue-property-decorator";
import IconButton from "./../IconButton.vue";
import InnoFindVue from "./../InnoFindVue";
import { TasteSession } from "@/models/taste-session";

@Component({
  components: { IconButton }
})
export default class PlpRateHint extends InnoFindVue {
  @Prop({ required: true })
  private readonly tasteSession!: TasteSession;

  private maxTwoRatings = true;

  plpHintVisible(visibility: boolean) {
    if (!visibility) {
      return;
    }
    this.maxTwoRatings = this.tasteSession.nrOfRatingsNotNeutral <= 2;
  }
}
