




import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import LeftArrowIcon from "./LeftArrowIcon.vue";

@Component({
  components: { LeftArrowIcon }
})
export default class RightArrowIcon extends InnoFindVue {}
