




































































import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { Subscription } from "rxjs";
import { Component, Prop, Watch } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import ProductKachel from "./ProductKachel.vue";
import RateButtons from "./RateButtons.vue";

const DOUBLE_CLICK_DELAY_MS = 200;

export interface RatingEvent {
  imageId: string;
  rating: number;
}

@Component({
  components: {
    ProductKachel,
    RateButtons
  }
})
export default class RateableProduct extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  @Prop()
  private image!: string;

  @Prop({ default: false })
  private handleClickAsLike!: boolean;

  @Prop({ default: true })
  private rateable!: boolean;

  @Prop({ default: false })
  private showProductInfos!: boolean;

  @Prop({ default: false })
  private useFreistellerImages!: boolean;

  @Prop({ default: "large" })
  private size!: "small" | "large" | "largeMobile";

  @Prop({ default: "large" })
  private sizeImage!: "small" | "large" | "largeMobile";

  @Prop({ default: true })
  private showDetailLink!: boolean;

  @Prop({ default: true })
  private border!: boolean;

  // only used for detailClick event
  @Prop({ required: false })
  private detailClickOrigin?: string;

  // only used for detailClick event
  @Prop({ required: false })
  private detailClickEventProductPositionIndex?: string;

  @Prop({ required: false, default: false })
  private noProductImageSlider!: boolean;

  public rating = 0;

  public disabled = false;
  public hoverLike = false;
  public hoverDislike = false;

  isInViewPort = false;

  private ratingForImageSubscription?: Subscription = undefined;

  @Watch("image")
  onNewImageChanged(newValue: string, oldValue: string) {
    if (!newValue) {
      return;
    }
    this.image = newValue;

    this.loadRatingForImage();
  }

  mounted() {
    this.loadRatingForImage();
  }

  get displayDefaultBorder(): boolean {
    return (
      this.border &&
      // !this.$user.isPlpSortingEntrypoint() && TODO: Remove only for AB-Test slider
      this.$vendor.defaultBorder
    );
  }

  loadRatingForImage(): void {
    this.ratingForImageSubscription?.unsubscribe(); // unsubscribe old subscription to load rating (performacen optimization)

    const getRatingSubscription = this.tasteSession
      .ratingForStream(this.image)
      .subscribe(rating => {
        this.rating = rating;
      });

    this.ratingForImageSubscription = getRatingSubscription;
  }

  visibilityChanged(visible: boolean): void {
    this.isInViewPort = visible;
    this.$emit("inViewPort", {
      isVisible: visible
    });
  }

  public toggleLiked(event: any) {
    event?.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.rating = this.liked() ? 0 : Rating.POS_RATING;
    this.emitRated();
  }

  public toggleDisliked(event: any) {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.rating = this.disliked() ? 0 : Rating.NEG_RATING;
    this.emitRated();
  }

  public emitRated() {
    this.disabled = true;
    setTimeout(() => {
      this.disabled = false;
    }, DOUBLE_CLICK_DELAY_MS);

    this.$emit("rated", {
      imageId: this.image,
      rating: this.rating
    });
  }

  public disliked(): boolean {
    return this.rating === Rating.NEG_RATING;
  }

  public liked(): boolean {
    return this.rating === Rating.POS_RATING;
  }

  public noRating() {
    return this.rating === 0;
  }
}
