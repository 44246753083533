export interface WidgetTexteConfig {
  useFormalForm?: boolean;
  defineTitle: string;
  defineText: string;
  gotoPLPButtonText: string;
  yourLikes: string;
  plpTitle: string;
  plpTitle2?: string;
  productInfoPriceOld?: string;
  rateHintTitle?: string;
  rateHintText?: string;
  surveyTitle?: string;
  surveyWhatDidYouLike?: string;
  surveyInactiveTitle?: string;
  surveyInactiveWhyNotRated?: string;
}

export class WidgetTexte {
  constructor(
    public readonly useFormalForm: boolean,
    public readonly defineTitle: string,
    public readonly defineText: string,
    public readonly gotoPLPButtonText: string,
    public readonly yourLikes: string,
    public readonly plpTitle: string,
    public readonly rateHintTitle: string,
    public readonly rateHintText: string,
    public readonly surveyTitle: string,
    public readonly surveyWhatDidYouLike: string,
    public readonly surveyInactiveTitle: string,
    public readonly surveyInactiveWhyNotRated: string,
    public readonly plpTitle2?: string,
    public readonly productInfoPriceOld?: string
  ) {}

  public static of(config: WidgetTexteConfig): WidgetTexte {
    return new WidgetTexte(
      config.useFormalForm == true ? true : false, // undefined as false
      config.defineTitle,
      config.defineText,
      config.gotoPLPButtonText,
      config.yourLikes,
      config.plpTitle,
      config.rateHintTitle || "rateHint.title",
      config.rateHintText || "rateHint.text",
      config.surveyTitle || "survey.title",
      config.surveyWhatDidYouLike || "survey.whatDidYouLike",
      config.surveyInactiveTitle || "survey.inactive.title",
      config.surveyInactiveWhyNotRated || "survey.inactive.whyNotRated",
      config.plpTitle2,
      config.productInfoPriceOld
    );
  }

  public static default(): WidgetTexte {
    return WidgetTexte.of({
      defineTitle: "Finden statt Suchen",
      defineText: "Definiere deinen Stil",
      gotoPLPButtonText: "Produkte anzeigen",
      yourLikes: "deine Likes",
      plpTitle: "Stilfinder",
      plpTitle2: "Zu dir passen diese Produkte"
    });
  }
}
