export class Connox {
  public static decodeCategory(passedCategory: string): string {
    switch (passedCategory) {
      case "vasen":
        return "100199";
      case "stühle":
        return "100243";
      case "pendelleuchten":
        return "100228";
      case "esstische":
        return "100244";
      default:
        return passedCategory;
    }
  }
}
