export class GTMEventsSessionStorage {
  public static readonly GTM_EVENTS_SESSION_STORAGE_NAME = "gEvents";

  public static alreadySent(eventName: string): boolean {
    return this.alreadySentEvents().includes(eventName);
  }

  public static addEventSent(eventName: string) {
    const existingEvents = this.alreadySentEvents();
    if (existingEvents.includes(eventName)) {
      return;
    }
    this.setEventsInSessionStorage([eventName, ...existingEvents]);
  }

  private static setEventsInSessionStorage(eventNames: string[]) {
    if (sessionStorage) {
      sessionStorage[this.GTM_EVENTS_SESSION_STORAGE_NAME] = eventNames.join(
        ","
      );
    }
  }

  private static alreadySentEvents(): string[] {
    if (
      sessionStorage &&
      sessionStorage[this.GTM_EVENTS_SESSION_STORAGE_NAME]
    ) {
      return sessionStorage[this.GTM_EVENTS_SESSION_STORAGE_NAME].split(",");
    }
    return [];
  }
}
