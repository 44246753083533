export const i18nConfig = {
  locale: "de", // set locale
  fallbackLocale: "de",
  silentTranslationWarn: true,
  messages: {
    de: {
      stilfinder: "Stilfinder",
      productPriceFrom: "ab",
      scribble: {
        likeOrDislike: "liken oder disliken",
        sofortEmpfelungen: "und sofort Empfehlungen erhalten"
      },
      progressBar: {
        label: "Stil-Erkennung",
        toMyTasteButton: "zu meinem Stil"
      },
      tasteSuggestions: {
        headline: "deine persönliche Auswahl",
        headlineSie: "Ihre persönliche Auswahl",
        improveTasteButton: "Stil verfeinern",
        improveTasteHeadline: "Noch nicht das richtige?",
        improveTasteText:
          "Verfeinere deinen Stil und erhalte weitere Vorschläge",
        improveTasteTextSie:
          "Verfeinern Sie Ihren Stil und erhalten Sie weitere Vorschläge."
      },
      pdp: {
        weitereVorschlaegeButton: "zum Stilfinder"
      },
      define: {
        neueVorschlaege: "Neue Vorschläge",
        mindOnePart1: "Mindestens 1",
        mindOnePart2: "notwendig, um Stil anzuzeigen",
        categoryNotAvailable: "Die gewählte Kategorie ist nicht vorhanden"
      },
      navigation: {
        back: "zurück",
        showMore: "Mehr anzeigen",
        showAllLikesButton: "alle Likes anzeigen",
        productdetailHint: "Produktdetail anzeigen",
        restart: {
          button: "neu starten",
          confirmQuestion: "Suche zurücksetzen?",
          confirmHinttext:
            "Sie verlieren die aktuellen Bewertungen und müssen Ihren Stil neu definieren",
          confirmAccept: "Ja, neu starten"
        },
        comparebuttonShowProducts:
          "Keine Produkt ausgewählt | Ein Produkt anzeigen | {count} Produkte anzeigen"
      },
      rateHint: {
        title: "Zu viel Auswahl?",
        text:
          "Bewerte Produkte die dir gefallen und erhalte sofort Empfehlungen",
        textSie:
          "Bewerten Sie Produkte die Ihnen gefallen und erhalten Sie sofort Empfehlungen",
        rateHintLess: "weniger davon",
        rateHintMore: "mehr davon"
      },
      survey: {
        title: "Wie findest du die interaktive Suche?",
        titleSie: "Wie finden Sie die interaktive Suche?",
        suggestedImprovements: "Verbesserungsvorschläge",
        whatDidYouLike: "Was hat dir gefallen?",
        whatDidYouLikeSie: "Was hat Ihnen gefallen?",
        thanks: "Besten Dank für das Feedback",
        send: "senden",
        inactive: {
          title: "Sind dir die Bewertungsbuttons aufgefallen?",
          titleSie: "Sind Ihnen die Bewertungsbuttons aufgefallen?",
          whyNotRated: "Warum hast du nicht bewertet?",
          whyNotRatedSie: "Warum haben Sie nicht bewertet?"
        }
      },
      ackermann: {
        damenschuhe: "Damenschuhe",
        bilder: "Bilder",
        weihnachtsdekoration: "Weihnachtsdekoration",
        teppiche: "Teppiche",
        jacken: "Damenjacken",
        kleider: "Kleider",
        pullover: "Pullover",
        texts: {
          defineTitle: "Finden statt Suchen",
          defineText:
            "persönlichen Stil definieren und schnell das Lieblingsprodukt finden",
          gotoPLPButtonText: "zu meinem Stil",
          yourLikes: "Ihre Likes",
          plpTitle: "Stilfinder",
          plpTitle2: "Finden statt Suchen",
          productInfoPriceOld: "statt"
        }
      },
      pkz: {
        jacken: "Jacken",
        maentel: "Mäntel",
        hosen: "Hosen",
        texts: {
          defineTitle: "FINDEN STATT SUCHEN",
          defineText: "DEFINIEREN SIE IHREN STIL",
          gotoPLPButtonText: "PRODUKTE ANZEIGEN",
          yourLikes: "IHRE LIKES",
          plpTitle: "STILFINDER",
          plpTitle2: "ZU IHNEN PASSEN DIESE PRODUKTE"
        }
      },
      hunn: {
        gartenstuehle: "Gartenstühle",
        gartenlounges: "Gartenlounges",
        defineTitle: "Wählen Sie was Ihnen gefällt",
        defineText:
          "Liken Sie Ihren persönlichen Stil und entdecken Sie, welche der hochwertigen Produkte am besten zu Ihnen passen.",
        findGardenFurnitureStil: "Finden Sie Ihren Gartenmöbel Stil",
        gotoPLPButtonText: "Zu meinem Stil",
        yourLikes: "Ihre Likes",
        suggestions: "Empfehlungen",
        plpTitle: "Zu Ihnen passen diese Produkte",
        plpNavBackButton: "Stilfinder"
      },
      demo: {
        yourLikes: "deine Likes",
        gotoPLPButtonText: "zu meinem Stil",
        defineTitle: "finden statt suchen"
      }
    },
    fr: {
      stilfinder: "Stylefinder",
      productPriceFrom: "dès",
      scribble: {
        likeOrDislike: "noter ce qui aime", // Scribble darf keine Sonderzeichen enthalten!!
        sofortEmpfelungen: "et recevoir tout de suite des recommandations" // Scribble darf keine Sonderzeichen enthalten!!
      },
      progressBar: {
        label: "Reconnaissance du goût",
        toMyTasteButton: "à mon goût"
      },
      tasteSuggestions: {
        headline: "ta sélection personnelle",
        headlineSie: "sélection personnelle",
        improveTasteButton: "Affiner le goût",
        improveTasteHeadline:
          "Vous n'avez pas encore trouvé ce que vous cherchez?",
        improveTasteText: "Affine tes goûts et obtiens d'autres suggestions",
        improveTasteTextSie: "Affiner le goût et obtenir d'autres suggestions"
      },
      pdp: {
        weitereVorschlaegeButton: "à la Stylefinder"
      },
      define: {
        neueVorschlaege: "autres suggestions",
        mindOnePart1: "Au moins un",
        mindOnePart2: "nécessaire pour afficher le style",
        categoryNotAvailable: "La catégorie choisie n'existe pas"
      },
      navigation: {
        back: "retour",
        showMore: "Voir plus",
        showAllLikesButton: "afficher tous les likes",
        productdetailHint: "afficher le détail du produit",
        restart: {
          button: "redémarrer",
          confirmQuestion: "Réinitialiser la recherche?",
          confirmHinttext:
            "Vous perdez les évaluations actuelles et devez redéfinir vos goûts",
          confirmAccept: "Oui, redémarrer"
        },
        comparebuttonShowProducts:
          "Aucun produit sélectionné | Afficher un produit | Afficher {count} produits"
      },
      rateHint: {
        title: "Trop de choix?",
        text:
          "Note les produits que tu aimes et obtiens des recommandations immédiates",
        textSie:
          "Notez les produits qui vous plaisent et obtenez immédiatement des recommandations",
        rateHintLess: "moins de cela",
        rateHintMore: "plus de cela"
      },
      survey: {
        title: "Comment trouves-tu la recherche interactive?",
        titleSie: "Comment trouver la recherche interactive?",
        suggestedImprovements: "Propositions d'amélioration",
        whatDidYouLike: "Qu'est-ce qui t'a plu?",
        whatDidYouLikeSie: "Qu'est-ce qui vous a plu?",
        thanks: "Merci pour le feedback",
        send: "Suivant",
        inactive: {
          title: "As-tu remarqué les boutons d'évaluation?",
          titleSie: "Avez-vous remarqué les boutons d'évaluation?",
          whyNotRated: "Pourquoi n'as-tu pas évalué?",
          whyNotRatedSie: "Pourquoi n'avez-vous pas évalué?"
        }
      },
      ackermann: {
        damenschuhe: "Chaussures pour femmes",
        bilder: "Galerie de peinture",
        weihnachtsdekoration: "Décorations de Noël",
        teppiche: "Tapis",
        jacken: "Vestes pour femmes",
        kleider: "Robes",
        pullover: "Pulls",
        texts: {
          defineTitle: "Trouver au lieu de chercher",
          defineText:
            "définir son style personnel et trouver rapidement son produit préféré",
          gotoPLPButtonText: "à mon style",
          yourLikes: "Vos Likes",
          plpTitle: "Trouveur de style",
          plpTitle2: "Trouver au lieu de chercher",
          productInfoPriceOld: "au lieu de"
        }
      },
      pkz: {
        jacken: "Vestes",
        maentel: "Manteaux",
        hosen: "Pantalons",
        texts: {
          defineTitle: "TROUVER AU LIEU DE CHERCHER",
          defineText: "DÉFINISSEZ VOS GOÛTS",
          gotoPLPButtonText: "AFFICHER LES PRODUITS",
          yourLikes: "VOS LIKES",
          plpTitle: "STYLEFINDER",
          plpTitle2: "LES PRODUITS SUIVANTS VOUS CONVIENNENT"
        }
      },
      hunn: {
        gartenstuehle: "Chaises de jardin",
        gartenlounges: "Lounges de jardin",
        defineTitle: "Choisissez ce qui vous plaît",
        defineText:
          "Likez votre style personnel et découvrez quels sont les produits de qualité qui vous conviennent le mieux.",
        findGardenFurnitureStil: "Trouvez votre style de meubles de jardin",
        gotoPLPButtonText: "vers mon style",
        yourLikes: "Vos Likes",
        suggestions: "Recommandations",
        plpTitle: "Les produits suivants vous conviennent",
        plpNavBackButton: "Guide des style"
      }
    },
    nl: {
      stilfinder: "Stijlkeuze", // validiert
      productPriceFrom: "vanaf", // validiert
      scribble: {
        likeOrDislike: "Vink aan wat je wel/niet leuk", // validiert - dürfen keine Sonderzeichen enthalten
        sofortEmpfelungen: "vindt om meer in jouw stijl te zien" // validiert - dürfen keine Sonderzeichen enthalten
      },
      progressBar: {
        label: "taste detection", // TODO
        toMyTasteButton: "to my taste" // TODO
      },
      tasteSuggestions: {
        headline: "your personal selection", // TODO
        headlineSie: "your personal selection", // TODO
        improveTasteButton: "refine taste", // TODO
        improveTasteHeadline: "still not the right one?", // TODO
        improveTasteText: "Refine your taste and get more suggestions", // TODO
        improveTasteTextSie: "Refine your taste and get more suggestions" // TODO
      },
      pdp: {
        weitereVorschlaegeButton: "Meer tonen" // validiert
      },
      define: {
        neueVorschlaege: "New suggestions", // TODO
        mindOnePart1: "At least one", // TODO
        mindOnePart2: "is required to show your style", // TODO
        categoryNotAvailable: "The selected category is not available" // TODO
      },
      navigation: {
        back: "Terug", // validiert
        showMore: "Meer tonen", // validiert
        showAllLikesButton: "Jouw producten", // validiert
        productdetailHint: "Product bekijken", // validiert
        restart: {
          button: "Stijlkeuzes wissen", // validiert
          confirmQuestion: "Stijlkeuzes wissen?", // validiert
          confirmHinttext:
            "Jouw productselectie wordt gewist en je strart opnieuw met je stijlkeuze", // validiert
          confirmAccept: "Opnieuw kiezen" // validiert
        },
        comparebuttonShowProducts:
          "Geen product geselecteerd | Toon 1 product | Toon {count} producten" // validiert
      },
      rateHint: {
        title: "Te veel keuze?", // validiert
        text: "Vink aan welke producten je leuk om meer in jouw stijl te zien", // validiert
        textSie:
          "Vink aan welke producten je leuk om meer in jouw stijl te zien", // validiert
        rateHintLess: "less like that", // TODO
        rateHintMore: "more like that" // TODO
      },
      survey: {
        title: "We horen graag wat je van onze stijlvinder vindt?", // validiert
        titleSie: "We horen graag wat je van onze stijlvinder vindt?", // validiert
        suggestedImprovements: "Wat kan er beter?", // validiert
        whatDidYouLike: "Wat vind je goed?", // validiert
        whatDidYouLikeSie: "Wat vind je goed?", // validiert
        thanks: "Bedankt voor jouw feedback", // validiert
        send: "Feedback versturen", // validiert
        inactive: {
          title: "Heb je de leuk/niet leuk knoppen gezien?", // validiert
          titleSie: "Heb je de leuk/niet leuk knoppen gezien?", // validiert
          whyNotRated: "Waarom heb je geen producten aangevinkt?", // validiert
          whyNotRatedSie: "Waarom heb je geen producten aangevinkt?" // validiert
        }
      }
    },
    en: {
      stilfinder: "Stylefinder",
      productPriceFrom: "from",
      scribble: {
        likeOrDislike: "like or dislike",
        sofortEmpfelungen: "to get instant recommendations"
      },
      progressBar: {
        label: "taste detection",
        toMyTasteButton: "to my taste"
      },
      tasteSuggestions: {
        headline: "your personal selection",
        headlineSie: "your personal selection",
        improveTasteButton: "refine taste",
        improveTasteHeadline: "still not the right one?",
        improveTasteText: "Refine your taste and get more suggestions",
        improveTasteTextSie: "Refine your taste and get more suggestions"
      },
      pdp: {
        weitereVorschlaegeButton: "go to Stylefinder"
      },
      define: {
        neueVorschlaege: "new recommendations",
        mindOnePart1: "at least 1",
        mindOnePart2: "required, to start stylfinder",
        categoryNotAvailable: "The selected category is not available"
      },
      navigation: {
        back: "back",
        showMore: "load more",
        showAllLikesButton: "show likes",
        productdetailHint: "go to productdetail",
        restart: {
          button: "restart",
          confirmQuestion: "Do you want to start from scratch?",
          confirmHinttext:
            "You lose the current ratings and can redefine your taste",
          confirmAccept: "Yes, restart"
        },
        comparebuttonShowProducts:
          "No product selected | show one product | show {count} products"
      },
      rateHint: {
        title: "Too much choice?",
        text: "Rate products you like and get instant recommendations",
        rateHintLess: "less like that",
        rateHintMore: "more like that"
      },
      survey: {
        title: "What do you think of the interactive search?",
        suggestedImprovements: "Suggestions for improvement",
        whatDidYouLike: "What did you like?",
        thanks: "Thanks for the feedback",
        send: "send",
        inactive: {
          title: "Did you notice the rating buttons?",
          whyNotRated: "Why didn't you rate it?"
        }
      },
      demo: {
        yourLikes: "your likes",
        gotoPLPButtonText: "to your style",
        defineTitle: "try the interactive product list"
      }
    }
  }
};
