var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.imageInViewport,
    once: true,
    intersection: {
      threshold: 0.001
    }
  }),expression:"{\n    callback: imageInViewport,\n    once: true,\n    intersection: {\n      threshold: 0.001\n    }\n  }"}],class:[
    _vm.$style.imageWrapper,
    _vm.styling == 'productList' ? [_vm.$style.productListItem] : '',
    _vm.styling == 'bounceHighlight' ? [_vm.$style.bounceHighlight] : ''
  ]},[(_vm.lazyLoadingDisabled || _vm.lazyLoadingInViewPort)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && !_vm.loadingError),expression:"!loading && !loadingError"}],attrs:{"src":_vm.imageUrl,"alt":"Produktbild"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"error":_vm.imageLoadingError,"load":_vm.imageLoaded}}):_vm._e(),(_vm.loading)?_c('loading-skeleton',{staticStyle:{"height":"100%"}}):_vm._e(),(_vm.loadingError)?_c('span',{staticStyle:{"color":"black"}},[_vm._v("Bild nicht gefunden")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }