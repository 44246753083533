





































import { Device } from "@/models/device";
import { TasteSession } from "@/models/taste-session";
import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import Define from "./Define.vue";
import DefineOnlyLikes from "./DefineOnlyLikes.vue";

enum DefineVersion {
  DEFINE,
  DEFINE_ONLY_LIKES
}

@Component({
  components: {
    Define,
    DefineOnlyLikes
  }
})
export default class DefineRoot extends InnoFindVue {
  private tasteSession?: TasteSession = undefined;

  private defineVersion: DefineVersion = DefineVersion.DEFINE;

  created() {
    if (this.$vendor.isHunn()) {
      this.defineVersion = DefineVersion.DEFINE_ONLY_LIKES;
    }

    this.$abtestConfigs.abTestWholeWidget.sendGaEvent(
      this.$user.userId,
      "",
      this.$route.params.category || this.$widgetConfig!.defaultCategory
    );
  }

  defaultDefine(): boolean {
    return this.defineVersion === DefineVersion.DEFINE;
  }

  defineOnlyLikes(): boolean {
    return this.defineVersion === DefineVersion.DEFINE_ONLY_LIKES;
  }

  tasteSessionInitialized(tasteSession: TasteSession) {
    this.tasteSession = tasteSession;
  }

  visibilityChangedInstant(visible: boolean) {
    if (visible) {
      // !!event sofort senden wenn sichtbar!!!!!

      // the abtest config should always send GA Event. even though tasteSession is not available when widget is hidden
      this.$abtestConfigs.abTestWholeWidget.sendGaEvent(
        this.$user.userId,
        "-visible",
        this.$route.params.category || this.$widgetConfig!.defaultCategory
      );
    }
  }

  visibilityChangedWithDelay(visible: boolean): void {
    if (!this.tasteSession) {
      if (this.$abtestConfigs.abTestWholeWidget.hideWholeWidget()) {
        return; // prevent error log
      }
      console.error(
        "tastesession is not defined yet. return tasteSession in define!"
      );
      return;
    }

    if (visible) {
      this.$googleTagManagerService.defineIsVisible(
        this.tasteSession?.category
      );

      this.tasteSession.ratingsStream().subscribe(ratings => {
        if (ratings.length !== 0) {
          this.$googleTagManagerService.ratingOnDefine(
            this.tasteSession?.category
          );
        }
      });

      //Define-Page sichtbar _> wird als Page-Visit getrackt
      this.$mtmService.initUrl({
        userId: this.$user.userId,
        url: "http://innofind.ch/define", //Fake-URL, damit sichtbar ist dass 'Define-Page' geöffnet ist
        vendor: this.$vendor.vendorname,
        category: this.tasteSession.category,
        entrypoint: this.$user.entrypoint || "",
        widgetVersionName: process.env.VUE_APP_WIDGET_VERSION_NAME,
        monetaryValue: "0",
        deviceType: Device.deviceType
      });
    }
  }
}
