export class UrlParam {
  public static readonly URL_PARAM_NAME_CATEGORY = "inno-category";

  public static readonly URL_PARAM_NAME_SESSION_ID = "inno-sessionid";
  public static readonly URL_PARAM_NAME_LIKES = "inno-likes";
  public static readonly URL_PARAM_NAME_DISLIKES = "inno-dislikes";
  public static readonly URL_PARAM_NAME_FORCE_PLP = "inno-forceplp";
  public static readonly URL_PARAM_NAME_REF = "inno-ref";
  public static readonly URL_PARAM_NAME_FORCE_PREDICT_MODEL_STRATEGY =
    "inno-forcepms";
  public static readonly URL_PARAM_NAME_INTERNAL_ROUTING_NAME = "inno-path"; // innofind routing path

  public static readonly URL_PARAM_NAME_VISIBLE_PRODUCTS_AMOUNT =
    "visibleProductsAmount";

  public static readonly URL_PARAM_NAME_PLP_SCROLL_STATE = "plpScrollState";
  public static readonly URL_PARAM_NAME_TASTESUGGESTIONS_SCROLL_STATE = "tsss";

  public static hasUrlParam(name: string): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has(name);
  }

  public static getUrlParamAsArray(name: string, delimiter: string): string[] {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(name);
    return param?.split(delimiter) || [];
  }

  public static getUrlParam(name: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }

  public static updateSearchParam(
    searchParamName: string,
    value: string
  ): void {
    const url = this.currentUrl();

    url.searchParams.set(searchParamName, value);

    this.updateWindowUrl(url);
  }

  public static removeSearchParam(urlParamName: string): void {
    const url = this.currentUrl();

    url.searchParams.delete(urlParamName);

    this.updateWindowUrl(url);
  }

  private static currentUrl(): URL {
    return new URL(window.location.href);
  }

  private static updateWindowUrl(url: URL): void {
    window.history.replaceState(null, "", url.toString());
  }
}
