














































import { TasteSession } from "@/models/taste-session";
import { RemoveAllVisibleDuringADislike } from "@/models/taste-update-strategy";
import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import ProductKachel from "../ProductKachel.vue";

@Component({
  components: {
    ProductKachel
  }
})
export default class SimilarImages extends InnoFindVue {
  private tasteSession!: TasteSession;
  private static NR_TO_SHOW = 10;
  private itemIds: string[] = [];

  created() {
    this.initTaste();
    const itemId = this.$route.query.itemId as string;
    const category = this.$route.query.category as string;

    this.$predictService
      .loadSimilarImageIds(this.$vendor.vendorname, category, itemId, 18)
      .subscribe((itemIds: string[]) => {
        this.itemIds = itemIds.slice(0, SimilarImages.NR_TO_SHOW);
      });
  }

  mounted() {
    history.pushState({}, "");

    window.onpopstate = () => {
      this.goback();
      window.onpopstate = function() {
        return undefined;
      };
    };
  }

  private initTaste() {
    const category = this.$route.query.category as string;

    this.tasteSession = TasteSession.new(
      category,
      new RemoveAllVisibleDuringADislike(),
      SimilarImages.NR_TO_SHOW,
      [],
      this.$vendor.shuffleImagesOnDefine
    );
  }

  goback() {
    this.$router.go(-1);
  }
}
