




























import { TasteSession } from "@/models/taste-session";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import { PlpSlideInElements } from "./plp-slide-in-elements";
import PlpLikesBanner from "./PlpLikesBanner.vue";
import PlpSlideIn from "./PlpSlideIn.vue";
import PlpRateHint from "./PlpRateHint.vue";
import PlpSurvey from "./../survey/PlpSurvey.vue";

@Component({
  components: {
    PlpLikesBanner,
    PlpSlideIn,
    PlpRateHint,
    PlpSurvey
  }
})
export default class PlpSlideInRoot extends InnoFindVue {
  @Prop({ required: true })
  private plpIndex!: number;

  @Prop({ required: true })
  private plpSlideInElements!: PlpSlideInElements;

  @Prop({ required: true })
  private tasteSession!: TasteSession;

  // private showRateHint(): boolean {
  //   return this.plpSlideInElements.showRateHintOnIndex(this.plpIndex);
  // }

  private showLikebar(): boolean {
    return this.plpSlideInElements.showLikebarOnIndex(this.plpIndex);
  }

  private showSurvey(): boolean {
    return this.plpSlideInElements.showSurveyOnIndex(this.plpIndex);
  }
}
