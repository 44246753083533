import { Taste } from "./taste";

export interface TasteUpdateStrategy {
  keepsLikedImages: boolean;

  merge: (
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ) => Taste;
}

export class ReplaceAllTasteUpdateStrategy implements TasteUpdateStrategy {
  public keepsLikedImages = true;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    return newTaste;
  }
}

export class IgnoreRatedProductsTasteUpdateStrategy
  implements TasteUpdateStrategy {
  keepsLikedImages = false;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    return newTaste.without([...dislikedImageIds, ...likedImageIds]);
  }
}

// only use this on define because the first 4 products will be removed for more diversity after a like
export class ReplaceAllExceptLikesStrategyForDefine
  implements TasteUpdateStrategy {
  keepsLikedImages = true;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste;
    }

    let result = newTaste
      .without([...likedImageIds, ...dislikedImageIds])
      .removeDuplicates();

    likedImageIds.sort((a: string, b: string) => {
      const oldIndexA = oldTaste.imageIds.indexOf(a);
      const oldIndexB = oldTaste.imageIds.indexOf(b);

      return oldIndexA - oldIndexB;
    });

    //Remove first items, so not too many too similar are displayed
    result = result.removeFirstXItems(4);

    likedImageIds.forEach((elem: string) => {
      const oldIndex = oldTaste.imageIds.indexOf(elem);
      result.imageIds.splice(oldIndex, 0, elem);
    });

    return result;
  }
}

export class RemoveAllVisibleDuringADislike implements TasteUpdateStrategy {
  keepsLikedImages = true;

  private readonly visibleProductsDuringADislike: string[] = [];

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste;
    }

    const visibleImages = oldTaste.imageIds.slice(
      visibleImagesIndexStart,
      visibleImagesIndexEnd + 1
    );

    this.visibleProductsDuringADislike.push(...visibleImages);

    const result = newTaste
      .without([
        ...likedImageIds,
        ...dislikedImageIds,
        ...this.visibleProductsDuringADislike
      ])
      .removeDuplicates();

    likedImageIds.sort((a: string, b: string) => {
      const oldIndexA = oldTaste.imageIds.indexOf(a);
      const oldIndexB = oldTaste.imageIds.indexOf(b);

      return oldIndexA - oldIndexB;
    });

    likedImageIds.forEach((elem: string) => {
      const oldIndex = oldTaste.imageIds.indexOf(elem);
      result.imageIds.splice(oldIndex, 0, elem);
    });

    return result;
  }
}

export class OnlyReplaceDislikesStrategy implements TasteUpdateStrategy {
  keepsLikedImages = true;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste;
    }
    const resultTaste = oldTaste.copy();

    const seenProducts = oldTaste?.topXImage(lastSeenProductIndex);
    const notSeenPredictions = newTaste.without(seenProducts);

    dislikedImageIds.forEach((imgId, index) => {
      resultTaste.replace(imgId, notSeenPredictions.at(index));
    });

    return resultTaste;
  }
}

export class FancyReplaceStrategy implements TasteUpdateStrategy {
  keepsLikedImages = true;

  private readonly NR_TO_REPLACE = 3;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste.without([...dislikedImageIds]);
    }
    return oldTaste.fancyReplaceStrategy(
      newTaste,
      likedImageIds,
      dislikedImageIds,
      this.NR_TO_REPLACE,
      visibleImagesIndexStart,
      visibleImagesIndexEnd
    );
  }
}

export class ReplaceAllBelowLowestRatingStrategy
  implements TasteUpdateStrategy {
  keepsLikedImages = true;

  private readonly NR_TO_REPLACE = 3;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste.without([...dislikedImageIds]);
    }
    const ratedImageIds = [...likedImageIds, ...dislikedImageIds];

    const newPredictions = newTaste.without(ratedImageIds);

    const indexOfLowestRating = Math.max(...oldTaste.allIndexOf(ratedImageIds));

    // Replace all dislikes
    dislikedImageIds.forEach((imgId, index) => {
      oldTaste.replace(imgId, newPredictions.at(index));
    });

    return oldTaste
      .replaceAllAfter(indexOfLowestRating, newPredictions)
      .removeDuplicates();
  }
}

export class ReplaceEvery3thBelowLowestRatingStrategy
  implements TasteUpdateStrategy {
  keepsLikedImages = true;

  private readonly NR_TO_REPLACE_AT_TOP = 3;
  private readonly REPLACE_EVERY_XTH = 3;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste.without([...dislikedImageIds]);
    }
    const ratedImageIds = [...likedImageIds, ...dislikedImageIds];
    const indexOfLowestRating = Math.max(...oldTaste.allIndexOf(ratedImageIds));

    const alreadySeenImageIds = oldTaste.topXImage(indexOfLowestRating);

    const newPredictions = newTaste.without([
      ...ratedImageIds,
      ...alreadySeenImageIds
    ]);

    // Replace all dislikes
    dislikedImageIds.forEach((imgId, index) => {
      oldTaste.replace(imgId, newPredictions.at(index + 10)); // use 10th index so that in the viewport not every element is moving one position upwards
    });

    const top3Items = newPredictions.topXImage(this.NR_TO_REPLACE_AT_TOP);
    return oldTaste
      .replaceAfter(indexOfLowestRating + 1, top3Items)
      .replaceEveryXthAfter(
        indexOfLowestRating + this.NR_TO_REPLACE_AT_TOP, // because first 3 already have been replaced
        newPredictions.without(top3Items),
        this.REPLACE_EVERY_XTH
      )
      .removeDuplicates();
  }
}

export class ReplaceAllNotVisibleProducts implements TasteUpdateStrategy {
  keepsLikedImages = true;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste.without([...dislikedImageIds]);
    }
    if (!lastEverVisibleElement) {
      console.warn(
        "can not determin last ever visible product element. So wont replace any product"
      );
    }
    const ratedImageIds = [...likedImageIds, ...dislikedImageIds];

    const newPredictions = newTaste.without(ratedImageIds);

    if (lastEverVisibleElement === -1) {
      console.warn("lastEverVisibleElement was -1, wont update...");
      return oldTaste; // if lastEverVisibleElement there seems to be a race condition error!
    }

    return oldTaste
      .replaceAllAfter(lastEverVisibleElement, newPredictions)
      .removeDuplicates();
  }
}

export class ReplaceEveryThirdFromNotVisibleProductsAndDislikes
  implements TasteUpdateStrategy {
  keepsLikedImages = true;

  merge(
    oldTaste: Taste | undefined,
    newTaste: Taste,
    likedImageIds: string[],
    dislikedImageIds: string[],
    lastSeenProductIndex: number,
    visibleImagesIndexStart: number,
    visibleImagesIndexEnd: number,
    lastEverVisibleElement: number
  ): Taste {
    if (!oldTaste) {
      return newTaste.without([...dislikedImageIds]);
    }
    if (!lastEverVisibleElement) {
      console.warn(
        "can not determin last ever visible product element. So wont replace any product"
      );
    }
    const ratedImageIds = [...likedImageIds, ...dislikedImageIds];

    const newPredictions = newTaste.without(ratedImageIds);

    // Replace all dislikes
    dislikedImageIds.forEach((imgId, index) => {
      oldTaste.replace(imgId, newPredictions.at(index));
    });

    return oldTaste
      .replaceEveryXthAfter(lastEverVisibleElement, newPredictions, 3)
      .without(dislikedImageIds);
  }
}
