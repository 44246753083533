











import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";

@Component
export default class ProgressBar extends InnoFindVue {
  @Prop()
  private progress!: number;

  @Prop()
  private finished!: boolean;
}
