export function initLocationChangeListener() {
  //@ts-ignore
  if (window.innofindInit) {
    console.log("if already initialized");
    return;
  }

  //function to watch for URL Change (Workaround ackermann)
  (function() {
    const oldPushState = history.pushState;
    const oldReplaceState = history.replaceState;
    /* eslint-disable */
    history.pushState = function() {
      //@ts-ignore
      oldPushState.apply(history, arguments);
      window.dispatchEvent(new Event("locationchange"));
    };

    history.replaceState = function() {
      //@ts-ignore
      oldReplaceState.apply(history, arguments);
      window.dispatchEvent(new Event("locationchange"));
    };

    window.addEventListener("popstate", function() {
      window.dispatchEvent(new Event("locationchange"));
    });
    /* eslint-enable */
  })();

  //@ts-ignore
  window.innofindInit = true;
}
