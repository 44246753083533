




































import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";

@Component
export default class HunnThumbIcon extends InnoFindVue {
  @Prop()
  private hover!: boolean;

  @Prop()
  private active!: boolean;

  @Prop()
  private sizeInPx!: string;

  @Prop({ default: "like" })
  private icon!: string;
}
