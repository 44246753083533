






























import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { Subscription } from "rxjs";
import { Component, Prop } from "vue-property-decorator";
import IconButton from "./IconButton.vue";
import HunnThumbIcon from "./icons/HunnThumbIcon.vue";
import InnoFindVue from "./InnoFindVue";
import ProductImage from "./ProductImage.vue";

@Component({
  components: {
    ProductImage,
    HunnThumbIcon,
    IconButton
  }
})
export default class LikeableProduct extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  @Prop()
  private imgId!: string;

  private hover = false;

  public rating = 0;
  private ratingForImageSubscription?: Subscription = undefined;

  created() {
    this.loadRatingForImage();
  }

  updated() {
    this.loadRatingForImage();
  }

  loadRatingForImage(): void {
    this.ratingForImageSubscription?.unsubscribe(); // unsubscribe old subscription to load rating (performacen optimization)

    const getRatingSubscription = this.tasteSession
      .ratingForStream(this.imgId)
      .subscribe(rating => {
        this.rating = rating;
      });

    this.ratingForImageSubscription = getRatingSubscription;
  }

  toggleLiked() {
    this.rating = this.rating == Rating.POS_RATING ? 0 : Rating.POS_RATING;
    this.$emit("toggleliked", this.rating);
  }

  public hasLiked(): boolean {
    return this.rating === Rating.POS_RATING;
  }
}
