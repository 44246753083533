import { GoogleTagManagerService } from "@/google-tag-manager/google-tag-manager-service";
import { MtmService } from "@/services/mtm-service";
import { Vendor } from "@/vendor/vendor";

export interface UserInfoSessionStorageDto {
  scribbleTutorialDone: boolean;
  surveyDone: boolean;
}

export class User {
  private static SESSIONSTORAGE_NAME_USER = "if-sid"; // innofind session id
  private static SESSIONSTORAGE_NAME_USERINFO = "usrinfo";

  public alreadySentImageSliderUsedEvent = false;
  public alreadySentLoadMoreEvent = false;

  private readonly userInfos: UserInfoSessionStorageDto;

  constructor(
    public userId: string,
    public entrypoint: string | undefined,
    public readonly lang: string | undefined,
    readonly mtmService: MtmService
  ) {
    this.userInfos = User.loadUserInfo();
  }

  isErgebnisseiteEntrypoint() {
    return this.entrypoint === "ergebnisseite";
  }

  public isPlpSortingEntrypoint(): boolean {
    return this.entrypoint === "plp-sorting";
  }
  public isPlpEntrypointExcludeTop(): boolean {
    return (
      this.entrypoint === "plp" ||
      this.entrypoint === "plp-unten" ||
      this.entrypoint === "plp-mitte" || // used for ackm / otto
      this.entrypoint === "feature-block" || // used from mooris
      this.entrypoint === "plp-plp_end" || // if adapter is used with entrypage and position
      this.entrypoint === "plp-page-change" || // trenddeko
      this.entrypoint === "plp-undefined" || // ochsnershoes mobile
      this.entrypoint === "plp-row_3"
    );
  }
  public isStartseiteEntrypoint(): boolean {
    return this.entrypoint === "startseite" || this.entrypoint === "homepage";
  }

  hasAlreadyDoneScribbleTutorial(): boolean {
    return this.userInfos?.scribbleTutorialDone || false;
  }
  scribbleTutorialFinished(): void {
    this.userInfos.scribbleTutorialDone = true;
    this.saveUserInfoToSessionstorage();
  }

  surveyFinished(): void {
    this.userInfos.surveyDone = true;
    this.saveUserInfoToSessionstorage();
  }
  hasAlreadyFilledOutSurvey(): boolean {
    return this.userInfos.surveyDone || false;
  }

  saveUserInfoToSessionstorage() {
    if (sessionStorage) {
      sessionStorage[User.SESSIONSTORAGE_NAME_USERINFO] = JSON.stringify(
        this.userInfos
      );
    }
  }

  static async initUser(
    entrypoint: string | undefined,
    lang: string | undefined,
    mtmService: MtmService,
    vendor: Vendor
  ): Promise<User> {
    const userId = await User.loadUserId(vendor);
    if (!vendor.isUnito() && sessionStorage) {
      sessionStorage[User.SESSIONSTORAGE_NAME_USER] = userId;
    }
    if (vendor.isUnito() && localStorage) {
      localStorage[User.SESSIONSTORAGE_NAME_USER] = userId;
    }

    return new User(userId, entrypoint, lang, mtmService);
  }

  static async loadUserId(vendor: Vendor): Promise<string> {
    if (localStorage && localStorage.getItem(User.SESSIONSTORAGE_NAME_USER)) {
      return localStorage.getItem(User.SESSIONSTORAGE_NAME_USER)!;
    }
    if (
      sessionStorage &&
      sessionStorage.getItem(User.SESSIONSTORAGE_NAME_USER)
    ) {
      return sessionStorage.getItem(User.SESSIONSTORAGE_NAME_USER)!;
    }

    const gaUserId = await this.loadGAUserId(vendor);

    return gaUserId || User.generateId();
  }

  static async loadGAUserId(vendor: Vendor): Promise<string | undefined> {
    if (!window.ga && vendor.gtmTrackingId) {
      // if trackingId is set but ga-function is not available yet, wait unti ga-funciton is initialized
      await GoogleTagManagerService.waitForGaFunctionIsInitialized();
    }

    // if google analytics is defined it provides a function ga: https://stackoverflow.com/questions/42910672/does-google-analytics-emit-an-event-when-they-are-set-up
    if (!window.ga) {
      return Promise.resolve(undefined);
    }

    return new Promise(resolve => {
      let isPromiseResolved = false;

      // pass callback fuction which will be called from google analytics as soon as ga is initialized
      window.ga(() => {
        isPromiseResolved = true;
        resolve(window.ga?.getAll()[0]?.get("clientId")); // it can be problematic if multiple trackers are installed on the page. But because we use everywhere the first tracker it should return the same client id here and at the web-client..
      });

      // add timeout if ga-function takes longer than 500 ms to initialize
      setTimeout(() => {
        if (!isPromiseResolved) {
          isPromiseResolved = true;
          resolve(undefined);
        }
      }, 500);
    });
  }

  static loadUserInfo(): UserInfoSessionStorageDto {
    return sessionStorage && sessionStorage[User.SESSIONSTORAGE_NAME_USERINFO]
      ? JSON.parse(sessionStorage[User.SESSIONSTORAGE_NAME_USERINFO])
      : { scribbleTutorialDone: false };
  }

  static generateId(): string {
    return User.generateHexString(16);
  }

  static generateHexString(size: number): string {
    return [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
  }
}
