import { shuffle, sortedIndex } from "@/helper/utils";

export class Taste {
  constructor(public imageIds: string[]) {}

  removeDuplicates(): Taste {
    return new Taste(Array.from(new Set(this.imageIds)));
  }

  replaceEveryXthAfter(
    replaceAfterIndex: number,
    newTaste: Taste,
    everyXth: number
  ): Taste {
    const firstIndexToReplace = replaceAfterIndex + 1;

    const fixedImageIdsOfCurrentTaste = this.imageIds.slice(
      0,
      firstIndexToReplace
    );
    const imageIdsWhereEveryXthShouldBeReplaced = this.imageIds.slice(
      firstIndexToReplace,
      this.imageIds.length
    );

    const newImageIds = newTaste.without(fixedImageIdsOfCurrentTaste).imageIds;

    const indexesWhereEveryXthShouldBeReplaced = Array.from(
      imageIdsWhereEveryXthShouldBeReplaced.keys()
    ).slice(0, newTaste.imageIds.length * everyXth);

    // shuffle array of all possible indexes and take first thirth of these index
    const indexesToReplace = indexesWhereEveryXthShouldBeReplaced
      .sort(function() {
        return 0.5 - Math.random();
      })
      .slice(0, indexesWhereEveryXthShouldBeReplaced.length / everyXth);

    const replacedImages = indexesToReplace.map(idx => this.imageIds[idx]);

    indexesToReplace
      .sort((a, b) => a - b)
      .forEach((indexToReplace, index) => {
        imageIdsWhereEveryXthShouldBeReplaced[indexToReplace] =
          newImageIds[index];
      });

    return new Taste([
      ...fixedImageIdsOfCurrentTaste,
      ...imageIdsWhereEveryXthShouldBeReplaced,
      ...replacedImages
    ]).removeDuplicates();
  }

  // adds the given "elementsTOAdd" after the given "addAfterIndex" and replaces the existing Elements there
  replaceAfter(addAfterIndex: number, elementsToAdd: string[]) {
    return new Taste([
      ...this.imageIds.slice(0, addAfterIndex),
      ...elementsToAdd,
      ...this.imageIds.slice(addAfterIndex + elementsToAdd.length)
    ]);
  }

  replaceAllAfter(replaceAfterIndex: number, newTaste: Taste): Taste {
    const imageIdsOfCurrentTaste = this.imageIds.slice(
      0,
      replaceAfterIndex + 1
    );

    return new Taste([
      ...imageIdsOfCurrentTaste,
      ...newTaste.without(imageIdsOfCurrentTaste).imageIds
    ]);
  }
  without(toRemove: string[]): Taste {
    return new Taste(
      this.imageIds.filter(imageId => toRemove.indexOf(imageId) === -1)
    );
  }
  firstNotIn(otherImageIds: string[]): string {
    for (const imageId of this.imageIds) {
      if (otherImageIds.indexOf(imageId) === -1) {
        return imageId;
      }
    }

    throw "no new image found";
  }

  firstXItemsWithout(amountOfItems: number, toIgnore: string[]): string[] {
    return this.without(toIgnore).topXImage(amountOfItems);
  }

  replace(imgId: string, newImgId: string): void {
    const index = this.imageIds.indexOf(imgId);
    this.imageIds[index] = newImgId;
    this.imageIds = [...this.imageIds];
  }

  at(index: number): string {
    return this.imageIds[index];
  }

  allIndexOf(imageIds: string[]): number[] {
    return imageIds.map((imgId: string) => this.imageIds.indexOf(imgId));
  }

  fancyReplaceStrategy(
    newTaste: Taste,
    likes: string[],
    dislikes: string[],
    amountToReplace: number,
    replaceInIndexStart: number,
    replaceInIndexEnd: number
  ): Taste {
    newTaste = newTaste.copy();

    const imagesToFilter = [...dislikes];

    const alreadySeenImages = this.imageIds.slice(0, replaceInIndexEnd + 1);
    const currentlyVisibleImages = this.imageIds.slice(
      replaceInIndexStart,
      replaceInIndexEnd + 1
    );

    const indexOfCurrentVisibleImagesInNewPrediction = currentlyVisibleImages.map(
      imageId => newTaste.imageIds.indexOf(imageId)
    );

    const indexToFilter = imagesToFilter
      .map(imageId => currentlyVisibleImages.indexOf(imageId))
      .filter(index => index !== -1);

    const worstCurrentlyVisibleImageIndexes = sortedIndex(
      indexOfCurrentVisibleImagesInNewPrediction
    ).filter(index => likes.indexOf(currentlyVisibleImages[index]) === -1);

    const indexesToReplace = [
      ...indexToFilter,
      ...worstCurrentlyVisibleImageIndexes
    ].splice(0, Math.max(amountToReplace, indexToFilter.length));

    const topNotAlreadyVisible = newTaste.imageIds
      .filter(imageId => !alreadySeenImages.includes(imageId))
      .filter(imageId => imagesToFilter.indexOf(imageId) === -1);

    const newVisisbleImageIds = [...currentlyVisibleImages];
    indexesToReplace.forEach((indexToReplace, replaceWithIndex) => {
      newVisisbleImageIds[indexToReplace] =
        topNotAlreadyVisible[replaceWithIndex] ||
        newVisisbleImageIds[indexToReplace];
    });

    // for all not visible products (index > replaceInTopX) take the value from the newTaste (newer customer taste)
    const imagesBefore = this.imageIds.slice(0, replaceInIndexStart);
    const newVisibleImages = newVisisbleImageIds.slice(
      0,
      replaceInIndexEnd + 1 - replaceInIndexStart
    );
    const imagesAfter = topNotAlreadyVisible.slice(indexesToReplace.length);
    const result = [
      ...imagesBefore, // dont change images before replaceInIndexStart
      ...newVisibleImages,
      ...imagesAfter
    ];

    return new Taste(result);
  }

  topXImage(amount: number): string[] {
    return this.imageIds.slice(0, amount);
  }

  public get maxAvailableArticles(): number {
    return this.imageIds.length;
  }
  public copy(): Taste {
    return new Taste([...this.imageIds]);
  }

  addOnTop(newTopItemIds: string[]): Taste {
    return new Taste([...newTopItemIds, ...this.imageIds]);
  }

  removeFirstXItems(amount: number): Taste {
    return new Taste(this.imageIds.slice(amount));
  }

  shuffleFirstXItems(amountToShuffle: number): Taste {
    const firstTenImages = this.imageIds.slice(0, amountToShuffle);
    shuffle(firstTenImages);
    const otherImages = this.imageIds.slice(amountToShuffle);

    return new Taste([...firstTenImages, ...otherImages]);
  }

  set(index: number, imageId: string) {
    this.imageIds[index] = imageId;
  }

  // takes the given itemids and sets them as new top of the taste
  setFirstItems(newTopItems?: string[]): Taste {
    if (!newTopItems) {
      return this;
    }
    return new Taste([...newTopItems, ...this.imageIds]).removeDuplicates();
  }
}
