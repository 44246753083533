import CursorInteragtionsAgent, {
  InteractionsDto
} from "@/components/agent/interaction.agent";
import InnoFindVue from "@/components/InnoFindVue";
import { ServerLogService } from "@/logging/serverLog.service";
import { ApiError } from "@/models/api-error";
import { Rating } from "@/models/rating";
import { Taste } from "@/models/taste";
import { MtmService } from "@/services/mtm-service";
import { PredictionCharacter } from "@/services/predict-service";
import { User } from "@/user/user";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { first, map } from "rxjs/operators";
import http from "../http-commons";
import { plpStateStore } from "./../store/plp.state";
import { Device } from "./device";
import {
  ReplaceAllTasteUpdateStrategy,
  TasteUpdateStrategy
} from "./taste-update-strategy";
import { UrlParam } from "./url-param";

export class TasteSession extends InnoFindVue {
  private tasteSubject = new ReplaySubject<Taste>(1);
  private visibleImageIdsSubject = new ReplaySubject<string[]>(1);

  private currentTaste?: Taste;

  private ratings: Rating[] = [];
  private ratingSubject = new ReplaySubject<Rating[]>(1);

  public sessionid: string;

  private visibleImagesIndexStart = 0;
  private visibleImagesIndexEnd: number;

  private lastEverVisibleElementIndex = -1;

  private lastLoadedImageNumber: number; // is not the index! use lastVisibleImageNumber -1

  public nrProductsToShow: number;

  private constructor(
    public category: string,
    private readonly tasteUpdateStrategy: TasteUpdateStrategy,
    private readonly initialVisibleProducts: number,
    initialRatings: Rating[],
    initialTaste?: Taste,
    private readonly predictionCharacter: PredictionCharacter = PredictionCharacter.DEFAULT,
    existingSessionId?: string,
    readonly cursorInteractionsAgent?: CursorInteragtionsAgent,
    private readonly forcePredictModelStrategy?: "strict" | "smart"
  ) {
    super();

    this.visibleImagesIndexEnd = initialVisibleProducts;
    this.lastLoadedImageNumber = initialVisibleProducts;
    this.nrProductsToShow = initialVisibleProducts;
    this.sessionid = existingSessionId || User.generateId();

    this.ratings = initialRatings;
    this.emitRatings();

    if (initialTaste) {
      this.updateTaste(initialTaste);
    }

    // cursorInteractionsAgent
    //   ?.cursorInteractionUpdates()
    //   .subscribe((cursorInteractions: CursorInteractionDto[]) => {
    //     console.log("subscribe!!!!");
    //     if (!this.hasRatings) {
    //       this.loadPredictions(false, {
    //         cursorInteractions: cursorInteractions,
    //         currentlyVisibleItemIds: this.currentTaste?.topXImage(
    //           this.lastEverVisibleElementIndex + 1
    //         )
    //       } as InteractionsDto).subscribe((taste: Taste) =>
    //         this.updateTaste(taste, new ReplaceAllNotVisibleProducts())
    //       );
    //     }
    //   });
  }

  public static new(
    category: string,
    tasteUpdateStrategy: TasteUpdateStrategy,
    initialVisibleProducts: number,
    initialRatings: Rating[],
    shuffleFirstTenImages = false,
    predictionCharacter: PredictionCharacter = PredictionCharacter.DEFAULT,
    existingSessionId?: string,
    fixedInitialProducts?: string[],
    errorCallbackOverride?: (err: string) => void, // override default error handling on server error. Default is hide whole widget
    cursorInteractionAgent?: CursorInteragtionsAgent,
    forcePredictModelStrategy?: "strict" | "smart",
    forcePredictModelStrategyOnFirstRequest?: "strict" | "smart"
  ): TasteSession {
    const tasteSession = new TasteSession(
      category,
      tasteUpdateStrategy,
      initialVisibleProducts,
      initialRatings,
      undefined,
      predictionCharacter,
      existingSessionId,
      cursorInteractionAgent,
      forcePredictModelStrategy
    );

    const firstRequest = existingSessionId ? false : true;

    tasteSession
      .loadPredictions(
        firstRequest,
        true, // to speed up first request
        {
          cursorInteractions: [],
          currentlyVisibleItemIds: fixedInitialProducts
        },
        forcePredictModelStrategyOnFirstRequest || "smart" // at first request to avoid that all are replaced after define like
      )
      .subscribe(
        (taste: Taste) => {
          taste = taste.setFirstItems(fixedInitialProducts);
          if (shuffleFirstTenImages) {
            taste = taste.shuffleFirstXItems(10);
          }
          tasteSession.updateTaste(taste);
        },
        error => {
          const errorMessage = `Could not load results for category ${category}`;
          console.error(errorMessage, error?.response?.data);
          ServerLogService.logToServer(
            `${errorMessage} - ${JSON.stringify(error?.response?.data)} - ${
              window.location
            }`
          );

          if (errorCallbackOverride) {
            errorCallbackOverride(error?.response?.data);
          } else {
            tasteSession.$errorCallback(
              ApiError.of(error?.response?.data) ||
                ApiError.unknownServerError(errorMessage)
            );
          }
        }
      );

    return tasteSession;
  }

  reset() {
    this.sessionid = User.generateId();

    UrlParam.removeSearchParam(UrlParam.URL_PARAM_NAME_SESSION_ID);
    UrlParam.removeSearchParam(UrlParam.URL_PARAM_NAME_LIKES);
    UrlParam.removeSearchParam(UrlParam.URL_PARAM_NAME_VISIBLE_PRODUCTS_AMOUNT);
    UrlParam.removeSearchParam(UrlParam.URL_PARAM_NAME_DISLIKES);
    UrlParam.removeSearchParam(UrlParam.URL_PARAM_NAME_PLP_SCROLL_STATE);
    UrlParam.removeSearchParam(
      UrlParam.URL_PARAM_NAME_TASTESUGGESTIONS_SCROLL_STATE
    );

    this.nrProductsToShow = this.initialVisibleProducts;

    this.updateRatings([], new ReplaceAllTasteUpdateStrategy(), {
      firstRequest: false,
      ignoreInteractions: true
    });
  }

  topXImgIds(amount: number): string[] {
    return this.currentTaste?.imageIds.slice(0, amount) || [];
  }

  get filtersLikedProducts(): boolean {
    return !this.tasteUpdateStrategy.keepsLikedImages;
  }

  loadMore(nrToAdditionallyLoad: number) {
    if (!this.currentTaste) {
      throw new Error(
        "can not load more if taste is not yet set! currentTaste is null!"
      );
    }

    this.nrProductsToShow = Math.min(
      this.nrProductsToShow + nrToAdditionallyLoad,
      this.maxAvailableProducts
    );

    this.emitUpdatedTaste(this.currentTaste);
  }

  get maxAvailableProducts(): number {
    return this.currentTaste?.maxAvailableArticles || 0;
  }

  get moreArticlesAvailable(): boolean {
    return this.maxAvailableProducts > this.nrProductsToShow;
  }

  isLastLoadedImage(imageId: string) {
    const lastLoadedImage = this.currentTaste?.imageIds[
      this.lastLoadedImageNumber - 1
    ];

    return lastLoadedImage === imageId;
  }

  dataInitialized(): Observable<void> {
    return this.taste().pipe(
      first(),
      map(() => {
        return;
      })
    );
  }

  visibleImageIds(): Observable<string[]> {
    return this.visibleImageIdsSubject.asObservable();
  }

  taste(): Observable<Taste> {
    return this.tasteSubject.asObservable();
  }

  public updateVisibleAreaIndexes(startIndex: number, endIndex: number) {
    this.visibleImagesIndexStart = startIndex;
    this.visibleImagesIndexEnd = endIndex;
    this.lastLoadedImageNumber = Math.max(
      this.lastLoadedImageNumber,
      endIndex + 1
    );
    this.lastEverVisibleElementIndex = Math.max(
      this.lastEverVisibleElementIndex,
      endIndex + 1
    );
  }

  public get nrOfRatings(): number {
    return this.ratings.length;
  }

  public get nrOfRatingsNotNeutral(): number {
    return this.ratings.filter(it => !it.isNeutral()).length;
  }

  public get nrOfLikes(): number {
    return this.ratings.filter(it => it.liked()).length;
  }

  private get hasRatings(): boolean {
    return this.ratings.some(rating => !rating.isNeutral());
  }

  ratingsStream(): Observable<Rating[]> {
    return this.ratingSubject.asObservable();
  }

  private loadPredictions(
    firstRequest = false,
    ignoreInteractions = false,
    interactionsDto?: InteractionsDto,
    forcepredictModelStrategy?: "strict" | "smart"
  ): Observable<Taste> {
    return this.$predictService
      .predictModel(
        this.sessionid,
        this.$user.userId,
        this.ratings,
        this.category,
        this.predictionCharacter,
        firstRequest,
        ignoreInteractions, // to speed up first request
        this.$widgetConfig?.initialProductOrder as string[],
        interactionsDto,
        forcepredictModelStrategy || this.$vendor.predictModelStrategy
      )
      .pipe(map((itemids: string[]) => new Taste(itemids)));
  }

  public compareUrl(likedItemIds: string[]): string {
    const params: URLSearchParams = new URLSearchParams(
      likedItemIds.map(itemId => ["itemids[]", itemId])
    );

    params.set("sessionid", this.sessionid);
    params.set("ratings", this.nrOfRatings.toString());
    params.set("category", this.category);

    return (
      http.defaults.baseURL + "/yourhome/compare" + "?" + params.toString()
    );
  }

  public get likedImageIds(): string[] {
    return this.ratings
      .filter(rating => rating.liked())
      .map(rating => rating.imgId);
  }

  public get dislikedImageIds(): string[] {
    return this.ratings
      .filter(rating => rating.disliked())
      .map(rating => rating.imgId);
  }

  private updateTaste(
    newTaste: Taste,
    tasteUpdateStrategy?: TasteUpdateStrategy
  ) {
    const filteredTaste = (
      tasteUpdateStrategy || this.tasteUpdateStrategy
    ).merge(
      this.currentTaste,
      newTaste,
      this.likedImageIds,
      this.dislikedImageIds,
      this.lastLoadedImageNumber,
      this.visibleImagesIndexStart,
      this.visibleImagesIndexEnd,
      this.lastEverVisibleElementIndex
    );

    this.emitUpdatedTaste(filteredTaste);

    return filteredTaste;
  }

  private emitUpdatedTaste(newTaste: Taste) {
    this.currentTaste = newTaste;

    this.tasteSubject.next(newTaste);
    this.visibleImageIdsSubject.next(newTaste.topXImage(this.nrProductsToShow));
  }

  saveSeenProductsInPlpState() {
    plpStateStore.commit(
      "updateSeenImageIds",
      this.currentTaste?.topXImage(this.lastEverVisibleElementIndex)
    );
  }

  emitRatings() {
    this.ratingSubject.next([...this.ratings]);
  }

  dislikeAllVisibleNotLikedItems() {
    this.visibleImageIds()
      .pipe(first())
      .subscribe((imageIds: string[]) => {
        const ratings = imageIds
          .filter(
            (imageId: string) => this.likedImageIds.indexOf(imageId) === -1
          )
          .map((imageId: string) => new Rating(imageId, Rating.NEG_RATING));
        this.addRatings(ratings);
      });
  }

  public removeRating(imageId: string, updateTaste = true) {
    this.ratings = this.ratings.filter(rating => !rating.isFor(imageId));
    this.emitRatings();
    if (updateTaste) {
      this.loadNewTaste();
    }
  }

  addRating(
    imageId: string,
    vote: number,
    urlPath: "plp" | "pdp" | "similarity" | "define"
  ): void {
    this.ratings = this.ratings.filter(rating => !rating.isFor(imageId));
    this.addRatings([new Rating(imageId, vote)]);

    this.$mtmService.addRating({
      entrypoint: this.$user.entrypoint || "",
      userId: this.$user.userId,
      widgetVersionName: process.env.VUE_APP_WIDGET_VERSION_NAME,
      url: "http://innofind.ch/" + urlPath,
      vendor: this.$vendor.vendorname,
      category: this.category,
      deviceType: Device.deviceType,
      rating: vote + "",
      itemId: imageId,
      activeUserReached:
        this.nrOfRatingsNotNeutral === MtmService.ACTIVE_USERS_MIN_NR_RATINGS
    });
  }

  private addRatings(ratings: Rating[]): void {
    this.updateRatings(this.ratings.concat(ratings));
  }

  private updateRatings(
    ratings: Rating[],
    forcedTasteSessionStrategy?: TasteUpdateStrategy,
    predictConfig?: { firstRequest: boolean; ignoreInteractions: boolean }
  ) {
    this.ratings = ratings;
    this.emitRatings();

    this.loadNewTaste(forcedTasteSessionStrategy, predictConfig);
  }

  private loadNewTaste(
    tasteUpdateStrategy?: TasteUpdateStrategy,
    predictConfig?: { firstRequest: boolean; ignoreInteractions: boolean }
  ) {
    const likedImages = this.ratings.filter(it => it.liked());
    const lastLikedImage = likedImages[likedImages.length - 1];

    combineLatest<Taste | string[]>([
      this.loadPredictions(
        predictConfig?.firstRequest,
        predictConfig?.ignoreInteractions,
        undefined,
        this.forcePredictModelStrategy
      ),
      this.$predictService.loadSimilarImageIds(
        this.$vendor.vendorname,
        this.category,
        lastLikedImage?.imgId,
        50
      )
    ])
      .pipe(
        map(([taste, similarItemsToLastLiked]) => {
          return {
            taste: taste as Taste,
            similarItemsToLastLiked: similarItemsToLastLiked as string[]
          };
        }),
        map(result => {
          if (this.$vendor.predictModelStrategy === "smart") {
            return result.taste;
          }

          const fourSimilarToLastLikedButNotYetSeen = new Taste(
            result.similarItemsToLastLiked
          ).firstXItemsWithout(4, this.itemsBeforeLastRating()); // first element is same as liked, so this will be ignored (3 most similar will be added)

          return result.taste.addOnTop(fourSimilarToLastLikedButNotYetSeen);
        })
      )
      .subscribe((newTaste: Taste) =>
        this.updateTaste(newTaste, tasteUpdateStrategy)
      );
  }

  indexOfLowestRating(): number | undefined {
    if (!this.currentTaste) {
      return undefined;
    }
    return Math.max(
      ...this.currentTaste.allIndexOf(this.ratings.map(it => it.imgId))
    );
  }

  private itemsBeforeLastRating(): string[] {
    return this.currentTaste?.topXImage(this.indexOfLowestRating() || 0) || [];
  }

  ratingForStream(imageId: string): Observable<number> {
    return this.ratingsStream().pipe(
      map(ratings => ratings.find(rating => rating.isFor(imageId))?.rating || 0)
    );
  }
}
