
















import { ServerLogService } from "@/logging/serverLog.service";
import { Ackermann } from "@/vendor/ackermann/main-ackermann";
import { Ottoversand } from "@/vendor/ottoversand/main-ottoversand";
import { Observable, of } from "rxjs";
import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import PDPWidget from "./PDPWidget.vue";

@Component({
  name: "PDPWidgetRoot",
  components: { PDPWidget }
})
export default class PDPWidgetRoot extends InnoFindVue {
  showWidget = false;
  itemId?: string;
  category?: string;

  mounted() {
    const initialItemId = this.$route.params.itemid
      ? this.$vendor.prefix + this.$route.params.itemid
      : null;
    if (!initialItemId) {
      this.$errorCallback("no itemid is given!");
      ServerLogService.logToServer(
        `category and itemid is mandatory for pdpWidget - no category or itemid is given for vendor: ${this.$vendor.vendorname}`
      );
      throw new Error(
        "category and itemid is mandatory for pdpWidget - no category or itemid is given"
      );
    }

    this.loadItemId(initialItemId).subscribe((itemId: string) => {
      if (!itemId && this.$vendor.pdpHideForUnknownItemid) {
        console.log("unknown itemId", initialItemId, " - hide widget");
        ServerLogService.logToServer(
          `unknown itemId for vendor ${this.$vendor.vendorname},itemid: ${initialItemId},
           - hide widget `
        );
        return;
      }

      if (!itemId) {
        console.warn(
          "open pdp widget for unknown item",
          initialItemId,
          "show default order"
        );
      }

      this.loadCategory(this.$route.params.category, itemId).subscribe(
        category => {
          if (!category) {
            this.$errorCallback("no category found!");
            return;
          }

          this.itemId = itemId;
          this.category = category;
          this.showWidget = true;
        }
      );
    });
  }

  // load the category from server (root category) if not passed to the widget
  loadCategory(
    givenCategory: string,
    initialItemId: string
  ): Observable<string | undefined> {
    if (
      !givenCategory ||
      (this.$vendor.isUnito() && givenCategory == "sofas & couches")
    ) {
      return this.$iteminfosService.rootCategoryFor(initialItemId);
    }
    return of(givenCategory);
  }

  loadItemId(initialItemId: string): Observable<string> {
    let productId = undefined;
    if (this.$vendor.isAckermann()) {
      productId = Ackermann.instance.extractPDPProductIdFromFromUrl();
    } else if (this.$vendor.isOttoversand()) {
      productId = Ottoversand.instance.extractPDPProductIdFromFromUrl();
    }

    return this.$iteminfosService.itemSubstitutePdp(initialItemId, productId);
  }

  visibilityChanged(visible: boolean) {
    if (visible) {
      //console.log("visibilityChanged", this.itemId, this.category);
      this.$abtestConfigs.abTestWholeWidget.sendGaEvent(
        this.$user.userId,
        "-visible",
        this.$route.params.category
      );
    }
  }
}
