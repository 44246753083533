














import { TasteSession } from "@/models/taste-session";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import PlpSurveyFeedback from "./PlpSurveyFeedback.vue";
import PlpSurveyInactiveUser from "./PlpSurveyInactiveUser.vue";

@Component({
  components: { PlpSurveyFeedback, PlpSurveyInactiveUser }
})
export default class PlpSurvey extends InnoFindVue {
  @Prop({ required: true })
  private currentCategory!: string;

  @Prop({ required: true })
  private tasteSession!: TasteSession;

  private showSurvey = false;

  mounted() {
    this.showSurvey =
      !this.$user.hasAlreadyFilledOutSurvey() && this.$vendor.plpRateable;
  }

  showInactiveUserSurvey() {
    return this.tasteSession.nrOfRatingsNotNeutral <= 1; // if user only has one rating (e.g. like from define) we still should show inactive survey
  }
}
