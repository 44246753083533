































































import { TasteSession } from "@/models/taste-session";
import { Component, Prop, Watch } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import ProductSliderEntry from "./ProductSliderEntry.vue";
import RateableProduct from "./RateableProduct.vue";
import LeftArrowIcon from "./icons/LeftArrowIcon.vue";
import RightArrowIcon from "./icons/RightArrowIcon.vue";
@Component({
  components: {
    RateableProduct,
    ProductSliderEntry,
    LeftArrowIcon,
    RightArrowIcon
  }
})
export default class ProductListSlider extends InnoFindVue {
  $refs!: {
    sliderWrapper: HTMLElement;
  };

  @Prop({ required: true })
  public itemIds!: string[];

  @Prop()
  public tasteSession!: TasteSession;

  @Prop({ default: "" })
  public size!: string;

  @Prop({ default: false })
  public rateable!: boolean;

  @Prop({ default: true })
  public showProductInfos!: boolean;

  @Prop({ default: false })
  public handleClickAsLike!: boolean;

  @Prop({ default: true })
  public showDetailLink!: boolean;

  @Prop()
  public useFreistellerImages!: boolean;

  @Prop({ default: false })
  private animateSlider!: boolean;

  @Prop({ required: false })
  public detailClickOrigin?: string;

  public isSliderOnLeftEnd = false;
  public isSliderOnRightEnd = false;

  private mouseOverOnceSent = false;
  private animationDirection = "right";

  mounted() {
    this.tasteSession.dataInitialized().subscribe(() => {
      this.$nextTick().then(() => {
        setTimeout(() => this.handleScroll(), 10); // calculate initial values of isSliderOnRightEnd and isSliderOnLeftEnd
      });
    });

    this.$refs.sliderWrapper.addEventListener("scroll", this.handleScroll);

    if (this.animateSlider) {
      this.animate();
    }
  }

  @Watch("itemIds")
  onItemIdsChanged(itemIds: string[], oldItemIds: string[]) {
    document.documentElement.style.setProperty(
      "--amount-slider-items",
      this.itemIds.length.toString()
    );

    this.$nextTick().then(() => {
      // wait one tick so that UI can update before calculate the scroll position
      this.handleScroll();
    });
  }

  beforeDestroy() {
    this.$refs.sliderWrapper?.removeEventListener("scroll", this.handleScroll);
  }
  private handleScroll() {
    this.isSliderOnLeftEnd = this.$refs.sliderWrapper.scrollLeft <= 0;
    this.isSliderOnRightEnd =
      this.$refs.sliderWrapper.clientWidth +
        this.$refs.sliderWrapper.scrollLeft +
        10 >= // small buffer
      this.$refs.sliderWrapper.scrollWidth;
  }

  get disableScrollbarDesktop(): boolean {
    return this.itemIds.length <= 4;
  }

  slideRight(): void {
    this.$refs.sliderWrapper.scroll({
      top: 0,
      left: this.$refs.sliderWrapper.scrollLeft + window.innerWidth * 0.8,
      behavior: "smooth"
    });
  }

  slideLeft(): void {
    this.$refs.sliderWrapper.scroll({
      top: 0,
      left: this.$refs.sliderWrapper.scrollLeft - window.innerWidth * 0.8,
      behavior: "smooth"
    });
  }

  animate() {
    setTimeout(() => {
      if (this.mouseOverOnceSent) {
        return;
      }

      if (this.isSliderOnLeftEnd) {
        this.animationDirection = "right";
      } else if (this.isSliderOnRightEnd) {
        this.animationDirection = "left";
      }

      this.animationDirection === "left" ? this.slideLeft() : this.slideRight();

      this.animate();
    }, 3500);
  }

  mouseover(event?: string) {
    //console.log("stop animation", event);
    this.mouseOverOnceSent = true;
  }
}
