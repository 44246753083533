


































import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";

@Component
export default class LikeMooris extends InnoFindVue {
  @Prop()
  private hover!: boolean;
}
