




















import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";

@Component
export default class ArrowIcon extends InnoFindVue {
  @Prop({ default: "right" })
  private direction?: "right" | "left";

  @Prop({ required: false })
  private pathCssClass?: string;

  directionLeft(): boolean {
    return this.direction === "left";
  }
}
