





































import { Rating } from "@/models/rating";
import { Component, Prop } from "vue-property-decorator";

import IconButton from "./IconButton.vue";
import InnoFindVue from "./InnoFindVue";

@Component({
  components: {
    IconButton
  }
})
export default class RateButtons extends InnoFindVue {
  @Prop()
  private rating?: number;

  public toggleLiked(event: any) {
    this.$emit("toggleLiked", event);
  }

  public isHoveringLike(hovering: boolean) {
    this.$emit("isHoveringLike", hovering);
  }

  public isHoveringDislike(hovering: boolean) {
    this.$emit("isHoveringDislike", hovering);
  }

  public toggleDisliked(event: any) {
    this.$emit("toggleDisliked", event);
  }

  public disliked(): boolean {
    return this.rating === Rating.NEG_RATING;
  }

  public liked(): boolean {
    return this.rating === Rating.POS_RATING;
  }
}
