
























import { Component } from "vue-property-decorator";
import InnoFindVue from "./../InnoFindVue";

@Component
export default class PlpSlideIn extends InnoFindVue {
  private initialPageLoad = true;
  private visible = false; // default has to be true otherwise page back wont be at same position

  mounted() {
    setTimeout(() => (this.initialPageLoad = false), 1);
  }

  inViewport(
    visibility: boolean,
    intersectionObserverEntry: IntersectionObserverEntry
  ) {
    const isElementAboveViewport =
      intersectionObserverEntry.boundingClientRect.top < 0;

    this.visible = visibility || isElementAboveViewport;
  }
}
