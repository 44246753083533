





















































































































































import { Device } from "@/models/device";
import { Navigation } from "@/models/navigation";
import { Rating } from "@/models/rating";
import { ScrollState } from "@/models/scroll-state";
import { TasteSession } from "@/models/taste-session";
import { PredictionCharacter } from "@/services/predict-service";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Component } from "vue-property-decorator";
import { plpStateStore } from "../../store/plp.state";
import PLPCursorAgent from "../agent/plp.cursor.agent";
import InnoFindVue from "../InnoFindVue";
import LikedProductListSlider from "../LikedProductListSlider.vue";
import ProductKachel from "../ProductKachel.vue";
import RateableProductList from "../RateableProductList.vue";
import PlpTutorialScribble from "../scribble/PlpTutorialScribble.vue";
import YourLikesButton from "../YourLikesButton.vue";
import CursorInteragtionsAgent from "./../agent/interaction.agent";
import Favorites from "./../Favorites.vue";
import PlpRateHintHeader from "../productlist/PlpRateHintHeader.vue";
import ProgressBarWrapper from "../ProgressBarWrapper.vue";

@Component({
  name: "PLP",
  components: {
    RateableProductList,
    ProductKachel,
    LikedProductListSlider,
    YourLikesButton,
    PlpTutorialScribble,
    Favorites,
    PlpRateHintHeader,
    ProgressBarWrapper
  }
})
export default class PLP extends InnoFindVue {
  private initialLikes: string[] = [];
  private initialDislikes: string[] = [];
  private tasteSession!: TasteSession;
  private initialVisibleProductsAmount!: number;

  private forceplp = false;
  private loading = true;
  private showStickyYourLikesButton = false;
  private plpVisible = false;

  private tutorialRunning = false;

  private readonly plpCursorAgent = new PLPCursorAgent();

  activated() {
    // to avoid race condition because dom is not yet updated
    setTimeout(() => {
      // when coming back from your likes page
      this.scrollToPreviousPosition();
    }, 10);
  }

  scrollToPreviousPosition() {
    const scrollStateString = this.$route.query.plpScrollState;

    if (!scrollStateString) {
      return;
    }

    ScrollState.scrollTo(scrollStateString);
  }

  created() {
    this.$googleTagManagerService.accessStilfinder(
      this.$route?.params?.category
    );
    this.init();
    this.plpCursorAgent?.start();
  }
  beforeDestroy() {
    this.plpCursorAgent?.stop();
  }

  private init() {
    const visibleProductsAmountString =
      this.$route.query.visibleProductsAmount || "";
    if (typeof visibleProductsAmountString === "string") {
      this.initialVisibleProductsAmount = Number.parseInt(
        visibleProductsAmountString
      );
    }

    if (this.$route.query.forceplp === true + "") {
      this.forceplp = true;
    }

    this.initTaste(this.$route.params.category).then(() => {
      this.plpCursorAgent.setSessionid(this.tasteSession.sessionid);

      this.$mtmService.initUrl({
        userId: this.$user.userId,
        url: "http://innofind.ch/plp", //Fake-URL, damit sichtbar ist dass 'PLP-Page' geöffnet ist
        vendor: this.$vendor.vendorname,
        category: this.tasteSession.category,
        entrypoint: this.$user.entrypoint || "",
        widgetVersionName: process.env.VUE_APP_WIDGET_VERSION_NAME,
        monetaryValue: "0",
        deviceType: Device.deviceType
      });
    });
  }

  get showRateHint(): boolean {
    return !this.$vendor.isHunn();
  }

  get showProgressBar(): boolean {
    return !this.$vendor.isHunn();
  }

  private get showScribbleTutorial(): boolean {
    return (
      this.$vendor.plpAlwaysShowScribble || this.tasteSession.nrOfRatings === 0
    );
  }

  private initTaste(category: string): Promise<void> {
    const initialLikes = (this.$route.query.likes as string[]) || [];
    const initialDislikes = (this.$route.query.dislikes as string[]) || [];
    const sessionid = this.$route.query.sessionid as string;

    return new Promise((resolve, reject) => {
      combineLatest([
        this.$iteminfosService.itemsSubstitutes(initialLikes),
        this.$iteminfosService.itemsSubstitutes(initialDislikes)
      ])
        .pipe(map(result => ({ likes: result[0], dislikes: result[1] })))
        .subscribe(
          (initialRatings: { likes: string[]; dislikes: string[] }) => {
            this.initialLikes = initialRatings.likes;
            this.initialDislikes = initialRatings.dislikes;

            this.tasteSession = this.initTasteSession(
              category,
              this.initialLikes,
              this.initialDislikes,
              sessionid,
              this.plpCursorAgent
            );

            this.loading = false;
            resolve();
          }
        );
    });
  }

  get showRateableProductTransparent(): boolean {
    if (
      this.$user.hasAlreadyDoneScribbleTutorial() ||
      this.tasteSession.nrOfRatings !== 0
    ) {
      return false;
    }

    return this.tutorialRunning;
  }

  goback() {
    this.$mtmService
      .sendEvent(
        "Interaction",
        "PLP_GO_BACK",
        this.tasteSession.category,
        "plp"
      )
      .subscribe(() => {
        history.back();
      });
  }

  public reset(): void {
    Navigation.scrollToInnoFindTop();
    this.tasteSession.reset();
  }

  visibilityChangedPlp(visible: boolean): void {
    this.plpVisible = visible;
    if (!visible) {
      this.showStickyYourLikesButton = false;
    }
  }

  visibilityChangedYourLikesButton(visible: boolean): void {
    this.showStickyYourLikesButton = this.plpVisible ? !visible : false;
  }

  private initTasteSession(
    category: string,
    initialLikes: string[],
    initialDislikes: string[],
    sessionid?: string,
    cursorInteractionAgent?: CursorInteragtionsAgent
  ): TasteSession {
    const initialRatings = [
      ...Rating.toRatings(initialDislikes, Rating.NEG_RATING),
      ...Rating.toRatings(initialLikes, Rating.POS_RATING)
    ];

    let forcePredictModelStrategy = this.$route.query.forcepms as
      | "strict"
      | "smart";

    if (this.$vendor.isHunn()) {
      forcePredictModelStrategy = "strict"; // for hunn we should show the most similar products on PLP because no rating buttons are shown
    }

    return TasteSession.new(
      category,
      this.$vendor.plpReplaceStrategy,
      this.initialVisibleProductsAmount ||
        this.$vendor.plpAmountOfProductsInitial,
      initialRatings,
      false,
      PredictionCharacter.DEFAULT,
      sessionid,
      plpStateStore.getters.fixedAlreadySeenImageIds.length !== 0
        ? plpStateStore.getters.fixedAlreadySeenImageIds
        : initialLikes, // set initial likes to top: Damit die auf dem define gelikten Produkte immer zuoberst kommen (ausser man kommt von einer PDP zurück)
      undefined,
      cursorInteractionAgent,
      forcePredictModelStrategy,
      forcePredictModelStrategy // for pdp widget it is importent that also the first request is forced
    );
  }

  goToLikes() {
    this.$mtmService.sendEvent(
      "Click",
      "SHOW_LIKES_FROM_YOURLIKESBUTTON",
      this.tasteSession.category,
      "likes"
    );
    Navigation.gotoLikesPage(this.tasteSession, this.$router);
  }
}
