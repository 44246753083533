import { Category } from "@/models/category";
import {
  IgnoreRatedProductsTasteUpdateStrategy,
  OnlyReplaceDislikesStrategy,
  ReplaceAllBelowLowestRatingStrategy,
  ReplaceEvery3thBelowLowestRatingStrategy,
  TasteUpdateStrategy
} from "@/models/taste-update-strategy";
import { IteminfosService } from "@/services/iteminfos-service";
import { PredictionCharacter } from "@/services/predict-service";
import { Observable, of } from "rxjs";
import { WidgetTexte } from "./widget-texts";

interface VendorConfig {
  vendorname: string;
  prefix: string;
  defineScreenWithFreisteller: boolean;
  plpWithFreisteller?: boolean;
  pdpWithFreisteller?: boolean;
  defineGreyBackground?: boolean;
  pdpGreyBackground?: boolean;
  widgetTexte: WidgetTexte;
  plpAmountOfProductsInitial?: number;
  plpRateable?: boolean;
  plpReplaceStrategy?: TasteUpdateStrategy;
  plpAlwaysShowScribble?: boolean;
  pdpLinkTarget?: string;
  pdpHideForUnknownItemid?: boolean;
  inspirationPageUrlTest?: string;
  inspirationPageUrlProd?: string;
  defaultCategory: string;
  defaultCategoryMenu?: Category[]; // all categories that can be selected in Category Menu
  ignoreCategories?: string[]; // don't display widget for all of these categories
  onlyAllowCategories?: string[]; // if undefined all categories are allowed. If set all categories not included in this list will be returned with an error
  utmParamsVendorDetailPageString?: string;
  utmParamsPLPPageString?: string;
  autoRedirectToPlpAfterLike?: boolean;
  productsPerRowOnDesktop?: number;
  productsPerRowOnMobile?: number;
  plpInfiniteScroll: boolean;
  loadMoreProductsDesktop?: number;
  loadMoreProductsMobile?: number;
  plpShowLikeBanner?: boolean;
  plpLikeBannerInterval?: number;
  currency: string;
  currencyLocale?: string;
  shuffleImagesOnDefine?: boolean;
  imageUrlParams?: string;
  showDetailLink?: boolean;
  showDetailLinkText?: boolean;
  changeImageOnHover?: boolean;
  showFavoritebar?: boolean;
  showCompareButtonOnFavoritebar?: boolean;
  defaultBorder?: boolean;
  borderProductInfos?: boolean /* line between image and product infos */;
  showProductPriceFrom?: boolean /* ob vor dem Preis "ab" geschrieben werden soll */;
  predictLimitSameProductIdPerResponse?: number /* otherwise server default is used */;
  predictLimitSameProductIdPerResponseForPdp?: number /* otherwise predictLimitSameProductIdPerResponse is used */;
  predictModelStrategy?:
    | "strict"
    | "smart" /* otherwise server default is used */;
  gtmLoadGtagScript?: boolean;
  gtmIsGA4?: boolean;
  gtmTrackingIdTest?: string;
  gtmTrackingIdProd?: string;
  abTestWholeWidget?: boolean;
}

export class Vendor {
  constructor(
    public readonly vendorname: string,
    public readonly vendorinfo: string | undefined,
    public readonly prefix: string,
    public readonly productsPerRowOnDesktop: number,
    public readonly productsPerRowOnMobile: number,
    private readonly defineScreenWithFreisteller: boolean,
    public readonly plpWithFreisteller: boolean,
    public readonly pdpWithFreisteller: boolean,
    public readonly defineGreyBackground: boolean,
    public readonly pdpGreyBackground: boolean,
    public readonly widgetTexte: WidgetTexte,
    public readonly plpRateable: boolean,
    public readonly plpInfiniteScroll: boolean,
    public readonly loadMoreProductsDesktop: number,
    public readonly loadMoreProductsMobile: number,
    public readonly plpAmountOfProductsInitial: number,
    public readonly plpShowLikeBanner: boolean,
    public readonly plpLikeBannerInterval: number,
    public readonly plpReplaceStrategy: TasteUpdateStrategy,
    public readonly plpAlwaysShowScribble: boolean,
    public readonly pdpLinkTarget: string,
    public readonly pdpHideForUnknownItemid: boolean,
    public readonly currency: string,
    public readonly currencyLocale: string,
    public readonly shuffleImagesOnDefine: boolean,
    public readonly imageUrlParams: string,
    public readonly showDetailLink: boolean, // if click on product kachel should redirect to pdp
    public readonly showDetailLinkText: boolean, // if "Produktdetail anzeigen" should shown on hover
    public readonly changeImageOnHover: boolean,
    public readonly showFavoritebar: boolean,
    public readonly showCompareButtonOnFavoritebar: boolean,
    public readonly defaultBorder: boolean,
    public readonly borderProductInfos: boolean,
    public readonly autoRedirectToPlpAfterLike: boolean,
    public readonly showProductPriceFrom: boolean,
    public readonly defaultCategory: string,
    private readonly inspirationPageUrlTest?: string,
    private readonly inspirationPageUrlProd?: string,
    public readonly defaultCategoryMenu?: Category[], // all categories that can be selected in Category Menu
    public readonly ignoreCategories?: string[], // don't display widget for all of these categories
    public readonly onlyAllowCategories?: string[], // if undefined all categories are allowed. If set all categories not included in this list will be returned with an error
    private readonly utmParamsVendorDetailPageString?: string,
    private readonly utmParamsPLPPageString?: string,
    private readonly predictLimitSameProductIdPerResponse?: number, // otherwise server default is used
    private readonly predictLimitSameProductIdPerResponseForPdp?: number, // if not set uses predictLimitSameProductIdPerResponse
    public readonly predictModelStrategy?: "strict" | "smart", // otherwise server default is used
    public readonly gtmLoadGtagScript?: boolean,
    public readonly gtmIsGA4?: boolean,
    public readonly gtmTrackingIdTest?: string,
    public readonly gtmTrackingIdProd?: string,
    public readonly abTestWholeWidget?: boolean
  ) {}

  public static ofConfig(
    config: VendorConfig,
    vendorinfo: string | undefined
  ): Vendor {
    return new Vendor(
      config.vendorname,
      vendorinfo,
      config.prefix,
      config.productsPerRowOnDesktop || 3,
      config.productsPerRowOnMobile || 1,
      config.defineScreenWithFreisteller == false ? false : true, // undefined as true
      config.plpWithFreisteller == true ? true : false, // undefined as false
      config.pdpWithFreisteller == true ? true : false, // undefined as false
      config.defineGreyBackground == false ? false : true, // undefined as true
      config.pdpGreyBackground == true ? true : false, // undefined as false
      config.widgetTexte,
      config.plpRateable || false,
      config.plpInfiniteScroll,
      config.loadMoreProductsDesktop || 12,
      config.loadMoreProductsMobile || 6,
      config.plpAmountOfProductsInitial || 12,
      config.plpShowLikeBanner || false,
      config.plpLikeBannerInterval || 72,
      config.plpReplaceStrategy || new OnlyReplaceDislikesStrategy(),
      config.plpAlwaysShowScribble == true ? true : false, // undefined as false
      config.pdpLinkTarget || "_self",
      config.pdpHideForUnknownItemid == true ? true : false, // undefined as false
      config.currency,
      config.currencyLocale || "de-CH",
      config.shuffleImagesOnDefine == false ? false : true, // undefined as true
      config.imageUrlParams || "",
      config.showDetailLink == false ? false : true, // undefined as true
      config.showDetailLinkText == false ? false : true, // undefined as true
      config.changeImageOnHover == true ? true : false, // undefined as false
      config.showFavoritebar == false ? false : true,
      config.showCompareButtonOnFavoritebar == true ? true : false, // undefined as false
      config.defaultBorder == false ? false : true, // undefined as true
      config.borderProductInfos == false ? false : true, // undefined as true
      config.autoRedirectToPlpAfterLike == false ? false : true, // undefined as true
      config.showProductPriceFrom == false ? false : true, // undefined as true
      config.defaultCategory,
      config.inspirationPageUrlTest,
      config.inspirationPageUrlProd,
      config.defaultCategoryMenu,
      config.ignoreCategories || [],
      config.onlyAllowCategories || undefined,
      config.utmParamsVendorDetailPageString,
      config.utmParamsPLPPageString,
      config.predictLimitSameProductIdPerResponse,
      config.predictLimitSameProductIdPerResponseForPdp ||
        config.predictLimitSameProductIdPerResponse,
      config.predictModelStrategy || "strict",
      config.gtmLoadGtagScript == true ? true : false, // undefined as false
      config.gtmIsGA4 == false ? false : true, // unedfined as true
      config.gtmTrackingIdTest || undefined,
      config.gtmTrackingIdProd || undefined,
      config.abTestWholeWidget == true ? true : false // undefined as false
    );
  }

  public static of(
    vendorname: string,
    entrypoint?: string,
    vendorinfo?: string
  ): Vendor {
    switch (vendorname) {
      case "yourhome":
        return Vendor.ofConfig(
          {
            vendorname: "yourhome",
            prefix: "yh-",
            defaultCategory: "stühle",
            defineScreenWithFreisteller: true,
            widgetTexte: WidgetTexte.of({
              defineTitle: "Finden statt Suchen",
              defineText:
                "Like deinen persönlichen Stil und finde schnell dein Lieblingsprodukt.",
              gotoPLPButtonText: "Produkte anzeigen",
              yourLikes: "deine Likes",
              plpTitle: "Stilfinder",
              plpTitle2: undefined
            }),
            plpReplaceStrategy: new ReplaceAllBelowLowestRatingStrategy(),
            plpRateable: true,
            plpInfiniteScroll: true,
            plpShowLikeBanner: true,
            currency: "EUR",
            pdpLinkTarget: "_blank",
            productsPerRowOnMobile: 2,
            imageUrlParams: "&maxW=800&maxH=800"
          },
          vendorinfo
        );
      case "mooris":
        return Vendor.ofConfig(
          {
            vendorname: "mooris",
            prefix: "moo-",
            defaultCategory: "stuhl",
            defaultCategoryMenu: [
              Category.of("stuhl", "Stuhl"),
              Category.of("tisch", "Tisch"),
              Category.of("licht", "Lampe")
            ],
            defineScreenWithFreisteller: true,
            defineGreyBackground: false,
            widgetTexte: WidgetTexte.of({
              defineTitle: "Finden statt Suchen",
              defineText:
                "Like deinen persönlichen Stil und finde schnell dein Lieblingsprodukt.",

              gotoPLPButtonText: "zu meinem Stil",
              yourLikes: "deine Likes",
              plpTitle: "Stilfinder",
              plpTitle2: "Finden statt Suchen"
            }),
            plpInfiniteScroll: true,
            plpRateable: true,
            plpReplaceStrategy: new ReplaceAllBelowLowestRatingStrategy(),
            inspirationPageUrlTest: "/selected/",
            inspirationPageUrlProd: "/selected/",
            productsPerRowOnDesktop: 4,
            productsPerRowOnMobile: 2,
            defaultBorder: false,
            borderProductInfos: false,
            predictLimitSameProductIdPerResponse: 1,
            showCompareButtonOnFavoritebar: true,
            currency: "CHF",
            predictModelStrategy: "smart",
            gtmIsGA4: false
          },
          vendorinfo
        );
      case "ottoversand":
        return Vendor.ofConfig(
          {
            vendorname: "ottoversand",
            prefix: "otv-",
            defaultCategory: "damenjacken",
            defaultCategoryMenu: [
              Category.of("damenjacken", "Damenjacken"),
              Category.of("damenschuhe", "Damenschuhe"),
              Category.of("bettwäsche", "Bettwäsche")
            ],
            defineScreenWithFreisteller: true, // validated by ab test
            defineGreyBackground: true,
            pdpGreyBackground: true,
            pdpHideForUnknownItemid: true,
            predictLimitSameProductIdPerResponseForPdp: 1,
            widgetTexte: WidgetTexte.of({
              defineTitle: "Finden statt Suchen",
              defineText:
                "persönlichen Stil definieren und schnell deine Lieblingsprodukte entdecken",
              gotoPLPButtonText: "zu meinem Stil",
              yourLikes: "Deine Likes",
              plpTitle: "Stilfinder",
              plpTitle2: "Finden statt Suchen",
              productInfoPriceOld: "statt"
            }),
            plpInfiniteScroll: true,
            plpRateable: true,
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
            productsPerRowOnDesktop: 4,
            productsPerRowOnMobile: 2,
            borderProductInfos: false,
            currency: "EUR",
            currencyLocale: "de-DE",
            predictModelStrategy: "strict",
            gtmTrackingIdTest: undefined,
            gtmTrackingIdProd: "UA-43871708-1",
            gtmIsGA4: false,
            imageUrlParams: "$format_p_m$"
          },
          vendorinfo
        );
      case "ackermann":
        return Vendor.ofConfig(
          {
            vendorname: "ackermann",
            prefix: "ackm-",
            defaultCategory: "kleider",
            defaultCategoryMenu: [
              Category.of("kleider", "ackermann.kleider"),
              Category.of("pullover", "ackermann.pullover"),
              Category.of("teppiche", "ackermann.teppiche")
            ],
            defineScreenWithFreisteller: true, // validated by abtest
            defineGreyBackground: true,
            pdpGreyBackground: true,
            pdpHideForUnknownItemid: true,
            predictLimitSameProductIdPerResponseForPdp: 1,
            widgetTexte: WidgetTexte.of({
              useFormalForm: true,
              defineTitle: "ackermann.texts.defineTitle",
              defineText: "ackermann.texts.defineText",
              gotoPLPButtonText: "ackermann.texts.gotoPLPButtonText",
              yourLikes: "ackermann.texts.yourLikes",
              plpTitle: "ackermann.texts.plpTitle",
              plpTitle2: "ackermann.texts.plpTitle2",
              productInfoPriceOld: "ackermann.texts.productInfoPriceOld",
              rateHintText: "rateHint.textSie",
              surveyTitle: "survey.titleSie",
              surveyWhatDidYouLike: "survey.whatDidYouLikeSie",
              surveyInactiveTitle: "survey.inactive.titleSie",
              surveyInactiveWhyNotRated: "survey.inactive.whyNotRatedSie"
            }),
            plpInfiniteScroll: true,
            plpRateable: true,
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
            currency: "CHF",
            productsPerRowOnDesktop: 4,
            productsPerRowOnMobile: 2,
            borderProductInfos: false,
            predictModelStrategy: "strict",
            gtmTrackingIdTest: undefined,
            gtmTrackingIdProd: "UA-16735579-1",
            gtmIsGA4: false,
            imageUrlParams: "$format_p_m$",
            abTestWholeWidget: true
          },
          vendorinfo
        );
      case "sheego":
        return Vendor.ofConfig(
          {
            vendorname: "sheego",
            prefix: "shg-",
            defaultCategory: "kleider",
            defaultCategoryMenu: [
              Category.of("kleider", "Kleider"),
              Category.of("jeans", "Jeans")
            ],
            defineScreenWithFreisteller: true,
            defineGreyBackground: false,
            widgetTexte: WidgetTexte.of({
              defineTitle: "Finden statt Suchen",
              defineText:
                "Like deinen persönlichen Stil und finde schnell dein Lieblingsprodukt.",
              gotoPLPButtonText: "zu meinem Stil",
              yourLikes: "deine Likes",
              plpTitle: "Stilfinder",
              plpTitle2: "Finden statt Suchen"
            }),
            plpInfiniteScroll: true,
            plpRateable: true,
            showProductPriceFrom: false,
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
            productsPerRowOnDesktop: 3,
            productsPerRowOnMobile: 2,
            borderProductInfos: false,
            currency: "EUR",
            currencyLocale: "de-DE",
            imageUrlParams: "$format_325_464$",
            gtmIsGA4: false
          },
          vendorinfo
        );
      case "connox":
        return Vendor.ofConfig(
          {
            vendorname: "connox",
            prefix: "cnx-",
            defaultCategory: "100243", // "stühle",
            defaultCategoryMenu: [
              Category.of("100199", "Vasen"),
              Category.of("100243", "Stühle"),
              Category.of("100228", "Pendelleuchten"),
              Category.of("100244", "Esstische")
            ],
            defineScreenWithFreisteller: true,
            defineGreyBackground: false,
            widgetTexte: WidgetTexte.of({
              defineTitle: "",
              defineText: "",
              gotoPLPButtonText: "zu meinem Stil",
              yourLikes: "deine Likes",
              plpTitle: "",
              plpTitle2: "",
              rateHintText: "rateHint.textSie",
              surveyTitle: "survey.titleSie",
              surveyWhatDidYouLike: "survey.whatDidYouLikeSie",
              surveyInactiveTitle: "survey.inactive.titleSie",
              surveyInactiveWhyNotRated: "survey.inactive.whyNotRatedSie"
            }),
            plpAmountOfProductsInitial: 48,
            productsPerRowOnDesktop: 4,
            productsPerRowOnMobile: 2,
            loadMoreProductsDesktop: 48,
            loadMoreProductsMobile: 24,
            plpInfiniteScroll: false,
            plpRateable: true,
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
            inspirationPageUrlTest: "/stilfinder.html",
            inspirationPageUrlProd: "/stilfinder.html",
            currency: "EUR",
            currencyLocale: "de-DE",
            gtmTrackingIdTest: undefined,
            gtmTrackingIdProd: "UA-63878-1",
            gtmIsGA4: false
          },
          vendorinfo
        );
      case "solovivo":
        return Vendor.ofConfig(
          {
            vendorname: "solovivo",
            prefix: "slv-",
            defaultCategory: "polstergruppen",
            defaultCategoryMenu: [
              Category.of("polstergruppen", "Polstergruppen"),
              Category.of("esstische", "Esstische"),
              Category.of("stuehle-hocker", "Stühle"),
              Category.of("sessel-hocker", "Sessel & Hocker")
            ],
            onlyAllowCategories: [
              "betten",
              "esstische",
              "polstergruppen",
              "salontisch",
              "sessel-hocker",
              "sideboards-kommoden",
              "stuehle-hocker",
              "canapes",
              "canape",
              "betten-1",
              "lits",
              "beds",
              "tables-1",
              "tables"
            ],
            defineScreenWithFreisteller: true,
            plpWithFreisteller: true,
            defineGreyBackground: true,
            currency: "CHF",
            widgetTexte: WidgetTexte.of({
              defineTitle: "Finden statt Suchen",
              defineText:
                "Bewerten Sie was Ihnen gefällt und finden Sie schneller Ihre Lieblingsmöbel",
              gotoPLPButtonText: "zu meinem Stil",
              yourLikes: "deine Likes",
              plpTitle: "Stilfinder",
              plpTitle2: "Finden statt Suchen",
              rateHintText: "rateHint.textSie",
              surveyTitle: "survey.titleSie",
              surveyWhatDidYouLike: "survey.whatDidYouLikeSie",
              surveyInactiveTitle: "survey.inactive.titleSie",
              surveyInactiveWhyNotRated: "survey.inactive.whyNotRatedSie"
            }),
            plpInfiniteScroll: true,
            plpRateable: true,
            plpReplaceStrategy: new ReplaceAllBelowLowestRatingStrategy(),
            inspirationPageUrlTest:
              "https://stage.solovivo.ch/de/innofind/stilfinder",
            inspirationPageUrlProd:
              "https://www.solovivo.ch/de/innofind/stilfinder",
            gtmLoadGtagScript: true,
            gtmTrackingIdTest: undefined,
            gtmTrackingIdProd: "UA-96471498-1",
            gtmIsGA4: false,
            utmParamsVendorDetailPageString:
              "utm_source=innofind&utm_medium=innofind&utm_campaign=stilfinder_{{category}}&utm_content=stilfinder_{{category}}"
          },
          vendorinfo
        );
      case "moreandmore":
        return Vendor.ofConfig(
          {
            vendorname: "moreandmore",
            prefix: "mam-",
            defaultCategory: "bekleidung>hosen-jeans",
            defaultCategoryMenu: [
              Category.of("bekleidung>hosen-jeans", "Hosen"),
              Category.of("not-imported", "Jacken"),
              Category.of("bekleidung>röcke", "Röcke")
            ],
            defineScreenWithFreisteller: true,
            plpWithFreisteller: true,
            defineGreyBackground: false,
            widgetTexte: WidgetTexte.of({
              defineTitle: "Finden statt Suchen",
              defineText:
                "persönlichen Stil definieren und schnell das Lieblingsprodukt finden.",

              gotoPLPButtonText: "zu meinem Stil",
              yourLikes: "Ihre Likes",
              plpTitle: "Stilfinder",
              plpTitle2: "Finden statt Suchen"
            }),
            plpInfiniteScroll: false,
            plpRateable: true,
            plpReplaceStrategy: new ReplaceAllBelowLowestRatingStrategy(),
            productsPerRowOnDesktop: 4,
            productsPerRowOnMobile: 2,
            defaultBorder: false,
            borderProductInfos: false,
            predictLimitSameProductIdPerResponse: 1,
            showCompareButtonOnFavoritebar: true,
            currency: "CHF",
            predictModelStrategy: "smart",
            gtmIsGA4: false
          },
          vendorinfo
        );
      case "hunn":
        return Vendor.ofConfig(
          {
            vendorname: "hunn",
            prefix: "hunn-",
            defineScreenWithFreisteller: false,
            defineGreyBackground: false,
            widgetTexte: WidgetTexte.of({
              defineTitle: "hunn.defineTitle",
              defineText: "hunn.defineText",
              gotoPLPButtonText: "hunn.gotoPLPButtonText",
              yourLikes: "hunn.yourLikes",
              plpTitle: "hunn.plpTitle",
              plpTitle2: undefined,
              rateHintText: "rateHint.textSie",
              surveyTitle: "survey.titleSie",
              surveyWhatDidYouLike: "survey.whatDidYouLikeSie",
              surveyInactiveTitle: "survey.inactive.titleSie",
              surveyInactiveWhyNotRated: "survey.inactive.whyNotRatedSie"
            }),
            plpRateable: false,
            plpReplaceStrategy: new IgnoreRatedProductsTasteUpdateStrategy(),
            inspirationPageUrlTest:
              "https://stage.hunn01.snowflakehosting.ch/inspiration/innofind",
            inspirationPageUrlProd:
              "https://www.hunn.ch/inspiration/stilfinder-ergebnisse",
            defaultCategory: "c4",
            defaultCategoryMenu: [
              Category.of("c4", "hunn.gartenstuehle"),
              Category.of("c2", "hunn.gartenlounges")
            ],
            showProductPriceFrom: false,
            currency: "", // is defined directly in ProductInfo.vue
            borderProductInfos: false,
            utmParamsVendorDetailPageString:
              "utm_source=stilfinder&utm_medium=stilfinder&utm_campaign=stilfinder_{{category}}&utm_content=stilfinder_{{category}}",
            plpInfiniteScroll: false,
            shuffleImagesOnDefine: false,
            showFavoritebar: false,
            autoRedirectToPlpAfterLike: false,
            gtmIsGA4: false
          },
          vendorinfo
        );
      // case "trenddeko":
      //   return Vendor.ofConfig(
      //     {
      //       vendorname: "trenddeko",
      //       prefix: "td-",
      //       defineScreenWithFreisteller: true,
      //       defineGreyBackground: false,
      //       widgetTexte: WidgetTexte.of({
      //         defineTitle: "Finden statt Suchen",
      //         defineText:
      //           "Like deinen persönlichen Stil und finde schnell dein Lieblingsprodukt.",
      //         gotoPLPButtonText: "zu meinem Stil",
      //         yourLikes: "deine Likes",
      //         plpTitle: "STILFINDER",
      //         plpTitle2: "Finden statt Suchen"
      //       }),
      //       plpAmountOfProductsInitial: 48,
      //       plpRateable: true,
      //       plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
      //       inspirationPageUrlTest: "http://redaktion.trenddeko.ch/stilfinder",
      //       inspirationPageUrlProd: "https://www.trenddeko.ch/stilfinder",
      //       ignoreCategories: [
      //         "766", // TODO it is integrated on Fliesenfolie but no background free images exist in datefeed
      //         "3938",
      //         "4368",
      //         "4363",
      //         "4369",
      //         "4538",
      //         "3408"
      //       ],
      //       defaultCategory: "3369",
      //       defaultCategoryMenu: [
      //         Category.of("3369", "Poster"),
      //         Category.of("792", "Leinwandbilder"),
      //         Category.of("3614", "Lampen"),
      //         Category.of("1668", "Mustertapeten"),
      //         Category.of("951", "Fototapeten")
      //       ],
      //       currency: "CHF",
      //       productsPerRowOnDesktop: 3,
      //       productsPerRowOnMobile: 2,
      //       plpInfiniteScroll: false,
      //       loadMoreProductsDesktop: 48,
      //       loadMoreProductsMobile: 24,
      //       plpShowLikeBanner: true,
      //       showDetailLink: true,
      //       showDetailLinkText: false,
      //       changeImageOnHover: true,
      //       gtmTrackingIdTest: undefined,
      //       gtmTrackingIdProd: "UA-6676222-1",
      //       gtmIsGA4: false
      //     },
      //     vendorinfo
      //   );
      case "naduvi":
        return Vendor.ofConfig(
          {
            vendorname: "naduvi",
            prefix: "nvi-",
            defineScreenWithFreisteller: false,
            defineGreyBackground: true,
            widgetTexte: WidgetTexte.of({
              defineTitle: "Te veel keuze? Vind jouw stijl!",
              defineText:
                "Vink producten aan die je mooi vindt en wij tonen je meer in dezelfde stijl.",
              gotoPLPButtonText: "Meer producten in deze stijl",
              yourLikes: "Jouw producten",
              plpTitle: "STIJLKEUZE",
              plpTitle2: "Te veel keuze? Vind jouw stijl!"
            }),
            plpRateable: true,
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
            inspirationPageUrlTest:
              "https://naduvidevelopment-shop1455innofindpoc.gtsb.io/stilfinder",
            inspirationPageUrlProd: "https://naduvi.nl/stilfinder",
            defaultCategory: "eetkamerstoelen",
            defaultCategoryMenu: [
              Category.of("eetkamerstoelen", "Stoelen"),
              Category.of("tafels", "Tagels"),
              Category.of("banken", "Banken")
            ],
            productsPerRowOnDesktop: 4,
            productsPerRowOnMobile: 2,
            borderProductInfos: false,
            showProductPriceFrom: false,
            currency: "", // dont show any currency
            currencyLocale: "nl",
            plpInfiniteScroll: false,
            plpAmountOfProductsInitial: 48,
            loadMoreProductsDesktop: 48,
            autoRedirectToPlpAfterLike: false,
            loadMoreProductsMobile: 24,
            plpShowLikeBanner: true,
            gtmTrackingIdTest: "UA-151027403-3",
            gtmTrackingIdProd: "UA-151027403-1",
            gtmIsGA4: false
          },
          vendorinfo
        );
      case "fashiondemo":
        return Vendor.ofConfig(
          {
            vendorname: "fashiondemo",
            prefix: "fashiondemo-",
            defaultCategory: "kleider",
            defaultCategoryMenu: [Category.of("kleider", "Kleider")],
            defineScreenWithFreisteller: false,
            widgetTexte: WidgetTexte.default(),
            plpInfiniteScroll: true,
            plpRateable: true,
            currency: "CHF",
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy()
          },
          vendorinfo
        );
      case "ochsnershoes":
        return Vendor.ofConfig(
          {
            vendorname: "ochsnershoes",
            prefix: "ocsh-",
            defaultCategory: "damenschuhe",
            defaultCategoryMenu: [Category.of("damenschuhe", "Damenschuhe")],
            defineScreenWithFreisteller: true,
            widgetTexte: WidgetTexte.of({
              defineTitle:
                entrypoint === "plp-plp_start"
                  ? ""
                  : "In wenigen Klicks zu Ihrem Lieblingsschuh",
              defineText:
                entrypoint === "plp-plp_start"
                  ? "In wenigen Klicks zu Ihrem Lieblingsschuh"
                  : "",
              gotoPLPButtonText: "Jetzt ausprobieren",
              yourLikes: "Ihre Likes",
              plpTitle: "In wenigen Klicks zu Ihrem Lieblingsschuh",
              plpTitle2: "",
              rateHintText: "rateHint.textSie",
              surveyTitle: "survey.titleSie",
              surveyWhatDidYouLike: "survey.whatDidYouLikeSie",
              surveyInactiveTitle: "survey.inactive.titleSie",
              surveyInactiveWhyNotRated: "survey.inactive.whyNotRatedSie"
            }),
            currency: "CHF",
            plpInfiniteScroll: true,
            plpShowLikeBanner: true,
            autoRedirectToPlpAfterLike: false,
            plpLikeBannerInterval: 36,
            plpAlwaysShowScribble: true,
            borderProductInfos: false,
            plpRateable: true,
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
            productsPerRowOnMobile: 2,
            showProductPriceFrom: false,
            gtmIsGA4: false,
            gtmTrackingIdTest: undefined,
            gtmTrackingIdProd: "UA-5966982-4"
          },
          vendorinfo
        );
      // case "pkz":
      //   return Vendor.ofConfig({
      //     vendorname: "pkz",
      //     prefix: "pkz-",
      //     defaultCategory: "246",
      //     defaultCategoryMenu: [
      //       Category.of("246", "pkz.jacken"),
      //       Category.of("240", "pkz.maentel"),
      //       Category.of("193", "pkz.hosen")
      //     ],
      //     defineScreenWithFreisteller: true,
      //     plpWithFreisteller: true,
      //     pdpWithFreisteller: true,
      //     defineGreyBackground: false,
      //     widgetTexte: WidgetTexte.of({
      //       useFormalForm: true,
      //       defineTitle: "pkz.texts.defineTitle",
      //       defineText: "pkz.texts.defineText",
      //       gotoPLPButtonText: "pkz.texts.gotoPLPButtonText",
      //       yourLikes: "pkz.texts.yourLikes",
      //       plpTitle: "pkz.texts.plpTitle",
      //       plpTitle2: "pkz.texts.plpTitle2"
      //     }),
      //     productsPerRowOnDesktop: 3,
      //     productsPerRowOnMobile: 2,
      //     borderProductInfos: false,
      //     defaultBorder: true,
      //     plpRateable: true,
      //     showProductPriceFrom: false,
      //     currency: "CHF",
      //     plpInfiniteScroll: false,
      //     plpAmountOfProductsInitial: 48,
      //     loadMoreProductsDesktop: 48,
      //     loadMoreProductsMobile: 24,
      //     plpShowLikeBanner: true,
      //     gtmTrackingIdTest: "G-3LM085EQ7Y",
      //     gtmTrackingIdProd: "G-9W2X6VRS60",
      //     plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
      //     gtmIsGA4: true
      //   });
      case "demo": // used on innofind.ch
        return Vendor.ofConfig(
          {
            vendorname: "mooris",
            prefix: "moo-",
            defaultCategory: "licht",
            defaultCategoryMenu: [Category.of("licht", "Licht")],
            defineScreenWithFreisteller: false,
            defineGreyBackground: false,
            widgetTexte: WidgetTexte.of({
              defineTitle: "demo.defineTitle",
              defineText: "",
              gotoPLPButtonText: "demo.gotoPLPButtonText",
              yourLikes: "demo.yourLikes",
              plpTitle: "",
              plpTitle2: ""
            }),
            currency: "CHF",
            plpAmountOfProductsInitial: 12,
            loadMoreProductsDesktop: 6,
            loadMoreProductsMobile: 6,
            productsPerRowOnMobile: 2,
            plpInfiniteScroll: false,
            plpRateable: true,
            gtmTrackingIdProd: "G-4X4PX3B0SB",
            gtmIsGA4: true,
            plpReplaceStrategy: new ReplaceEvery3thBelowLowestRatingStrategy(),
            showDetailLink: false,
            showDetailLinkText: false
          },
          vendorinfo
        );
    }
    throw new Error("Unknown vendor - " + vendorname);
  }

  init() {
    //@ts-ignore
    import("./../assets/css/_styles_" + this.vendorname + ".css");

    if (
      this.isOchsnershoes() &&
      window.location.href.includes("stilfinder.html")
    ) {
      // ochsner added for mobile pages overflow-x to a root div which prevents sticky to work correctly

      import(
        //@ts-ignore
        "./../assets/css/_styles_ochsnershoes-mobile-landingpage-fix.scss"
      );
    }
  }

  public initialItemId(
    passedItemId: string | undefined,
    iteminfosService: IteminfosService
  ): Observable<string | undefined> {
    if (
      this.isYourhome() &&
      window.parent.location.href.indexOf("/p/") !== -1
    ) {
      return iteminfosService.yourhomeItemOfUrl();
    }

    return of(passedItemId ? this.prefix + passedItemId : undefined);
  }

  public get gtmTrackingId(): string | undefined {
    return this.isProductionEnv()
      ? this.gtmTrackingIdProd
      : this.gtmTrackingIdTest;
  }

  public plpPageUtmParamsForCategory(category: string): string {
    if (!this.isProductionEnv()) {
      return "";
    }

    return this.utmParamsForCategory(category, this.utmParamsPLPPageString);
  }

  private utmParamsForCategory(category: string, utmParams?: string): string {
    const cat = this.isHunn() ? this.encodeCategory(category) : category;

    return utmParams?.replace(/{{category}}/g, cat) || "";
  }

  public detailPageUtmParamsForCategory(category: string): string {
    if (!this.isProductionEnv() || !this.isHunn()) {
      //RAH: only for hunn, not sure if it should be for solovivo as well -> to discuss
      return "";
    }
    return this.utmParamsForCategory(
      category,
      this.utmParamsVendorDetailPageString
    );
  }

  private encodeCategory(category: string): string {
    let cat = category.toLowerCase();
    cat = cat.replace(/ä/g, "ae");
    cat = cat.replace(/ö/g, "oe");
    cat = cat.replace(/ü/g, "ue");
    return cat;
  }

  public isProductionEnv(): boolean {
    return process.env.VUE_APP_ENVIRONMENT === "prod";
  }

  public getInspirationPageUrl(listurl: string, category: string): string {
    if (listurl) {
      return listurl;
    }

    if (
      process.env.VUE_APP_ENVIRONMENT == "local" ||
      process.env.VUE_APP_ENVIRONMENT == "demo"
    ) {
      return "";
    } else if (process.env.VUE_APP_ENVIRONMENT == "dev") {
      if (this.isMooris()) {
        return this.inspirationPageUrlTest + category;
      }
      return this.inspirationPageUrlTest || "";
    } else {
      if (this.isMooris()) {
        return this.inspirationPageUrlProd + category;
      }
      return this.inspirationPageUrlProd || "";
    }
  }

  // should show freisteller in define screen
  public shouldShowFreistellerOnDefineScreen(): boolean {
    return this.defineScreenWithFreisteller;
  }

  public predictLimitSameProductIdPerResponseFor(
    predictionCharacter: PredictionCharacter
  ): number | undefined {
    if (
      predictionCharacter === PredictionCharacter.PDP &&
      !!this.predictLimitSameProductIdPerResponseForPdp
    ) {
      return this.predictLimitSameProductIdPerResponseForPdp;
    }
    return this.predictLimitSameProductIdPerResponse;
  }

  public isYourhome(): boolean {
    return this.vendorname === "yourhome";
  }

  public isOchsnershoes(): boolean {
    return this.vendorname === "ochsnershoes";
  }

  public isTrenddeko(): boolean {
    return this.vendorname === "trenddeko";
  }

  public isHunn(): boolean {
    return this.vendorname === "hunn";
  }

  public isSolovivo(): boolean {
    return this.vendorname === "solovivo";
  }

  public isMooris(): boolean {
    return this.vendorname === "mooris";
  }

  public isAckermann(): boolean {
    return this.vendorname === "ackermann";
  }

  public isOttoversand(): boolean {
    return this.vendorname === "ottoversand";
  }

  public isUnito(): boolean {
    return this.isAckermann() || this.isOttoversand();
  }

  public isUnitoApp(): boolean {
    return this.isUnito() && this.vendorinfo === "unitoapp";
  }

  public isNaduvi(): boolean {
    return this.vendorname === "naduvi";
  }

  public isSheego(): boolean {
    return this.vendorname === "sheego";
  }

  public isConnox(): boolean {
    return this.vendorname === "connox";
  }

  public isPkz() {
    return this.vendorname === "pkz";
  }
}
