import { CursorInteractionDto } from "./dtos/cursor-interaction.dto";
import PLPCursorAgent from "./plp.cursor.agent";
import Stopwatch from "./stopwatch";

export default class ProductTile {
  private itemId: string;
  private stopWatch: Stopwatch = new Stopwatch();

  constructor(private readonly htmlElement: HTMLElement) {
    this.itemId = this.htmlElement.dataset["producttile"]!;

    this.initEventListener();
  }

  mouseEnterEventListener() {
    return (_: MouseEvent) => {
      this.stopWatch.start();
    };
  }

  mouseLeaveEventListener() {
    return (_: MouseEvent) => {
      this.stopWatch.stop();
    };
  }

  notInDom(): boolean {
    return !this.htmlElement.isConnected;
  }

  cursorInteraction(): CursorInteractionDto {
    return {
      itemId: this.itemId,
      seconds: Math.min(
        this.stopWatch.seconds(),
        PLPCursorAgent.MAX_MOUSEOVER_SECONDS
      )
    };
  }

  private initEventListener() {
    this.htmlElement.addEventListener(
      "mouseenter",
      this.mouseEnterEventListener()
    );

    this.htmlElement.addEventListener(
      "mouseleave",
      this.mouseLeaveEventListener()
    );
  }

  removeEventListener() {
    this.htmlElement.removeEventListener(
      "mouseenter",
      this.mouseEnterEventListener()
    );

    this.htmlElement.addEventListener(
      "mouseleave",
      this.mouseLeaveEventListener()
    );
  }
}
