export class Category {
  constructor(
    public readonly value: string, // value that will be sent to the server
    private readonly label: string // Label to display in web
  ) {}

  public static of(value: string, label: string): Category {
    return new Category(value, label);
  }

  public hasValue(value: string): boolean {
    return this.value === value;
  }
}
