export default class Sheego {
  public static instance = new Sheego();
  private constructor() {
    // empty constructor
  }

  trackDetailclick(detailClickOrigin?: string) {
    const category =
      detailClickOrigin === "favorites"
        ? "category/innofind/wishlist"
        : "category/innofind/pl";

    console.debug("send sheego event cat", category);

    try {
      //@ts-ignore
      window.oTracking.productCategory.save(category);
    } catch {
      console.debug("could not send sheego event");
    }
  }
}
