var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.stickyBottom},[_c('div',{class:_vm.$style.progressBarWrapper,staticStyle:{"z-index":"20"}},[_c('div',{class:_vm.$style.progressBarContainer},[_c('div',{class:_vm.$style.progressBarLabel},[_vm._v(" "+_vm._s(_vm.$t("progressBar.label"))+" ")]),_c('progress-bar',{class:_vm.$style.progressBar,attrs:{"progress":_vm.progress(),"finished":_vm.progressBarFinished()}})],1),_c('div',{class:_vm.$style.buttonAndResetContainer},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('button',{class:( _obj = {}, _obj[_vm.$style.primary] = true, _obj[_vm.$style.disabled] = !_vm.progressBarFinished(), _obj[_vm.$style.enabled] = _vm.progressBarFinished(), _obj ),attrs:{"disabled":!_vm.progressBarFinished()},on:{"click":function($event){return _vm.redirectToTastePage()}}},[_vm._v(" "+_vm._s(_vm.$t("progressBar.toMyTasteButton"))+" ")]),(_vm.progressBarFinished())?_c('div',{staticStyle:{"position":"absolute"}},_vm._l((100),function(n){return _c('i',{key:n,class:_vm.$style.confetti,style:('transform: translate3d(' +
                (_vm.random(300) - 150) +
                'px, ' +
                _vm.random(200) * -1 +
                'px, 0) rotate(' +
                _vm.random(360) +
                'deg);\
                background: hsla(' +
                _vm.random(360) +
                ',100%,50%,1);\
                ')})}),0):_vm._e()]),_c('div',{class:[_vm.$style.reset, _vm.$style.link],on:{"click":function($event){return _vm.reset()}}},[_vm._v(" "+_vm._s(_vm.$t("navigation.restart.button"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }