var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChangedWithDelay,
    once: true,
    throttle: 2000,
    intersection: {
      threshold: 0.3
    }
  }),expression:"{\n    callback: visibilityChangedWithDelay,\n    once: true,\n    throttle: 2000,\n    intersection: {\n      threshold: 0.3\n    }\n  }"}],class:_vm.$style.defineContainer,attrs:{"id":"innofind-define"}},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.visibilityChangedInstant,
      once: true
    }),expression:"{\n      callback: visibilityChangedInstant,\n      once: true\n    }"}]}),(!_vm.$abtestConfigs.abTestWholeWidget.hideWholeWidget())?_c('div',[(_vm.defaultDefine())?_c('define',{on:{"tasteSessionInitialized":function($event){return _vm.tasteSessionInitialized($event)}}}):_vm._e(),(_vm.defineOnlyLikes())?_c('define-only-likes',{on:{"tasteSessionInitialized":function($event){return _vm.tasteSessionInitialized($event)}}}):_vm._e(),(_vm.$vendor.isSolovivo())?_c('div',{staticStyle:{"margin-top":"20px"}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }