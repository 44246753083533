













import { TasteSession } from "@/models/taste-session";
import { Component, Prop } from "vue-property-decorator";
import IconButton from "../IconButton.vue";
import InnoFindVue from "../InnoFindVue";
import LikedProductListSlider from "./../LikedProductListSlider.vue";

@Component({
  components: { IconButton, LikedProductListSlider }
})
export default class PlpLikesBanner extends InnoFindVue {
  @Prop({ required: true })
  private tasteSession!: TasteSession;

  private showLikeBanner = false;

  mounted() {
    this.tasteSession.ratingsStream().subscribe(_ => {
      console.log("rating stream emitted");
      this.showLikeBanner = this.tasteSession.likedImageIds.length !== 0;
    });
  }
}
