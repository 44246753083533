


























































































import { Device } from "@/models/device";
import { Navigation } from "@/models/navigation";
import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { OnlyReplaceDislikesStrategy } from "@/models/taste-update-strategy";
import { PredictionCharacter } from "@/services/predict-service";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import GoToPlpButton from "./../GoToPlpButton.vue";
import ProductListSlider from "./../ProductListSlider.vue";

@Component({
  name: "PDPWidget",
  components: { ProductListSlider, GoToPlpButton }
})
export default class PDPWidget extends InnoFindVue {
  private readonly AMOUNT_OF_IMAGES = 9;
  private readonly BUTTON_ANIMATION_DURATION_MS = 1200;

  @Prop()
  private itemId!: string;

  @Prop()
  private category!: string;

  private tasteSession: TasteSession | null = null;

  private imageIds: string[] = [];

  private buttonAnimation = false;

  mounted() {
    this.tasteSession = TasteSession.new(
      this.category,
      new OnlyReplaceDislikesStrategy(),
      this.AMOUNT_OF_IMAGES,
      this.itemId ? [new Rating(this.itemId, Rating.POS_RATING)] : [],
      false,
      PredictionCharacter.PDP,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "strict"
    );

    this.tasteSession.visibleImageIds().subscribe((imageIds: string[]) => {
      this.imageIds = imageIds.filter(it => it !== this.itemId);
    });

    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      const lastRating = ratings[ratings.length - 1];
      if (
        lastRating?.imgId !== this.itemId &&
        lastRating?.liked() &&
        !this.buttonAnimation
      ) {
        this.userLikedInPdpWidget();
      }
    });
  }

  userLikedInPdpWidget(): void {
    // Navigation.gotoPLP(
    //   this.$vendor,
    //   this.tasteSession,
    //   this.$user,
    //   this.$mtmService,
    //   this.$router,
    //   this.$googleTagManagerService,
    //   "pdp-like",
    //   "strict"
    // );
    this.buttonAnimation = true;
    setTimeout(
      () => (this.buttonAnimation = false),
      this.BUTTON_ANIMATION_DURATION_MS
    );
  }

  gotoPLP(originEventName: "pdp-cta"): void {
    Navigation.gotoPLP(
      this.$vendor,
      this.tasteSession,
      this.$user,
      this.$mtmService,
      this.$router,
      this.$googleTagManagerService,
      originEventName,
      "strict"
    );
  }

  public visibilityChanged(visible: boolean) {
    if (!this.tasteSession) {
      return;
    }
    if (visible) {
      this.$mtmService.initUrl({
        userId: this.$user.userId,
        url: "http://innofind.ch/pdp", //Fake-URL, damit sichtbar ist dass 'PLP-Page' geöffnet ist
        vendor: this.$vendor.vendorname,
        category: this.tasteSession.category,
        entrypoint: this.$user.entrypoint || "",
        widgetVersionName: process.env.VUE_APP_WIDGET_VERSION_NAME,
        monetaryValue: "0",
        deviceType: Device.deviceType,
        pdpItemId: this.itemId
      });
    }
  }
}
