import { MtmService } from "@/services/mtm-service";
import * as ABTesting from "ab-testing";

export default class AbTest {
  private readonly abtest: any;
  private sentToServer = false;

  constructor(
    name: string,
    options: object[],
    private readonly mtmService: MtmService
  ) {
    this.abtest = ABTesting.createTest(name, options);
  }

  getGroup(userid: string, forceSendToServer = true): string {
    if (forceSendToServer && !this.sentToServer) {
      this.sendToServer(userid);
    }
    if (!userid) {
      console.warn("userid not set!");
      return "n/a";
    }
    return this.abtest.getGroup(userid);
  }

  sendToServer(userid: string) {
    if (this.sentToServer) {
      return;
    }

    this.sentToServer = true;
    this.mtmService.abTestingVariant(
      userid,
      this.abtest.getName(),
      this.getGroup(userid)
    );
  }
}
