












import { Component, Prop } from "vue-property-decorator";
import RateableProduct from "./RateableProduct.vue";
import ProductSliderEntry from "./ProductSliderEntry.vue";
import ProductListSlider from "./ProductListSlider.vue";
import { TasteSession } from "@/models/taste-session";
import { Rating } from "@/models/rating";
import InnoFindVue from "./InnoFindVue";
import { shuffle } from "@/helper/utils";

@Component({
  components: {
    RateableProduct,
    ProductSliderEntry,
    ProductListSlider
  }
})
export default class LikedProductListSlider extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  @Prop()
  private useFreistellerImages!: boolean;

  @Prop({ required: false })
  private detailClickOrigin?: string;

  private likedItemIds: string[] = [];

  mounted() {
    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      const likedItems = ratings
        .filter((rating: Rating) => rating.liked())
        .map((rating: Rating) => rating.imgId);
      if (this.likedItemIds === []) {
        shuffle(this.likedItemIds);
      }
      this.likedItemIds = likedItems;
    });
  }
}
