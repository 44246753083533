






import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";

@Component
export default class LoadingSkeleton extends InnoFindVue {
  @Prop({ default: "100%" })
  private expectedWidth?: string;
}
