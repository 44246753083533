
























































import { Navigation } from "@/models/navigation";
import { TasteSession } from "@/models/taste-session";
import { IteminfoDto } from "@/services/iteminfos-service";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import ProductImage from "./ProductImage.vue";
import ProductInfos from "./ProductInfos.vue";

@Component({
  components: { ProductImage, ProductInfos }
})
export default class ProductKachel extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  @Prop()
  public itemId!: string;

  @Prop({ default: false })
  public showProductInfos!: boolean;

  @Prop({ default: false })
  public useFreistellerImages!: boolean;

  @Prop({ default: "large" })
  public size!: "small" | "large" | "largeMobile";

  @Prop({ default: "large" })
  public sizeImage!: "small" | "large" | "largeMobile";

  @Prop({ default: true })
  public showDetailLink!: boolean;

  // only used for detailClick event
  @Prop({ required: false })
  private detailClickEventProductPositionIndex?: string;

  @Prop({ required: false })
  private detailClickOrigin?: string;

  @Prop({ default: false })
  public handleClickAsLike!: boolean;

  @Prop({ required: false, default: false })
  private noProductImageSlider!: boolean;

  public iteminfoDto: IteminfoDto | null = null;

  mounted() {
    this.$iteminfosService
      .iteminfoFor(this.itemId)
      .subscribe((iteminfoDto: IteminfoDto) => {
        this.iteminfoDto = iteminfoDto;
      });
  }

  detailPageLinkClicked($event: any) {
    if (!this.showDetailLink) {
      return;
    }

    Navigation.gotoPdp(
      this.itemId,
      this.tasteSession,
      this.$vendor,
      this.$user,
      this.$route,
      this.$googleTagManagerService,
      this.$iteminfosService,
      this.detailClickOrigin,
      this.detailClickEventProductPositionIndex
    );
  }

  goToDebugSimilaritiesPage($event: Event): void {
    $event.preventDefault();
    this.$router.push({
      name: "debugsimilarities",
      query: {
        category: this.tasteSession.category,
        itemId: this.itemId
      }
    });
  }

  public get detailPageUrl(): string {
    return Navigation.detailPageRedirectUrl(
      this.itemId,
      this.tasteSession,
      this.$user,
      this.$vendor,
      this.detailClickOrigin,
      this.detailClickEventProductPositionIndex
    );
  }
}
