































import { Component, Prop, Watch } from "vue-property-decorator";
import http from "../http-commons";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import InnoFindVue from "./InnoFindVue";

import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import { ServerLogService } from "@/logging/serverLog.service";

@Component({
  components: { LoadingSkeleton, ArrowIcon }
})
export default class ProductImage extends InnoFindVue {
  @Prop()
  private imgId!: string;

  @Prop({ required: false, default: undefined })
  private directImageUrl!: string | undefined; // if this is set the image url will not be concated with the imgId, the given url is used directly instead

  @Prop()
  private styling!: string;

  @Prop({ default: false })
  private useFreistellerImages!: boolean;

  @Prop({ default: false })
  private lazyLoadingEnabled!: boolean;

  hover = false;

  loadingError = false;
  loading = true;

  lazyLoadingInViewPort = false;

  get lazyLoadingDisabled(): boolean {
    return !this.lazyLoadingEnabled;
  }

  imageInViewport(visible: boolean) {
    if (visible) {
      this.lazyLoadingInViewPort = true;
    }
  }

  @Watch("imgId")
  onNewImageChanged(value: string, oldValue: string) {
    if (value !== oldValue) {
      this.loading = true;
      this.loadingError = false;
    }
  }

  imageLoaded() {
    this.loading = false;
    this.$emit("loaded");
  }

  imageLoadingError(e: Error) {
    if (this.hover) {
      this.hover = false;
      return;
    }

    console.error("failed to load image", this.imgId, e);

    this.loading = false;
    this.loadingError = true;

    ServerLogService.logToServer(
      "error: unavailable image " + this.imgId + " for vendor " + this.$vendor
    );
  }

  get imageUrl(): string {
    return (
      this.directImageUrl ||
      http.defaults.baseURL +
        "/image/" +
        (this.useFreistellerImages ? "freisteller/" : "") +
        this.imgId +
        "?" +
        this.$vendor.imageUrlParams
    );
  }
}
