


























import { Component, Prop } from "vue-property-decorator";
import IconButton from "../IconButton.vue";
import InnoFindVue from "../InnoFindVue";
import PlpSurveyPageTextQuestions, {
  SurveyFeedback
} from "./PlpSurveyPageTextQuestions.vue";
import PlpSurveyPageYesNo from "./PlpSurveyPageYesNo.vue";

@Component({
  components: { IconButton, PlpSurveyPageYesNo, PlpSurveyPageTextQuestions }
})
export default class PlpSurveyInactiveUser extends InnoFindVue {
  @Prop({ required: true })
  private currentCategory!: string;

  private hasSeenRateButtons = false;

  private currentSurveyPage = 1;

  mounted() {
    //this.sendEvent("SURVEY_IMPRESSION", undefined);
  }

  question2(): string | undefined {
    return this.hasSeenRateButtons
      ? this.$vendor.widgetTexte.surveyInactiveWhyNotRated
      : undefined;
  }

  private rateButtonsSeen(rateButtonsSeen: boolean) {
    this.hasSeenRateButtons = rateButtonsSeen;

    this.currentSurveyPage = 2;

    this.sendEvent(
      "SURVEY_RATE_BUTTONS_SEEN",
      rateButtonsSeen ? "seen" : "not_seen"
    );
  }

  private feedbackSubmitted(feedback: SurveyFeedback) {
    this.sendEvent("SURVEY_FEEDBACK_SUGGESTIONS", feedback.answer1);

    if (feedback.answer2) {
      this.sendEvent("SURVEY_FEEDBACK_WHY_NOT_RATED", feedback.answer2);
    }

    this.$user.surveyFinished();
    this.currentSurveyPage = 3;
  }

  private sendEvent(
    eventAction:
      | "SURVEY_RATE_BUTTONS_SEEN"
      | "SURVEY_FEEDBACK_WHY_NOT_RATED"
      | "SURVEY_FEEDBACK_SUGGESTIONS"
      | "SURVEY_IMPRESSION",
    eventName?: string
  ) {
    this.$mtmService.sendEvent(
      "SURVEY_INACTIVE",
      eventAction,
      this.currentCategory,
      "plp",
      eventName
    );
  }
}
