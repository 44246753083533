var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"innofindMainApp",class:_vm.$style.app},[(!_vm.loading)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.widgetVisibilityChanged,
      once: true,
      throttle: 300,
      intersection: {
        threshold: 0.1
      }
    }),expression:"{\n      callback: widgetVisibilityChanged,\n      once: true,\n      throttle: 300,\n      intersection: {\n        threshold: 0.1\n      }\n    }"}]},[_c('div',{class:_vm.$style.ieWarning},[_vm._v(" Ihr Browser wird nicht unterstützt. Bitte wechseln Sie zu einem modernen Browser, um den Stilfinder zu verwenden. ")]),_c('div',{class:_vm.$style.showIfNotIe},[(!_vm.error)?[_c('transition',{attrs:{"name":_vm.transitionName,"mode":"out-in"}},[_c('keep-alive',{attrs:{"include":"PLP"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)]:_vm._e()],2),(_vm.error && !_vm.$vendor.isProductionEnv())?_c('ErrorPage'):_vm._e()],1):_vm._e(),_c('confirm-dialogue',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }