import InnoFindVue from "@/components/InnoFindVue";
import { GTMEventsSessionStorage } from "./gtm-session-storage";

class GaEvents {
  public static PLP_ALL = "innofind-plp-all";
  public static INNOFIND_ALL = "innofind-all";

  public static PLP_HIGH_INTERACTIVE = "innofind-analytics-plp-hi-all";
  public static PLP_HIGH_INTERACTIVE_INNOFIND =
    "innofind-analytics-plp-hi-innofind";
  public static INNOFIND_DETAILCLICK = "innofind-analytics-dc";
}

export class GoogleTagManagerService extends InnoFindVue {
  private static GTM_REQUEST_TIMEOUT = 500;

  public static DATA_LAYER_NAME = "dataLayer";
  public static DATA_LAYER_GROUP_NAME = "iftracking";

  anyInnoFindArtifactLoaded(category?: string) {
    if (!this.$vendor.gtmIsGA4 && this.$user.isPlpEntrypointExcludeTop()) {
      this.sendEventToDatalayer("event", GaEvents.PLP_ALL, category);
    }
  }

  defineIsVisible(category?: string) {
    if (!this.$vendor.gtmIsGA4 && this.$user.isPlpEntrypointExcludeTop()) {
      this.sendEventToDatalayer(
        "event",
        GaEvents.PLP_HIGH_INTERACTIVE,
        category
      );
    }
  }

  ratingOnDefine(category?: string) {
    if (!this.$vendor.gtmIsGA4 && this.$user.isPlpEntrypointExcludeTop()) {
      this.sendEventToDatalayer(
        "event",
        GaEvents.PLP_HIGH_INTERACTIVE_INNOFIND,
        category
      );
    }
  }

  gotoPlp(category?: string): Promise<void> {
    if (this.$vendor.gtmIsGA4 || !this.$user.isPlpEntrypointExcludeTop()) {
      return new Promise(res => res());
    }

    return this.sendEventToDatalayer(
      "event",
      GaEvents.PLP_HIGH_INTERACTIVE_INNOFIND,
      category
    );
  }

  detailclick(category?: string): Promise<any> {
    const promises = [];
    promises.push(
      this.sendEventToDatalayer("event", GaEvents.INNOFIND_ALL, category)
    );

    if (!this.$vendor.gtmIsGA4) {
      promises.push(
        this.sendEventToDatalayer(
          "event",
          GaEvents.INNOFIND_DETAILCLICK,
          category
        )
      );
    }

    return Promise.all(promises);
  }

  accessStilfinder(category: string) {
    return this.sendEventToDatalayer("event", GaEvents.INNOFIND_ALL, category);
  }

  public sendEventToDatalayer(
    label: string,
    value: string,
    category?: string,
    gtmObject?: object
  ): Promise<void> {
    return new Promise(resolve => {
      if (
        !this.$vendor.gtmTrackingId ||
        GTMEventsSessionStorage.alreadySent(value)
      ) {
        resolve();
        return;
      }

      console.debug("send gtm", label, value, category);

      let isResolved = false;

      setTimeout(() => {
        if (!isResolved && label === "event") {
          console.warn("Could not send GTM Event", label, value);
          resolve();
        }
      }, GoogleTagManagerService.GTM_REQUEST_TIMEOUT * 1.1);

      GoogleTagManagerService.gtagWithRetry(label, value, {
        ...gtmObject,
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: category,
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_callback: () => {
          isResolved = true;
          GTMEventsSessionStorage.addEventSent(value);
          resolve();
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        send_to: GoogleTagManagerService.DATA_LAYER_GROUP_NAME,
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_timeout: GoogleTagManagerService.GTM_REQUEST_TIMEOUT,
        // eslint-disable-next-line @typescript-eslint/camelcase
        non_interaction: true // damit unsere ereignisse absprungrate usw. nicht kaputt machen. https://support.google.com/analytics/answer/1033068#NonInteractionEvents&zippy=%2Cthemen-in-diesem-artikel
      });
    });
  }

  public initConfig(gtmTrackingId: string) {
    GoogleTagManagerService.gtagWithRetry(
      "config",
      gtmTrackingId,
      // eslint-disable-next-line @typescript-eslint/camelcase
      {
        groups: GoogleTagManagerService.DATA_LAYER_GROUP_NAME,
        // eslint-disable-next-line @typescript-eslint/camelcase
        send_page_view: false
      }
    );
  }

  public static gtagWithRetry(
    label: string,
    value: string,
    gtmObject: any,
    retryCount = 0
  ) {
    //@ts-ignore
    if (!window[this.DATA_LAYER_NAME]) {
      if (retryCount < 5) {
        console.debug(
          "datalayer not yet initialized for " + label + ". retries:",
          retryCount
        );
        setTimeout(() => {
          this.gtagWithRetry(label, value, gtmObject, ++retryCount);
        }, 30);
      } else {
        console.log(
          "could not send ",
          label,
          value,
          "because dataLayer not available"
        );
      }
      return;
    }

    GoogleTagManagerService.gtag(label, value, gtmObject);
  }

  // attention: dont change function signatur! because arguments is used!
  public static gtag(...args: any[]) {
    // @ts-ignore
    window[this.DATA_LAYER_NAME] = window[this.DATA_LAYER_NAME] || [];

    //@ts-ignore
    // eslint-disable-next-line prefer-rest-params
    window[this.DATA_LAYER_NAME].push(arguments); // typescript args does not work therefor we have to use argument from javascript
  }

  static waitForGaFunctionIsInitialized(): Promise<void> {
    return new Promise(resolve => {
      const intervalId = setInterval(() => {
        if (window.ga) {
          clearInterval(intervalId);
          resolve(undefined); // continue as soon as ga-function is available
        }
      }, 50);

      setTimeout(() => {
        clearInterval(intervalId);
        resolve(undefined); // continue if it takes more than a second to initialize ga-function
      }, 1000);
    });
  }
}
