



































































import { Device } from "@/models/device";
import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { ReplaceAllExceptLikesStrategyForDefine } from "@/models/taste-update-strategy";
import { PredictionCharacter } from "@/services/predict-service";
import { plpStateStore } from "@/store/plp.state";
import { Component } from "vue-property-decorator";
import ButtonNewPredictions from "../ButtonNewPredictions.vue";
import CategoryMenu from "../CategoryMenu.vue";
import GoToPlpButton from "../GoToPlpButton.vue";
import IconButton from "../IconButton.vue";
import HunnThumbIcon from "../icons/HunnThumbIcon.vue";
import InnoFindVue from "../InnoFindVue";
import LikeableProduct from "../LikeableProduct.vue";
import ProductImage from "../ProductImage.vue";
import RateableProductList from "../RateableProductList.vue";
import { Navigation } from "./../../models/navigation";
import ReloadIcon from "./../icons/ReloadIcon.vue";
@Component({
  components: {
    RateableProductList,
    IconButton,
    CategoryMenu,
    GoToPlpButton,
    ProductImage,
    HunnThumbIcon,
    ReloadIcon,
    LikeableProduct,
    ButtonNewPredictions
  }
})
export default class Define extends InnoFindVue {
  private readonly AMOUNT_OF_IMAGES = 4;

  private tasteSession: TasteSession | null = null;
  private loaded = false;

  private imageIds: string[] | null = null;

  private likes: string[] = [];

  private category: string | undefined;

  created() {
    this.category =
      this.$route.params.category || this.$widgetConfig!.defaultCategory;

    if (!this.category) {
      throw "category or default category required!";
    }
    this.initTaste(this.category);
  }

  changeCategory(category: string) {
    this.category = category;
    this.initTaste(category);
    this.likes = [];
  }

  newPredictions(): void {
    if (!this.tasteSession) {
      return;
    }

    this.tasteSession?.dislikeAllVisibleNotLikedItems();
  }

  initTaste(category: string): void {
    this.tasteSession = TasteSession.new(
      category,
      new ReplaceAllExceptLikesStrategyForDefine(),
      this.AMOUNT_OF_IMAGES,
      [],
      this.$vendor.shuffleImagesOnDefine,
      PredictionCharacter.DEFAULT,
      undefined,
      undefined,
      undefined,
      undefined,
      "smart"
    );

    plpStateStore.commit("reset");
    this.tasteSession.visibleImageIds().subscribe((imageIds: string[]) => {
      this.imageIds = imageIds;
      this.loaded = true;
    });

    this.$emit("tasteSessionInitialized", this.tasteSession);
  }

  toggleLiked(rating: number, itemId: string): void {
    if (rating == Rating.POS_RATING) {
      this.likes.push(itemId);
      this.tasteSession?.addRating(itemId, rating, this.$route.meta.mtmPath);
    } else {
      const index = this.likes.indexOf(itemId);
      if (index > -1) {
        this.likes.splice(index, 1);
        this.tasteSession?.removeRating(itemId, false);
      }
    }

    if (this.likes.length >= 4) {
      this.gotoPLP("define-all-liked");
    }
  }

  gotoPLP(
    originEventName: "define-cta" | "define-like" | "define-all-liked"
  ): void {
    Navigation.gotoPLP(
      this.$vendor,
      this.tasteSession,
      this.$user,
      this.$mtmService,
      this.$router,
      this.$googleTagManagerService,
      originEventName
    );
  }
}
