





































































































import { ScreenSize } from "@/models/screen-size";
import { TasteSession } from "@/models/taste-session";
import { UrlParam } from "@/models/url-param";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import { PlpSlideInElements } from "./productlist/plp-slide-in-elements";
import PlpSlideInRoot from "./productlist/PlpSlideInRoot.vue";
import RateableProduct, { RatingEvent } from "./RateableProduct.vue";

@Component({
  components: {
    RateableProduct,
    PlpSlideInRoot
  }
})
export default class RateableProductList extends InnoFindVue {
  private static MIN_RATINGS_FOR_LOAD_MORE_BUTTON = 0;

  @Prop({ default: false })
  private allowToLoadMoreProducts!: boolean;

  @Prop({ default: true })
  private rateable!: boolean;

  @Prop({ default: false })
  private showProductInfos!: boolean;

  @Prop({ default: true })
  private showDetailLink!: boolean;

  @Prop({ default: 2 })
  private productsPerRowOnDesktop!: 2 | 3;

  @Prop({ default: 3 })
  private productsPerRowOnTablet!: 2 | 3 | 4;

  @Prop({ default: 2 })
  private productsPerRowOnMobile!: 1 | 2;

  @Prop()
  private useFreistellerImages!: boolean;

  @Prop()
  private tasteSession!: TasteSession;

  @Prop({ default: false })
  private handleClickAsLike!: boolean;

  @Prop({ default: false })
  private tutorialRunning!: boolean;

  @Prop({ default: true })
  private border!: boolean;

  @Prop({ required: false })
  private productListCssClass?: string;

  @Prop({ required: false, default: false })
  private noProductImageSlider!: boolean;

  @Prop({ required: false })
  private detailClickOrigin?: string;

  private showLoadMoreButton = false;

  private likeListAtLeastOnceVisible = false;
  private plpSlideInElements: PlpSlideInElements = PlpSlideInElements.new(
    this.$vendor,
    this.rateable
  );

  // two arrays are needed for seamless turnaround animation. Otherwise it always generates a new imageContainer
  imageIds: string[] = [];

  private visibleIndexes = new Set<number>();

  private initialDataLoaded = false;

  private static readonly INTERACTIVE_USER_AFTER_X_PRODUCTS = 20;
  private hasSentInteractiveUserEvent = false;

  private productlistInitializedSent = false;
  // used for animation
  $refs!: {
    rateableProducts: RateableProduct[];
  };

  constructor() {
    super();
  }

  public mounted(): void {
    this.tasteSession.visibleImageIds().subscribe((imageIds: string[]) => {
      this.initialDataLoaded = true;
      this.updateShownImageIds(imageIds);

      this.showOrHideLoadMoreButton();

      this.tasteSession.saveSeenProductsInPlpState();

      if (!this.productlistInitializedSent) {
        this.productlistInitializedSent = true;
        this.$nextTick().then(() => {
          this.$emit("productlistInitialized");
        });
      }
    });
  }

  visibilityChanged(isVisible: boolean, imgId: string, index: number) {
    if (isVisible) {
      this.visibleIndexes.add(this.imageIds.indexOf(imgId));
    } else {
      this.visibleIndexes.delete(this.imageIds.indexOf(imgId));
    }

    this.tasteSession.updateVisibleAreaIndexes(
      Math.min(...this.visibleIndexes),
      Math.max(...this.visibleIndexes)
    );

    this.tasteSession.saveSeenProductsInPlpState();

    // if (
    //   !this.hasSentInteractiveUserEvent &&
    //   isVisible &&
    //   index > RateableProductList.INTERACTIVE_USER_AFTER_X_PRODUCTS
    // ) {
    //   this.$mtmService.sendEvent(
    //     "Interaction",
    //     "20_PRODUCTS_SEEN",
    //     this.tasteSession.category,
    //     "plp"
    //   );

    //   this.hasSentInteractiveUserEvent = true;
    // }

    if (
      isVisible &&
      index === PlpSlideInElements.SHOW_SURVEY_AFTER_SEEN_NUMBER_OF_PRODUCTS
    ) {
      this.plpSlideInElements.displaySurvey(index, this.productsPerRow() || 4);
    }
  }

  loadMoreButtonVisibilityChanged(isVisible: boolean) {
    if (isVisible && this.$vendor.plpInfiniteScroll) {
      this.loadMore();
    }
  }

  public loadMore(): void {
    this.tasteSession.loadMore(
      ScreenSize.ofWindow().nrProductsLoadMore(this.$vendor)
    );

    if (!this.$user.alreadySentLoadMoreEvent) {
      this.$mtmService.sendEvent(
        "Interaction",
        "LOAD_MORE",
        this.tasteSession.category,
        "plp"
      );
      this.$user.alreadySentLoadMoreEvent = true;
    }

    this.updateVisibleProductsParam();
  }

  private updateVisibleProductsParam() {
    UrlParam.updateSearchParam(
      UrlParam.URL_PARAM_NAME_VISIBLE_PRODUCTS_AMOUNT,
      String(this.tasteSession.nrProductsToShow)
    );
  }

  private showOrHideLoadMoreButton(): void {
    this.showLoadMoreButton =
      this.tasteSession.moreArticlesAvailable &&
      this.tasteSession.nrOfRatings >=
        RateableProductList.MIN_RATINGS_FOR_LOAD_MORE_BUTTON;
  }

  private updateShownImageIds(imageIds: string[]) {
    this.imageIds = imageIds;
  }

  productsPerRow(): number | undefined {
    switch (ScreenSize.ofWindow().webComponentSize()) {
      case "MOBILE":
        return this.productsPerRowOnMobile;
      case "TABLET":
        return this.productsPerRowOnTablet;
      case "DESKTOP":
        return this.productsPerRowOnDesktop;
    }
  }

  imageRated(event: RatingEvent) {
    const lastLoadedImageWasRated = this.tasteSession.isLastLoadedImage(
      event.imageId
    );

    this.tasteSession.addRating(
      event.imageId,
      event.rating,
      this.$route.meta?.mtmPath
    );

    if (
      this.tasteSession.nrOfRatings ===
      PlpSlideInElements.SHOW_SURVEY_AFTER_NUMBER_OF_RATINGS
    ) {
      this.plpSlideInElements.displaySurvey(
        this.imageIds.indexOf(event.imageId),
        this.productsPerRow() || 4
      );
    }

    if (lastLoadedImageWasRated && this.allowToLoadMoreProducts) {
      this.loadMore();
    }
  }
}
