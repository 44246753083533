export class Solovivo {
  public static decodeCategory(passedCategory: string) {
    if (passedCategory === "canapes" || passedCategory === "canape") {
      return "polstergruppen"; // solovivo changes category name for different languages!
    }
    if (
      passedCategory === "betten-1" ||
      passedCategory === "lits" ||
      passedCategory === "beds"
    ) {
      return "betten";
    }
    if (passedCategory === "tables-1" || passedCategory === "tables") {
      return "esstische";
    }
    return passedCategory;
  }
}
