import http from "../http-commons";
export class ServerLogService {
  public static logToServer(logMessage: any): void {
    try {
      http.post(`/debug/dl`, { errorMessage: logMessage });
    } catch (e) {
      console.debug("could not send log to server", e);
    }
  }
}
