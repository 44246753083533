import { PlpSlideInElementsConfig } from "@/components/productlist/plp-slide-in-elements";
import { BrowserNavigation } from "@/models/browser-navigation";
import { INNOFIND_SESSIONSTORAGE_ROUTE_FROM_NAME } from "@/router";
import Vuex from "vuex";
import { vuexLocalStorage } from "../config/vuex.config";

const plpStateExpirationInMs = 1000 * 60 * 10; // 10 Mins

export const plpStateStore = new Vuex.Store<{
  fixedAlreadySeenImageIds?: string[];
  updateDate: number;
  plpSlideInElementsConfig?: string;
}>({
  state: {
    fixedAlreadySeenImageIds: [],
    updateDate: Date.now(),
    plpSlideInElementsConfig: undefined
  },
  mutations: {
    updateSeenImageIds(state: any, fixedImageIds: string[]) {
      state.fixedAlreadySeenImageIds = fixedImageIds;
      state.updateDate = Date.now();
    },

    updatePlpSlideInElementsConfig(
      state: any,
      config: PlpSlideInElementsConfig
    ) {
      state.plpSlideInElementsConfig = JSON.stringify(config);
      state.updateDate = Date.now();
    },

    reset(state: any) {
      state.fixedAlreadySeenImageIds = [];
      state.plpSlideInElementsConfig = undefined;
    }
  },
  getters: {
    fixedAlreadySeenImageIds: (state: any) => {
      if (Date.now() - state.updateDate > plpStateExpirationInMs) {
        return [];
      }

      if (
        BrowserNavigation.userCameWithBrowserBackOrForward() ||
        sessionStorage?.getItem(INNOFIND_SESSIONSTORAGE_ROUTE_FROM_NAME) ===
          "tastesuggestions"
      ) {
        return state.fixedAlreadySeenImageIds;
      }
      return [];
    },

    plpSlideInElementsConfig: (state: any) => {
      if (
        !BrowserNavigation.userCameWithBrowserBackOrForward() ||
        Date.now() - state.updateDate > plpStateExpirationInMs
      ) {
        return undefined;
      }

      return state.plpSlideInElementsConfig
        ? JSON.parse(state.plpSlideInElementsConfig)
        : undefined;
    }
  },
  plugins: [vuexLocalStorage.plugin]
});
