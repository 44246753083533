import { Vendor } from "@/vendor/vendor";
import { DeviceType } from "./device";

export class ScreenSize {
  private static readonly MAX_CONTAINER_SIZE = 1400;
  private static readonly MIN_NR_PRODUCTS_TO_SHOW = 6;
  private static readonly DESKTOP_BREAKPOINT = 1024;
  private static readonly PHONE_BREAKPOINT = 768;
  private static readonly TABLET_AND_BIGGER_BREAKPOINT = 768;
  constructor(private readonly width: number) {}

  public static ofWindow(): ScreenSize {
    return new ScreenSize(
      document.documentElement.clientWidth || window.innerWidth
    );
  }

  private get containerSize(): number {
    return Math.min(this.width, ScreenSize.MAX_CONTAINER_SIZE);
  }

  public static isPhone(): boolean {
    return screen.width <= ScreenSize.PHONE_BREAKPOINT;
  }

  public nrProductsLoadMore(vendor: Vendor): number {
    if (this.containerSize >= ScreenSize.TABLET_AND_BIGGER_BREAKPOINT) {
      return vendor.loadMoreProductsDesktop; // should be dividable by 3 and 4
    }
    // Mobile/Tablet design!
    else {
      return vendor.loadMoreProductsMobile;
    }
  }

  public webComponentSize(): DeviceType | undefined {
    const responsibleClasses = [
      ...(document?.getElementById("innofind-app")?.classList || [])
    ].filter(className => className.startsWith("wc"));

    if (responsibleClasses.length === 0) {
      console.warn(
        "can not determine web component size because no responsive classes are available"
      );
      return undefined;
    }

    if (responsibleClasses.includes("wcPhone")) {
      return DeviceType.MOBILE;
    }

    if (responsibleClasses.includes("wcDesktop")) {
      return DeviceType.DESKTOP;
    } else if (responsibleClasses.includes("wcTabletAndBigger")) {
      return DeviceType.TABLET;
    }
  }
}
