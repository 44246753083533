























import { Component } from "vue-property-decorator";
import InnoFindVue from "./../InnoFindVue";
import CursorIcon from "./../icons/CursorIcon.vue";

interface Position {
  x: number;
  y: number;
}
@Component({
  components: {
    CursorIcon
  }
})
export default class PlpTutorialScribbleCursor extends InnoFindVue {
  private readonly DEFAULT_ANIMATION_DURATION_SECONDS = 0.8;
  private readonly CLICK_ANIMATION_DURATION_MS = 100;
  private animationDurationSeconds = 2;

  private positionTop = 0;
  private positionLeft = 0;
  private startPosition!: { x: number; y: number };
  private animationFinished = false;

  mounted() {
    this.startAnimationWhenLikeButtonIsLoaded();
  }
  startAnimationWhenLikeButtonIsLoaded(retryCounter = 0) {
    if (retryCounter >= 10) {
      return; // to prevent an stack overflow endless recursion
    }

    setTimeout(() => {
      if (!document.getElementById("like-button")) {
        this.startAnimationWhenLikeButtonIsLoaded(++retryCounter);
        return;
      }
      this.startAnimation();
    }, 100);
  }

  startAnimation() {
    this.initStartPosition();
    this.animateToHtmlElementWithId("like-button");

    setTimeout(() => {
      this.animateToHtmlElementWithId("dislike-button");
    }, 3400);

    setTimeout(() => {
      this.animationFinished = true;
    }, 4500);
  }

  initStartPosition() {
    this.startPosition = this.htmlElementTopLeftPosition(
      this.$refs["plpTutorialCursor"] as HTMLElement
    );
  }

  animateToHtmlElementWithId(elementId: string) {
    const element = document.getElementById(elementId) as HTMLElement;
    if (!element) {
      throw new Error("no htmlelement found with id " + elementId);
    }
    const firstLikeButtonPosition = this.htmlElementCenterPosition(element);
    this.animateTo(firstLikeButtonPosition);
  }

  animateTo(to: Position) {
    const targetPosition = {
      x: to.x - this.startPosition.x,
      y: to.y - this.startPosition.y
    };
    this.positionTop = targetPosition.y;
    this.positionLeft = targetPosition.x;

    // click animation
    setTimeout(() => {
      this.clickAnimation();
    }, this.animationDurationSeconds * 1000);
  }

  clickAnimation() {
    this.animationDurationSeconds = this.CLICK_ANIMATION_DURATION_MS / 1000;

    this.positionTop += 4;
    this.positionLeft += 2;

    setTimeout(() => {
      this.positionTop -= 4;
      this.positionLeft -= 2;

      setTimeout(() => {
        this.animationDurationSeconds = this.DEFAULT_ANIMATION_DURATION_SECONDS;
      }, this.CLICK_ANIMATION_DURATION_MS);
    }, this.CLICK_ANIMATION_DURATION_MS);
  }
  htmlElementTopLeftPosition(element: HTMLElement): Position {
    return {
      x: window.pageXOffset + element?.getBoundingClientRect().left,
      y: window.pageYOffset + element?.getBoundingClientRect().top
    };
  }
  htmlElementCenterPosition(element: HTMLElement): Position {
    return {
      x:
        window.pageXOffset +
        element?.getBoundingClientRect().left +
        element.clientWidth / 2,
      y:
        window.pageYOffset +
        element?.getBoundingClientRect().top +
        element.clientHeight / 2
    };
  }
}
