var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],class:_vm.$style.height100},[_c('div',{class:[
      [_vm.$style.rateableProductContainer],
      [_vm.$style.height100],
      _vm.isInViewPort ? [_vm.$style.inViewport] : ''
    ]},[_c('div',{class:( _obj = {}, _obj[_vm.$style.height100] = true, _obj )},[_c('div',{class:[
          _vm.$style.height100,
          _vm.$style.productKachel,
          _vm.$style.face,
          _vm.hoverLike ? [_vm.$style.hover, _vm.$style.like] : '',
          _vm.hoverDislike ? [_vm.$style.hover, _vm.$style.dislike] : '',
          _vm.liked() ? [_vm.$style.liked] : '',
          !_vm.displayDefaultBorder ? _vm.$style.noBorder : ''
        ],on:{"click":[function($event){_vm.handleClickAsLike ? _vm.toggleLiked() : ''},function($event){_vm.rateable ? (_vm.hoverLike = false) : ''}],"mouseover":function($event){_vm.rateable && !_vm.hoverDislike && _vm.handleClickAsLike
            ? (_vm.hoverLike = true)
            : ''},"mouseout":function($event){_vm.rateable ? (_vm.hoverLike = false) : ''}}},[(_vm.rateable)?_c('rate-buttons',{attrs:{"rating":_vm.rating,"showProductInfos":_vm.showProductInfos},on:{"toggleLiked":function($event){return _vm.toggleLiked($event)},"isHoveringLike":function($event){_vm.hoverLike = $event},"isHoveringDislike":function($event){_vm.hoverDislike = $event},"toggleDisliked":function($event){return _vm.toggleDisliked($event)}}}):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('product-kachel',{key:_vm.image,attrs:{"itemId":_vm.image,"showProductInfos":_vm.showProductInfos,"handleClickAsLike":_vm.handleClickAsLike,"useFreistellerImages":_vm.useFreistellerImages,"tasteSession":_vm.tasteSession,"size":_vm.size,"sizeImage":_vm.sizeImage,"showDetailLink":_vm.showDetailLink,"detailClickEventProductPositionIndex":_vm.detailClickEventProductPositionIndex,"detailClickOrigin":_vm.detailClickOrigin,"noProductImageSlider":_vm.noProductImageSlider,"alt":"Produktbild"}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }