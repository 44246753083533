/**
 * Returns the indexes of all values ordered by max value
 */
export function sortedIndex(array: number[]): number[] {
  const topXValues = [...array].sort((a, b) => b - a);

  return topXValues.map(value => array.indexOf(value));
}

export function shuffle(array: any[]) {
  array.sort(() => Math.random() - 0.5);
}

export function shuffled(array: any[]): string[] {
  const result = [...array];
  shuffle(result);
  return result;
}

export function nextNumberDivisibleBy(
  startNumber: number,
  divider: number
): number {
  if (startNumber % divider === 0) {
    return startNumber;
  }

  const lastDividableNumber = startNumber - (startNumber % divider);
  return lastDividableNumber + divider;
}
