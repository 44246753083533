


















































import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { PredictionCharacter } from "@/services/predict-service";
import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import ProductKachel from "../ProductKachel.vue";

@Component({
  components: {
    ProductKachel
  }
})
export default class Likes extends InnoFindVue {
  private likedItemIds: string[] = [];
  private tasteSession!: TasteSession;

  created() {
    this.initTaste();

    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      this.likedItemIds = ratings
        .filter((rating: Rating) => rating.liked())
        .map((rating: Rating) => rating.imgId);
    });
  }

  private initTaste() {
    const likedItemIdsAsString = (this.$route.query.likes as string) || "";
    this.likedItemIds = likedItemIdsAsString.split(".");
    const sessionid = this.$route.query.sessionid as string;
    const category = this.$route.query.category as string;

    this.tasteSession = this.initTasteSession(
      category,
      this.likedItemIds,
      [],
      sessionid
    );
  }

  private initTasteSession(
    category: string,
    likes: string[],
    dislikes: string[],
    sessionid?: string
  ): TasteSession {
    const initialRatings = [
      ...Rating.toRatings(dislikes, Rating.NEG_RATING),
      ...Rating.toRatings(likes, Rating.POS_RATING)
    ];

    return TasteSession.new(
      category,
      this.$vendor.plpReplaceStrategy,
      this.$vendor.plpAmountOfProductsInitial,
      initialRatings,
      false,
      PredictionCharacter.DEFAULT,
      sessionid
    );
  }

  goback() {
    this.$router.go(-1);
  }
}
