import { initLocationChangeListener as initLocationChangeEvents } from "@/helper/listener";
import WebComponentRoot from "@/WebComponentRoot.vue";
import VueRouter from "vue-router";

export class Ackermann {
  public static instance: Ackermann = new Ackermann();

  private eventListenerToCleanUp: any;
  private currentUrlPathname?: string = undefined;

  /** add event listeners when category changes. Bacause ackermann does not reload the page on category change and their used dynamic yield version does not allow to rerender the dynamic yield content. So we have to trigger the category change in the widget by our selves */
  initListeners(
    webComponentRoot: WebComponentRoot,
    router: VueRouter,
    entrypoint?: string
  ) {
    if (
      !entrypoint ||
      (!entrypoint.includes("plp") && !entrypoint.includes("pdp"))
    ) {
      return;
    }

    // todo remove as soon as they went to newer dynamic yield version
    initLocationChangeEvents();

    const urlChange = () => {
      if (
        this.currentUrlPathname === window.location.pathname ||
        window.location.pathname.includes("stilfinder") ||
        window.location.hostname === "localhost"
      ) {
        console.log("url did not change since last listener callback");
        return;
      }
      this.currentUrlPathname = window.location.pathname;

      console.log("locationchange - reevaluate category ackermann");

      webComponentRoot.init(); // webcomponent has to reevaluate if widget should be displayed or not

      router.push({
        name: "define",
        params: {
          category: this.extractCategoryFromUrl() //this.category reingeben oder prioritär verwenden falls gesetzt?
        }
      });
    };

    window.addEventListener("locationchange", urlChange);

    // Useful for cleanup when component is destroyed
    this.eventListenerToCleanUp = urlChange;
  }

  removeListeners() {
    window.removeEventListener("locationchange", this.eventListenerToCleanUp);
  }

  public extractCategoryFromUrl(): string {
    const lookupTableAckermann = {
      /** SCHUHE */
      // badeschuhe: [
      //   "/damen/schuhe/badeschuhe/",
      //   "/fr/femme/chaussures/mules-de-bain/"
      // ], zu wenig produkte 24
      ballerinas: [
        "/damen/schuhe/ballerinas/",
        "/fr/femme/chaussures/ballerines/"
      ],
      boots: ["/damen/schuhe/boots/", "/fr/femme/chaussures/boots/"],
      clogs: ["/damen/schuhe/clogs/", "fr/femme/chaussures/sabots/"],
      // gummistiefel nicht vorhanden
      hausschuhe: [
        "/damen/schuhe/hausschuhe/",
        "/fr/femme/chaussures/pantoufles/"
      ],
      // "high heels": [
      //   "/damen/schuhe/high-heels/",
      //   "/fr/femme/chaussures/talons-hauts/"
      // ], zu wenig produkte 23
      klettschuhe: [
        "/damen/schuhe/klettschuhe/",
        "/fr/femme/chaussures/chaussures-a-scratch/"
      ],
      // loafer: ["/damen/schuhe/loafer/", "/fr/femme/chaussures/mocassin/"],zu wenig produkte 11
      mokassins: [
        "/damen/schuhe/mokassins/",
        "/fr/femme/chaussures/chaussures-basses/"
      ],
      pantoletten: [
        "/damen/schuhe/pantoletten/",
        "/fr/femme/chaussures/mules/"
      ],
      pumps: ["/damen/schuhe/pumps/", "/fr/femme/chaussures/escarpins/"],

      "sandalen & zehentrenner": [
        "/damen/schuhe/sandalen-zehentrenner/",
        "/fr/femme/chaussures/sandales-sandalettes/"
      ],
      sandaletten: [
        "/damen/schuhe/sandaletten/",
        "/fr/femme/chaussures/sandalettes/"
      ],
      schnürschuhe: [
        "/damen/schuhe/schnuerschuhe/",
        "/fr/femme/chaussures/chaussures-a-lacets/"
      ],
      slipper: [
        "/damen/schuhe/slipper/",
        "/fr/femme/chaussures/chaussures-sans-lacets/"
      ],
      sneakers: ["/damen/schuhe/sneakers/", "/fr/femme/chaussures/baskets/"],

      /** BILDER */
      "deko & accessoires": ["", ""], //überkategorie von bilder, nicht relevant
      // bilder: [ dont add root! is handled below!
      //   "/wohnen-garten/deko-accessoires/bilder/",
      //   "/fr/habitat-jardin/accessoires/galerie-de-peinture/"
      // ],
      // gemälde: [
      //   "/wohnen-garten/deko-accessoires/bilder/gemaelde/",
      //   "/fr/habitat-jardin/accessoires/galerie-de-peinture/peintures/"
      // ], zu wenig produkte 15
      glasbilder: [
        "/wohnen-garten/deko-accessoires/bilder/glasbilder/",
        "/fr/habitat-jardin/accessoires/galerie-de-peinture/peintures-sur-verre/"
      ],
      // holzbilder: [ // zu wenig produke!
      //   "/wohnen-garten/deko-accessoires/bilder/holzbilder/",
      //   "/fr/habitat-jardin/accessoires/galerie-de-peinture/tableaux-en-bois/"
      // ],
      kunstdrucke: [
        "/wohnen-garten/deko-accessoires/bilder/kunstdrucke/",
        "/fr/habitat-jardin/accessoires/galerie-de-peinture/impressions-sur-toile/"
      ],
      /*leinwandbilder: [ RAH, 10.07.2023: entfernt, da error (empty prediction found)
        "/wohnen-garten/deko-accessoires/bilder/leinwandbilder/",
        "/fr/habitat-jardin/accessoires/galerie-de-peinture/tableaux/"
      ],*/

      //mehrteilige-bilder nicht vorhanden
      // metallschilder nicht vorhanden
      // weitere-bilder nicht vorhanden

      // -------
      /** TEPPICHE */
      // fellteppiche nicht vorhanden
      // hochflorteppiche nicht vorhanden
      // laeufer-bettumrandungen nicht vorhanden
      // moderne-teppiche nicht vorhanden
      // naturteppiche nicht vorhanden
      // orientteppiche nicht vorhanden
      // outdoor-teppiche nicht vorhanden
      // stufenmatten nicht vorhanden
      // wohnzimmer, schlafzimmer, küchen und esszimmerteppiche nicht vorhande
      // vintage-teppiche nicht vorhanden

      "bunte teppiche": [
        "/wohnen-garten/heimtextilien-von-a-z/teppiche/weitere-teppiche/bunte-teppiche/",
        "/fr/habitat-jardin/linge-de-maison-de-a-a-z/tapis/autres-tapis/tapis-colores/"
      ], //nicht vorhanden in Shopnavigation
      damen: ["", ""], //was ist in dieser kategorie?

      designerteppiche: ["", ""], //nicht vorhanden in Shopnavigation
      "flachgewebe-teppiche": ["", ""], //nicht vorhanden in Shopnavigation

      "heimtextilien von a-z": ["", ""], //nicht relevant

      // knüpfteppiche: [ nicht vorhanden
      //   "/wohnen-garten/heimtextilien-von-a-z/teppiche/weitere-teppiche/knuepfteppiche/",
      //   "/fr/habitat-jardin/linge-de-maison-de-a-a-z/tapis/autres-tapis/tapis-noue/"
      // ], //nicht vorhanden in Shopnavigation

      // orientteppiche: [  nicht vorhanden
      //   "/wohnen-garten/heimtextilien-von-a-z/teppiche/orientteppiche/",
      //   "/fr/habitat-jardin/linge-de-maison-de-a-a-z/tapis/tapis-dorient/"
      // ],

      "runde teppiche": [
        "/wohnen-garten/heimtextilien-von-a-z/teppiche/weitere-teppiche/runde-teppiche/",
        "/fr/habitat-jardin/linge-de-maison-de-a-a-z/tapis/autres-tapis/tapis-ronds/"
      ], //nicht vorhanden in Shopnavigation

      //schlafzimmerteppiche: ["", ""], //nicht vorhanden

      //schuhe: ["/damen/schuhe/", "/fr/femme/chaussures/"], dont add root! is handled below!

      // teppiche: [ dont add root! is handled below!
      //   "/wohnen-garten/heimtextilien-von-a-z/teppiche/",
      //   "/fr/habitat-jardin/linge-de-maison-de-a-a-z/tapis/"
      // ],
      "teppiche nach räumen": ["", ""], //nicht vorhanden in Shopnavigation
      "täbriz-teppiche": ["", ""], //nicht vorhanden in Shopnavigation
      wandteppiche: ["", ""], //nicht vorhanden in Shopnavigation
      "waschbare teppiche": ["", ""], //nicht vorhanden in Shopnavigation
      webteppiche: ["", ""], //nicht vorhanden in Shopnavigation
      "weitere teppiche": ["", ""], //nicht vorhanden in Shopnavigation
      "ziegler-teppiche": ["", ""], //nicht vorhanden in Shopnavigation

      armbanduhren: ["/damen/accessoires/armbanduhren/"],

      armschmuck: ["/damen/accessoires/schmuck/armschmuck/"],
      halsschmuck: ["/damen/accessoires/schmuck/halsschmuck/"],
      ohrschmuck: ["/damen/accessoires/schmuck/ohrschmuck/"],
      schmuck: ["/damen/accessoires/schmuck/"],

      "gardinen & vorhänge": [
        "/wohnen-garten/heimtextilien-von-a-z/gardinen-vorhaenge/"
      ],

      sportbekleidung: ["/damen/mode/sportbekleidung/"],
      "t-shirts": ["/damen/mode/shirts/t-shirts/"],
      shirts: ["/damen/mode/shirts/"],

      röcke: ["/damen/mode/roecke/"],
      mäntel: ["/damen/mode/maentel/"],
      jeans: ["/damen/mode/jeans/"],
      hosen: ["/damen/mode/hosen/"],

      "klassische blusen": ["/damen/mode/blusen-tuniken/klassische-blusen/"],
      "blusen & tuniken": ["/damen/mode/blusen-tuniken/"],
      blazer: ["/damen/mode/blazer/"],
      tops: ["/damen/mode/tops/"],
      strickjacken: [
        "/damen/mode/strickjacken/",
        "/damen/mode/strickjacken/strickjacken/"
      ],

      einzelsofas: [
        "/wohnen-garten/moebel-von-a-z/polstermoebel-sofas/einzelsofas/",
        "/fr/habitat-jardin/meubles-de-a-a-z/meubles-capitonnes-canapes/canapes-individuels/"
      ],
      bettsofa: [
        "/wohnen-garten/moebel-von-a-z/polstermoebel-sofas/bettsofa/",
        "/fr/habitat-jardin/meubles-de-a-a-z/meubles-capitonnes-canapes/des-canapes-lit/"
      ],
      wohnlandschaften: [
        "/wohnen-garten/moebel-von-a-z/polstermoebel-sofas/wohnlandschaften/",
        "/fr/habitat-jardin/meubles-de-a-a-z/meubles-capitonnes-canapes/canapes-panoramiques/"
      ],
      ecksofa: [
        "/wohnen-garten/moebel-von-a-z/polstermoebel-sofas/",
        "/fr/habitat-jardin/meubles-de-a-a-z/meubles-capitonnes-canapes/",
        "/wohnen-garten/moebel-von-a-z/polstermoebel-sofas/ecksofa/",
        "/fr/habitat-jardin/meubles-de-a-a-z/meubles-capitonnes-canapes/canapes-dangle/"
      ],
      kommoden: [
        "/wohnen-garten/moebel-von-a-z/kommoden-sideboards/kommoden/",
        "/fr/habitat-jardin/meubles-de-a-a-z/commodes-buffets/commodes/"
      ],
      sideboards: [
        "/wohnen-garten/moebel-von-a-z/kommoden-sideboards/sideboards/",
        "/fr/habitat-jardin/meubles-de-a-a-z/commodes-buffets/buffets/"
      ],

      "kommoden & sideboards": [
        "/wohnen-garten/moebel-von-a-z/kommoden-sideboards/",
        "/fr/habitat-jardin/meubles-de-a-a-z/commodes-buffets/"
      ],

      "damen-taschen": [
        "/damen/accessoires/taschen-rucksaecke-reisegepaeck/damen-taschen/",
        "/femme/accessoires/sacs-sacs-a-dos-bagages/sacs-pour-femme/"
      ],

      rucksäcke: [
        "/damen/accessoires/taschen-rucksaecke-reisegepaeck/rucksaecke/",
        "/femme/accessoires/sacs-sacs-a-dos-bagages/sacs-a-dos/"
      ],

      sporttaschen: [
        "/sport-freizeit/sportausruestung/sporttaschen-rucksaecke/",
        "/sport-loisirs/besoin-de-sport/sacs-sacs-a-dos/"
      ],

      "taschen, rucksäcke & reisegepäck": [
        "/damen/accessoires/taschen-rucksaecke-reisegepaeck/",
        "femme/accessoires/sacs-sacs-a-dos-bagages/"
      ],

      //"tankinis": ["", ""], 15.02.23: aktuell nur 3 produkte importiert */

      /*mixkinis: ["/damen/bademode-waesche/bademode/mixkinis/", "/fr/femme/mode-balneaire-lingerie/mode-balneaire-pour-femme/mixkinis/"], 15.02.23: aktuell nur 23 produkte importiert */

      /*"bikini-sets": [
        "/damen/bademode-waesche/bademode/bikinis/bikini-sets/",
        "/fr/femme/mode-balneaire-lingerie/mode-balneaire-pour-femme/bikinis/ensembles-de-bikinis/"
      ], 15.02.23: aktuell nur 90 produkte importiert */

      "bikini oberteile": [
        "/damen/bademode-waesche/bademode/bikinis/bikini-oberteile/",
        "/fr/femme/mode-balneaire-lingerie/mode-balneaire-pour-femme/bikinis/hauts-de-bikini/"
      ],

      "bikini hosen": [
        "/damen/bademode-waesche/bademode/bikinis/bikini-hosen/",
        "/fr/femme/mode-balneaire-lingerie/mode-balneaire-pour-femme/bikinis/bas-de-bikini/"
      ],

      bikinis: [
        "/damen/bademode-waesche/bademode/bikinis/",
        "/fr/femme/mode-balneaire-lingerie/mode-balneaire-pour-femme/bikinis/"
      ],

      badeanzüge: [
        "/damen/bademode-waesche/bademode/badeanzuege/",
        "/fr/femme/mode-balneaire-lingerie/mode-balneaire-pour-femme/maillots-de-bain/"
      ],

      bademode: [
        "/damen/bademode-waesche/bademode/",
        "/fr/femme/mode-balneaire-lingerie/mode-balneaire-pour-femme/"
      ],

      "bademode & wäsche": [
        "/damen/bademode-waesche/",
        "/fr/femme/mode-balneaire-lingerie/"
      ]
    };

    const urlPath = window.location.pathname.toLowerCase();

    const category = this.categoryFromLookupTable(lookupTableAckermann);
    if (category) {
      return category;
    }

    //Fallback if its a child category, which is not in our lookuptable
    if (
      urlPath.indexOf("/damen/schuhe/") > -1 ||
      urlPath.indexOf("/fr/femme/chaussures/") > -1
    ) {
      return "schuhe";
    }

    if (
      urlPath.indexOf("/wohnen-garten/heimtextilien-von-a-z/teppiche/") > -1 ||
      urlPath.indexOf("/fr/habitat-jardin/linge-de-maison-de-a-a-z/tapis/") > -1
    ) {
      return "teppiche";
    }

    if (
      urlPath.indexOf("/wohnen-garten/deko-accessoires/bilder/") > -1 ||
      urlPath.indexOf("/fr/habitat-jardin/accessoires/galerie-de-peinture/") >
        -1
    ) {
      return "bilder";
    }

    if (
      urlPath.indexOf("/damen/mode/jacken") > -1 ||
      urlPath.indexOf("femme/mode/vestes") > -1
    ) {
      return "jacken";
    }
    if (
      urlPath.indexOf("/damen/mode/kleider") > -1 ||
      urlPath.indexOf("/femme/mode/robes") > -1
    ) {
      return "kleider";
    }
    if (
      urlPath.indexOf("/damen/mode/pullover") > -1 ||
      urlPath.indexOf("/femme/mode/pulls") > -1
    ) {
      return "pullover";
    }
    if (
      urlPath.indexOf("/damen/bademode-waesche") > -1 ||
      urlPath.indexOf("/femme/mode/mode-balneaire-lingerie") > -1
    ) {
      return "bademode & wäsche";
    }

    if (
      urlPath.indexOf("wohnen-garten/deko-accessoires/weihnachtsdekoration") >
        -1 ||
      urlPath.indexOf("habitat-jardin/accessoires/decoration-de-noel") > -1
    ) {
      return "weihnachtsdekoration";
    }

    //Searchpage: show Category if matching category from searchstring:
    // TODO currently we don't show the Widget on the Search Result page
    // if (urlPath.startsWith("/s/")) {
    //   for (const categoryKey of Object.keys(lookupTableAckermann)) {
    //     if (urlPath.indexOf(categoryKey) > -1) {
    //       return categoryKey;
    //     }
    //   }

    //   if (urlPath.indexOf("poster") > -1 || urlPath.indexOf("bild") > -1) {
    //     return "bilder";
    //   }

    //   if (urlPath.indexOf("teppich") > -1) {
    //     return "teppiche";
    //   }

    //   if (urlPath.indexOf("weihnachtsdeko") > -1) {
    //     return "weihnachtsdekoration";
    //   }

    //   if (urlPath.indexOf("mantel") > -1) {
    //     return "mäntel";
    //   }

    //   if (urlPath.indexOf("tasche") > -1) {
    //     return "damen-taschen";
    //   }

    //   if (urlPath.indexOf("kommoden") > -1) {
    //     return "kommoden";
    //   }

    //   if (urlPath.indexOf("sofa") > -1 || urlPath.indexOf("couch") > -1) {
    //     return "ecksofa";
    //   }

    //   if (urlPath.indexOf("bikini") > -1) {
    //     return "bikinis";
    //   }

    //   if (urlPath.indexOf("jacken") > -1) {
    //     return "jacken";
    //   }
    //   if (urlPath.indexOf("kleider") > -1) {
    //     return "kleider";
    //   }
    //   if (urlPath.indexOf("pullover") > -1) {
    //     return "pullover";
    //   }
    // }

    //Else: Hide Widget
    return "";
  }

  public extractPDPItemIdFromFromUrl(
    urlQueryString = window.location.search
  ): string {
    const urlParams = new URLSearchParams(urlQueryString);

    let sku = urlParams.get("sku");
    if (sku) {
      sku = sku.split("#")[0];

      if (sku.includes("-")) {
        // wenn es einen bindestrich in der SKU hat, ist der erste Teil direkt unsere itemid
        return sku.split("-")[0];
      }

      // ansonsten ist unsere ID die SKU minus die letzten beiden Ziffern
      return sku.substring(0, sku.length - 2);
    }

    return "";
  }

  public extractPDPProductIdFromFromUrl(): string {
    const pathParts = window.location.pathname.split("/");
    return "ackm-" + pathParts[pathParts.length - 1];
  }

  public overrideEntrypoint(
    givenEntrypoint: string | undefined
  ): string | undefined {
    if (
      (givenEntrypoint === "plp-unten" || givenEntrypoint === "plp-mitte") &&
      window.location.pathname.startsWith("/s/")
    ) {
      return "plp-search";
    }
    return givenEntrypoint;
  }

  private categoryFromLookupTable(lookupTable: object): string {
    for (const key in lookupTable) {
      if (
        //@ts-ignore
        lookupTable[key].includes(window.location.pathname)
      ) {
        return key;
      }
    }

    return "";
  }
}
