





























































import { IteminfoDto } from "@/services/iteminfos-service";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import LoadingSkeleton from "./LoadingSkeleton.vue";
import TextOrLoadingSkeleton from "./TextOrLoadingSkeleton.vue";

@Component({
  components: { LoadingSkeleton, TextOrLoadingSkeleton }
})
export default class ProductInfos extends InnoFindVue {
  @Prop()
  private itemId!: string;

  @Prop({ default: "large" })
  private size!: "small" | "large";

  @Prop()
  private iteminfo: IteminfoDto | null = null;

  getFormattedPrice(priceString: string): string {
    if (!priceString) {
      return "";
    }

    const priceAsNumber = parseFloat(priceString);

    let formatter;
    if (this.$vendor.currency) {
      formatter = new Intl.NumberFormat(this.$vendor.currencyLocale, {
        style: "currency",
        currency: this.$vendor.currency || "",
        minimumFractionDigits: 2
      });
    } else {
      formatter = new Intl.NumberFormat(this.$vendor.currencyLocale, {
        minimumFractionDigits: 2
      });
    }

    return formatter.format(priceAsNumber);
  }

  get noBorder(): boolean {
    return !this.$vendor.borderProductInfos;
  }

  getOldPrice(): string | undefined {
    if (!this.iteminfo) {
      return undefined;
    }
    if (!this.hasOldPrice()) {
      return "";
    }

    return this.getFormattedPrice(this.iteminfo.priceOld);
  }
  hasOldPrice(): boolean {
    return (
      !!this.iteminfo?.priceOld &&
      parseFloat(this.iteminfo?.priceOld) > parseFloat(this.iteminfo?.price)
    );
  }
}
