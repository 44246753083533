var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.container,
    _vm.$style.define,
    _vm.$vendor.isMooris() ? [_vm.$style.limitMaxWidth] : '',
    _vm.$vendor.defineGreyBackground ? [_vm.$style.greyBackground] : ''
  ]},[_c('div',{class:_vm.$style.colContainer},[_c('div',{class:[_vm.$style.paddingLeftRight]},[_c('span',{class:_vm.$style.heading1},[_vm._v(_vm._s(_vm.$t(_vm.$vendor.widgetTexte.defineTitle)))]),(
          _vm.tasteSession &&
            !_vm.$route.params.category &&
            this.$widgetConfig.hasCategoryMenu()
        )?_c('category-menu',{class:_vm.$style.categoryMenu,attrs:{"selectedCategory":_vm.tasteSession.category},on:{"categoryChanged":_vm.changeCategory}}):_vm._e(),_c('p',{class:_vm.$style.text},[_vm._v(" "+_vm._s(_vm.$t(_vm.$vendor.widgetTexte.defineText))+" ")])],1),(!_vm.showCategoryNotAvailableError)?_c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.tasteSession)?_c('rateable-product-list',{key:_vm.tasteSession.sessionid,attrs:{"useFreistellerImages":_vm.useFreistellerImages,"tasteSession":_vm.tasteSession,"showDetailLink":false,"handleClickAsLike":_vm.handleClickAsLiked(),"productsPerRowOnDesktop":4,"productsPerRowOnTablet":4,"productListCssClass":[
              _vm.$style.productListFixedGridGap,
              _vm.$vendor.isOchsnershoes()
                ? _vm.$style.ochsnerShoesProductListStyles
                : ''
            ].join(' '),"border":true,"noProductImageSlider":true,"detailClickOrigin":"define"}}):_vm._e()],1),(_vm.showGefaelltNichtsButton)?_c('button',{class:[_vm.$style.newRecommendations],on:{"click":function($event){return _vm.newRecommendations()}}},[_vm._v(" Mir gefällt nichts ")]):_vm._e(),(!_vm.showGefaelltNichtsButton)?_c('go-to-plp-button',{key:_vm.tasteSession.sessionid,attrs:{"tasteSession":_vm.tasteSession},on:{"clicked":function($event){return _vm.gotoPLP('define-cta')}}}):_vm._e()],1):_c('div',[_vm._v(_vm._s(_vm.$t("define.categoryNotAvailable")))])]),(_vm.$vendor.isSolovivo())?_c('div',{staticStyle:{"margin-top":"20px"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }