


























import { TasteSession } from "@/models/taste-session";
import { Component, Prop } from "vue-property-decorator";
import IconButton from "../IconButton.vue";
import InnoFindVue from "../InnoFindVue";
import PlpSurveyPageYesNo from "./PlpSurveyPageYesNo.vue";
import PlpSurveyPageTextQuestions, {
  SurveyFeedback
} from "./PlpSurveyPageTextQuestions.vue";

@Component({
  components: { IconButton, PlpSurveyPageYesNo, PlpSurveyPageTextQuestions }
})
export default class PlpSurveyFeedback extends InnoFindVue {
  @Prop({ required: true })
  private currentCategory!: string;

  @Prop({ required: true })
  private tasteSession!: TasteSession;

  private currentSurveyPage = 1;

  mounted() {
    //this.sendEvent("SURVEY_IMPRESSION", undefined);
  }

  private surveyType(): "SURVEY_ONLY_DISLIKE" | "SURVEY_WITH_LIKES" | "SURVEY" {
    if (
      this.tasteSession.nrOfRatingsNotNeutral > 0 &&
      this.tasteSession.nrOfLikes === 0
    ) {
      return "SURVEY_ONLY_DISLIKE";
    }

    if (this.tasteSession.nrOfLikes > 0) {
      return "SURVEY_WITH_LIKES";
    }

    return "SURVEY";
  }

  private voted(liked: boolean) {
    this.currentSurveyPage = 2;

    this.sendEvent("SURVEY_VOTE", liked ? "liked" : "disliked");
  }

  private feedbackSubmitted(feedback: SurveyFeedback) {
    this.sendEvent("SURVEY_FEEDBACK_SUGGESTIONS", feedback.answer1);

    this.sendEvent("SURVEY_FEEDBACK_LIKED", feedback.answer2);

    this.$user.surveyFinished();
    this.currentSurveyPage = 3;
  }

  private sendEvent(
    eventAction:
      | "SURVEY_VOTE"
      | "SURVEY_FEEDBACK_LIKED"
      | "SURVEY_FEEDBACK_SUGGESTIONS"
      | "SURVEY_IMPRESSION",
    eventName?: string
  ) {
    this.$mtmService.sendEvent(
      this.surveyType(),
      eventAction,
      this.currentCategory,
      "plp",
      eventName
    );
  }
}
