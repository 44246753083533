

























import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import ProductImage from "../ProductImage.vue";

@Component({
  components: {
    ProductImage
  }
})
export default class DebugSimilareImagesPage extends InnoFindVue {
  private static NR_TO_SHOW = 10;
  private itemIds: string[] = [];
  created() {
    const itemId = this.$route.query.itemId as string;
    const category = this.$route.query.category as string;

    this.$predictService
      .loadSimilarImageIds(
        this.$vendor.vendorname,
        category,
        itemId,
        DebugSimilareImagesPage.NR_TO_SHOW
      )
      .subscribe((itemIds: string[]) => {
        this.itemIds = itemIds.slice(0, DebugSimilareImagesPage.NR_TO_SHOW);
      });
  }
  goback() {
    this.$router.go(-1);
  }
}
