











































































import { Device } from "@/models/device";
import { Navigation } from "@/models/navigation";
import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
import { RemoveAllVisibleDuringADislike } from "@/models/taste-update-strategy";
import { PredictionCharacter } from "@/services/predict-service";
import { plpStateStore } from "@/store/plp.state";
import { Component } from "vue-property-decorator";
import CategoryMenu from "../CategoryMenu.vue";
import GoToPlpButton from "../GoToPlpButton.vue";
import IconButton from "../IconButton.vue";
import InnoFindVue from "../InnoFindVue";
import RateableProductList from "../RateableProductList.vue";

@Component({
  components: {
    RateableProductList,
    IconButton,
    CategoryMenu,
    GoToPlpButton
  }
})
export default class Define extends InnoFindVue {
  private readonly AMOUNT_OF_IMAGES = 4;
  private plpButtonEnabled = false;

  private tasteSession: TasteSession | null = null;
  private loaded = false;

  public showCategoryNotAvailableError = false;

  private ratings?: Rating[];

  created() {
    const category =
      this.$route.params.category || this.$widgetConfig!.defaultCategory;

    if (!category) {
      throw "category or default category required!";
    }
    this.initTaste(category);

    this.loaded = true;
  }

  handleClickAsLiked(): boolean {
    return !this.$vendor.isHunn();
  }

  initTaste(category: string, errorCallback?: (err: string) => void): void {
    this.tasteSession = TasteSession.new(
      category,
      new RemoveAllVisibleDuringADislike(),
      this.AMOUNT_OF_IMAGES,
      [],
      this.$vendor.shuffleImagesOnDefine,
      PredictionCharacter.DEFAULT,
      undefined,
      undefined,
      errorCallback,
      undefined,
      "smart"
    );

    plpStateStore.commit("reset");

    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      this.ratingsUpdated(ratings);
    });

    this.$emit("tasteSessionInitialized", this.tasteSession);
  }

  newRecommendations() {
    if (!this.tasteSession) {
      return;
    }
    this.tasteSession.dislikeAllVisibleNotLikedItems();
  }

  get useFreistellerImages(): boolean {
    return this.$vendor.shouldShowFreistellerOnDefineScreen();
  }

  ratingsUpdated(ratings: Rating[]) {
    this.ratings = ratings;

    const amountOfLikes = ratings.filter((rating: Rating) => rating.liked())
      .length;

    if (amountOfLikes >= 1) {
      this.plpButtonEnabled = true;
    } else {
      this.plpButtonEnabled = false;
    }

    if (amountOfLikes >= this.goToPlpAfterXLikes) {
      this.gotoPLP("define-like");
    }
  }

  get goToPlpAfterXLikes(): number {
    return this.$vendor.autoRedirectToPlpAfterLike ? 1 : this.AMOUNT_OF_IMAGES;
  }

  get showGefaelltNichtsButton(): boolean {
    //return this.$vendor.gotoPLPAfterAmountOfLikes == 1; wid aktuell nicht mehr verwendet
    return false;
  }

  changeCategory(category: string) {
    this.showCategoryNotAvailableError = false;
    this.initTaste(category, (err: string) => {
      this.showCategoryNotAvailableError = true;
    });
    this.plpButtonEnabled = false;
  }

  likedImageIds(): string[] {
    return (
      this.ratings
        ?.filter(rating => rating.liked())
        ?.map(rating => rating.imgId) || []
    );
  }

  dislikedImageIds(): string[] {
    return (
      this.ratings
        ?.filter(rating => rating.disliked())
        ?.map(rating => rating.imgId) || []
    );
  }

  gotoPLP(originEventName: "define-cta" | "define-like"): void {
    if (this.$vendor.isYourhome() && !!this.tasteSession) {
      this.$router.push({
        name: "plp",
        params: {
          category: this.tasteSession.category
        },
        query: {
          likes: this.likedImageIds(),
          dislikes: this.dislikedImageIds(),
          sessionid: this.tasteSession.sessionid
        }
      });
      return;
    }
    Navigation.gotoPLP(
      this.$vendor,
      this.tasteSession,
      this.$user,
      this.$mtmService,
      this.$router,
      this.$googleTagManagerService,
      originEventName
    );
  }
}
