var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"plpTutorialCursor",staticStyle:{"position":"relative"}},[_c('transition',{attrs:{"name":"fadeslow"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.animationFinished),expression:"!animationFinished"}],staticStyle:{"position":"relative"}},[_c('cursor-icon',{class:[_vm.$style.cursorAnimation, _vm.$style.cursor],style:('top: ' +
            _vm.positionTop +
            'px; left: ' +
            _vm.positionLeft +
            'px; transition: top ' +
            _vm.animationDurationSeconds +
            's, left ' +
            _vm.animationDurationSeconds +
            's')})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }