import { Vendor } from "@/vendor/vendor";

export class UnitoAppApi {
  static shouldUseUnitoAppCommunication(vendor: Vendor) {
    return vendor.isUnitoApp();
  }

  static goToPLP(productlistUrlQueryString: string) {
    // console.log(
    //   `call bridge.onNavigateToInnofindProductList(${productlistUrlQueryString});`
    // );
    //@ts-ignore
    const bridge = window.bridge || {};
    bridge.onNavigateToInnofindProductList(productlistUrlQueryString);
  }

  static goToPDP(itemid: string) {
    itemid = itemid.split("-")[1]; // remove vendor prefix

    //console.log(`call bridge.onNavigateToProductDetail(${itemid});`);
    //@ts-ignore
    const bridge = window.bridge || {};
    bridge.onNavigateToProductDetail(itemid);
  }

  // static updateAppHeight(height: number) {
  //   console.log(`call bridge.onHeightChanged(${height});`);
  //   //@ts-ignore
  //   const bridge = window.bridge || {};
  //   bridge.onHeightChanged(height);
  // }
}
