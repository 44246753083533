import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class InnoFindVue extends Vue {
  beforeMount() {
    if (this.$style) {
      Object.assign(this.$style, this.$globalStyle);
    } else {
      this.$style = this.$globalStyle;
    }
  }
}
