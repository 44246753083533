










import { Component } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";

@Component
export default class PopupModal extends InnoFindVue {
  private isVisible = false;

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
  }
}
