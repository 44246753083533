




























































import { ScreenSize } from "@/models/screen-size";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import HunnThumbIcon from "./icons/HunnThumbIcon.vue";
import LikeMooris from "./icons/LikeMooris.vue";
import DislikeMooris from "./icons/DislikeMooris.vue";

@Component({
  components: {
    HunnThumbIcon,
    LikeMooris,
    DislikeMooris
  }
})
export default class IconButton extends InnoFindVue {
  @Prop()
  private icon?: string;

  @Prop()
  private active?: boolean;

  @Prop({ default: "medium" })
  private size?: "small" | "medium" | "large";

  private phoneBreakpoint = 768;

  private hover = false;

  private showThumbIcon() {
    return this.$vendor.isHunn();
  }

  private get sizeInPx(): number {
    switch (this.size) {
      case "small":
        return 20;
      case "medium":
        return 25;
      case "large":
        if (!ScreenSize.isPhone()) {
          return 35;
        }
    }
    return 25;
  }
}
