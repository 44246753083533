var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.initialPageLoad)?_vm._t("default"):_vm._e(),(!_vm.initialPageLoad)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.inViewport,
      once: true,
      throttle: 200,
      intersection: {
        threshold: 0
      }
    }),expression:"{\n      callback: inViewport,\n      once: true,\n      throttle: 200,\n      intersection: {\n        threshold: 0\n      }\n    }"}]},[_c('div',{class:( _obj = {}, _obj[_vm.$style.plpSlideIn] = true, _obj[_vm.$style.height0] = !this.visible, _obj )},[_vm._t("default")],2)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }