


















import { Component, Prop } from "vue-property-decorator";
import { Category } from "../models/category";
import InnoFindVue from "./InnoFindVue";
@Component({})
export default class CategoryMenu extends InnoFindVue {
  @Prop()
  private selectedCategory!: string;

  public availableCategories!: Category[];

  created() {
    if (!this.$widgetConfig?.categoryMenu) {
      throw new Error(
        "can not show category menu without selectable category for given vendor"
      );
    }

    this.availableCategories = this.$widgetConfig?.categoryMenu;

    if (
      this.availableCategories.length > 0 &&
      !this.categoryIsSelectable(this.selectedCategory)
    ) {
      this.changeCategory(this.availableCategories[0].value);
    }
  }

  private categoryIsSelectable(categoryToCheck: string): boolean {
    return this.availableCategories.some((category: Category) =>
      category.hasValue(categoryToCheck)
    );
  }

  changeCategory(category: string) {
    this.$emit("categoryChanged", category);
  }
}
