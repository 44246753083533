








































import { Component } from "vue-property-decorator";
import InnoFindVue from "../../InnoFindVue";

@Component
export default class IconSurveyDislikeNoBorder extends InnoFindVue {}
