

























import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import IconButton from "./IconButton.vue";
import { TasteSession } from "@/models/taste-session";
import { Rating } from "@/models/rating";

@Component({
  components: {
    IconButton
  }
})
export default class YourLikesButton extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  @Prop()
  private showText!: boolean;

  public nrOfLikes = 0;
  private heartAnimation = false;

  private disabled = false;

  mounted() {
    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      const newNrOfLikes = ratings.filter((it: Rating) => it.liked()).length;

      if (this.nrOfLikes != 0 && newNrOfLikes > this.nrOfLikes) {
        this.heartAnimation = true;
        setTimeout(() => (this.heartAnimation = false), 1000);
      }

      this.nrOfLikes = newNrOfLikes;
      this.disabled = newNrOfLikes === 0;
    });
  }
  buttonClicked() {
    if (!this.disabled) {
      this.$emit("clicked");
    }
  }
}
