



























































































import { Device } from "@/models/device";
import { Rating } from "@/models/rating";
import { ScrollState } from "@/models/scroll-state";
import { TasteSession } from "@/models/taste-session";
import { UrlParam } from "@/models/url-param";
import { PredictionCharacter } from "@/services/predict-service";
import { ReplaySubject } from "rxjs";
import { first } from "rxjs/operators";
import { Component } from "vue-property-decorator";
import InnoFindVue from "../InnoFindVue";
import ProductKachel from "../ProductKachel.vue";

@Component({
  components: {
    ProductKachel
  }
})
export default class TasteSuggestions extends InnoFindVue {
  private likedItemIds: string[] = [];
  private dislikedItemIds: string[] = [];
  private tasteSession!: TasteSession;

  private suggestedItemIds: string[] = [];

  private dataLoaded$ = new ReplaySubject<boolean>(1);

  created() {
    this.initTaste();

    this.tasteSession.ratingsStream().subscribe((ratings: Rating[]) => {
      this.likedItemIds = ratings
        .filter((rating: Rating) => rating.liked())
        .map((rating: Rating) => rating.imgId);

      this.$predictService
        .loadTastePredictions(
          this.tasteSession.sessionid,
          this.tasteSession.$user.userId,
          ratings,
          this.tasteSession.category
        )
        .subscribe((itemIds: string[]) => {
          const suggestionsWithoutLikes = itemIds
            .filter((itemId: string) => !this.likedItemIds.includes(itemId))
            .filter((itemId: string) => !this.dislikedItemIds.includes(itemId));

          this.suggestedItemIds = suggestionsWithoutLikes;

          this.dataLoaded$.next(true);
        });
    });

    this.sendMtmPageLoad();
  }
  mounted() {
    this.dataLoaded$.pipe(first()).subscribe(loaded => {
      setTimeout(() => this.scrollToPreviousPosition(), 50);
    });
  }

  scrollToPreviousPosition() {
    const scrollStateString = this.$route.query.tasteSuggestionsScrollState;

    if (!scrollStateString) {
      return;
    }

    ScrollState.scrollTo(scrollStateString);
  }

  private sendMtmPageLoad() {
    this.$mtmService.initUrl({
      userId: this.$user.userId,
      url: "http://innofind.ch/tastesuggestions", //Fake-URL, damit sichtbar ist dass 'PLP-Page' geöffnet ist
      vendor: this.$vendor.vendorname,
      category: this.tasteSession.category,
      entrypoint: this.$user.entrypoint || "",
      widgetVersionName: process.env.VUE_APP_WIDGET_VERSION_NAME,
      monetaryValue: "0",
      deviceType: Device.deviceType
    });
  }

  private initTaste() {
    const likedItemIdsAsString = (this.$route.query.likes as string) || "";
    this.likedItemIds = likedItemIdsAsString.split(".").filter(it => !!it);

    this.dislikedItemIds = ((this.$route.query.dislikes as string) || "")
      .split(".")
      .filter(it => !!it);

    const sessionid = this.$route.query.sessionid as string;
    const category = this.$route.query.category as string;

    this.tasteSession = this.initTasteSession(
      category,
      this.likedItemIds,
      this.dislikedItemIds,
      sessionid
    );
  }

  private initTasteSession(
    category: string,
    likes: string[],
    dislikes: string[],
    sessionid?: string
  ): TasteSession {
    const initialRatings = [
      ...Rating.toRatings(dislikes, Rating.NEG_RATING),
      ...Rating.toRatings(likes, Rating.POS_RATING)
    ];

    return TasteSession.new(
      category,
      this.$vendor.plpReplaceStrategy,
      this.$vendor.plpAmountOfProductsInitial,
      initialRatings,
      false,
      PredictionCharacter.DEFAULT,
      sessionid
    );
  }

  goback() {
    UrlParam.removeSearchParam(UrlParam.URL_PARAM_NAME_INTERNAL_ROUTING_NAME);

    //@ts-expect-error this.$toure.history is not set
    if (this.$router?.history?.stack?.length >= 2) {
      this.$router.go(-1);
    }

    // in case the history stack has only one element, just the current route is in the stack
    // that means the user came from external.  In this case redirect him directly to plp

    // TODO use generic method together with APp.vue
    this.$router.push({
      name: "plp",
      params: {
        category: this.tasteSession.category
      },
      query: {
        likes: this.tasteSession.likedImageIds,
        dislikes: this.tasteSession.dislikedImageIds,
        visibleProductsAmount: UrlParam.getUrlParam(
          UrlParam.URL_PARAM_NAME_VISIBLE_PRODUCTS_AMOUNT
        ),
        plpScrollState: UrlParam.getUrlParam(
          UrlParam.URL_PARAM_NAME_PLP_SCROLL_STATE
        ),
        forcepms: UrlParam.getUrlParam(
          UrlParam.URL_PARAM_NAME_FORCE_PREDICT_MODEL_STRATEGY
        ),
        sessionid: UrlParam.getUrlParam(UrlParam.URL_PARAM_NAME_SESSION_ID)
      }
    });
    return;
  }
}
