export enum ErrorCode {
  CATEGORY_UNKNOWN = 10,
  CATEGORY_TO_FEW_PRODUCTS = 11,
  VENDOR_REQUIRED = 12,

  UNKNOWN_SERVER_ERROR = 98
}

export class ApiError {
  constructor(
    readonly errorCode: ErrorCode,
    readonly title: string,
    private readonly message: string,
    private readonly httpStatus?: number,
    private readonly nestedError?: any
  ) {}

  public static of(responseError: any) {
    if (!responseError?.isInnoFindError) {
      return undefined;
    }

    return new ApiError(
      responseError.errorCode,
      responseError.title,
      responseError.httpStatus,
      responseError.message,
      responseError.nestedError
    );
  }

  static unknownServerError(message: string) {
    return new ApiError(
      ErrorCode.UNKNOWN_SERVER_ERROR,
      "",
      message,
      undefined,
      undefined
    );
  }
}
