import { GoogleTagManagerService } from "@/google-tag-manager/google-tag-manager-service";
import { MtmService } from "@/services/mtm-service";
import { User } from "@/user/user";
import { Vendor } from "@/vendor/vendor";

import AbTestGAWholeWidget from "./abtest-ga-whole-widget";

export class AbTestConfig {
  public abTestWholeWidget!: AbTestGAWholeWidget;

  init(
    user: User,
    vendor: Vendor,
    mtmService: MtmService,
    googleTagManagerService: GoogleTagManagerService,
    entrypoint?: string
  ) {
    this.abTestWholeWidget = AbTestGAWholeWidget.of(
      vendor,
      entrypoint,
      user,
      mtmService,
      googleTagManagerService
    );
  }

  widgetIsVisibleSendToServer(user: User) {
    // send ab test to server if neccessary
  }
}
