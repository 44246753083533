var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[_vm.$style.wrapper, _vm.$style.paddingLeftRight]},[_c('div',{class:( _obj = {}, _obj[_vm.$style.productList] = true, _obj[_vm.$style.paddingLeftRight] = true, _obj[_vm.productListCssClass] = !!_vm.productListCssClass, _obj[_vm.$style.threeProductsPerRow] = _vm.productsPerRowOnDesktop == 3, _obj[_vm.$style.fourProductsPerRow] = _vm.productsPerRowOnDesktop == 4, _obj[_vm.$style.fourProductsPerRowOnTablet] = _vm.productsPerRowOnTablet == 4, _obj[_vm.$style.oneProductPerRowMobile] = _vm.productsPerRowOnMobile == 1, _obj )},[_vm._l((_vm.imageIds),function(imageId,index){
      var _obj, _obj$1;
return [(_vm.plpSlideInElements.hasElementOnIndex(index))?_c('plp-slide-in-root',{key:'plpslidein' + index,class:( _obj = {}, _obj[_vm.$style.fullwidth] = true, _obj[_vm.$style.ieFallbackGrid] = true, _obj ),attrs:{"plpIndex":index,"plpSlideInElements":_vm.plpSlideInElements,"tasteSession":_vm.tasteSession}}):_vm._e(),_c('div',{key:index,class:( _obj$1 = {}, _obj$1[_vm.$style.height100] = true, _obj$1[_vm.$style.ieFallbackGrid] = true, _obj$1[_vm.$style.rateableProduct] = true, _obj$1[_vm.$style.fadeNotFirstChild] = _vm.tutorialRunning, _obj$1 ),staticStyle:{"display":"inline-block"}},[_c('rateable-product',{ref:"rateableProducts",refInFor:true,attrs:{"tasteSession":_vm.tasteSession,"useFreistellerImages":_vm.useFreistellerImages,"size":_vm.productsPerRowOnMobile == 1 ? 'largeMobile' : '',"sizeImage":_vm.productsPerRowOnMobile == 1
                ? 'largeMobile'
                : _vm.$vendor.isMooris()
                ? 'small'
                : '',"showDetailLink":_vm.showDetailLink,"handleClickAsLike":_vm.handleClickAsLike,"image":imageId,"rateable":_vm.rateable,"border":_vm.border,"showProductInfos":_vm.showProductInfos,"detailClickOrigin":_vm.detailClickOrigin,"detailClickEventProductPositionIndex":index,"noProductImageSlider":_vm.noProductImageSlider},on:{"rated":function($event){return _vm.imageRated($event)},"inViewPort":function ($event) { return _vm.visibilityChanged($event.isVisible, imageId, index); }}})],1)]})],2),(_vm.allowToLoadMoreProducts)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoadMoreButton),expression:"showLoadMoreButton"}],class:_vm.$style.actionBar},[_c('div',{class:_vm.$style.left}),_c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('button',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.loadMoreButtonVisibilityChanged),expression:"loadMoreButtonVisibilityChanged"}],class:( _obj$1 = {}, _obj$1[_vm.$style.primary] = true, _obj$1[_vm.$style.hidden] = this.$vendor.plpInfiniteScroll, _obj$1.ifLoadMore = true, _obj$1 ),on:{"click":function($event){return _vm.loadMore()}}},[_vm._v(" "+_vm._s(_vm.$t("navigation.showMore"))+" ")])])]),_c('div',{class:_vm.$style.right},[(!this.$vendor.isYourhome())?_c('span',{class:_vm.$style.poweredByText},[_vm._v("powered by InnoFind ©")]):_vm._e()])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }