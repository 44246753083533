























import { Rating } from "@/models/rating";
import { TasteSession } from "@/models/taste-session";
//@ts-ignore
import Vara from "vara";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./../InnoFindVue";
import PlpTutorialScribbleCursor from "./PlpTutorialScribbleCursor.vue";

@Component({
  components: {
    PlpTutorialScribbleCursor
  }
})
export default class PlpTutorialScribble extends InnoFindVue {
  private readonly TUTORIAL_DURATION = 1250 + 1400 + 2500;

  @Prop({ required: true })
  private tasteSession!: TasteSession;

  scribbleActive = true;

  animationStarted = false;
  mounted() {
    if (!this.scribbleActive) {
      return;
    }

    const subscription = this.tasteSession
      .ratingsStream()
      .subscribe((ratings: Rating[]) => {
        if (ratings.length > 0) {
          this.scribbleActive = false;
          subscription.unsubscribe();
        }
      });
  }

  scribbleVisible(visible: boolean) {
    if (!this.animationStarted && visible && this.scribbleActive) {
      this.$emit("tutorialRunning", true);
      setTimeout(() => {
        this.$user.scribbleTutorialFinished();
        this.$emit("tutorialRunning", false);
      }, this.TUTORIAL_DURATION);

      this.animationStarted = true;
      this.doScribble(
        this.$t("scribble.likeOrDislike").toString(),
        this.$t("scribble.sofortEmpfelungen").toString(),
        "shadows-into-light.json"
      ); //SatisfySL.json, PacificoSLO.json, Parisienne.json, shadows-into-light.json
    }
  }

  doScribble(text1: string, text3: string, font: string) {
    //"https://rawcdn.githack.com/akzhy/Vara/ed6ab92fdf196596266ae76867c415fa659eb348/fonts/Satisfy/SatisfySL.json",
    new Vara(
      "#scribble",
      "https://data.innofind.ch/scribble/" + font,
      [
        {
          text: text1,
          fontSize: 20,
          delay: 200,
          duration: 1250,
          y: 1
        },

        {
          text: text3,
          fontSize: 12,
          delay: 1000,
          duration: 1400,
          y: 10
        }
      ],
      {
        fontSize: 20,
        strokeWidth: 1.2,
        textAlign: "center"
      }
    );
  }
}
