import InnoFindVue from "@/components/InnoFindVue";
import { Device } from "@/models/device";
import { from, Observable, of } from "rxjs";
import { catchError, first, timeout } from "rxjs/operators";
import http from "../http-commons";

export interface MtmEventDto {
  userId: string;
  url: string;
  vendor: string;
  category: string;
  entrypoint: string;
  widgetVersionName: string;
  monetaryValue: string;
  deviceType: string;
  eventCategory?: string;
  eventAction?: string;
  eventName?: string;
  pdpItemId?: string;
}

export interface MtmRatingDto {
  userId: string;
  url: string;
  vendor: string;
  category: string;
  entrypoint: string;
  widgetVersionName: string;
  deviceType: string;
  rating: string;
  itemId: string;
  activeUserReached: boolean;
}

export class MtmService extends InnoFindVue {
  public static readonly ACTIVE_USERS_MIN_NR_RATINGS = 3;

  public sendEvent(
    eventCategory:
      | "Click"
      | "Interaction"
      | "SURVEY"
      | "SURVEY_ONLY_DISLIKE"
      | "SURVEY_WITH_LIKES"
      | "SURVEY_INACTIVE",
    eventAction:
      | "GOTO_PLP"
      | "GOTO_SIMILARITIES_PAGE"
      // | "20_PRODUCTS_SEEN"
      | "LOAD_MORE"
      | "IMAGE_SLIDER_USED"
      | "SHOW_LIKES_FROM_BANNER"
      | "SHOW_LIKES_FROM_FAVORITEBAR"
      | "SHOW_LIKES_FROM_YOURLIKESBUTTON"
      | "SURVEY_IMPRESSION"
      | "SURVEY_VOTE"
      | "PLP_GO_BACK"
      | "SURVEY_FEEDBACK_SUGGESTIONS"
      | "SURVEY_FEEDBACK_LIKED"
      | "SURVEY_RATE_BUTTONS_SEEN"
      | "SURVEY_FEEDBACK_WHY_NOT_RATED"
      | "RESET"
      | "REMOVE_RATING"
      | "SHOW_TASTE_SUGGESTIONS",
    category: string,
    urlPath:
      | "plp"
      | "similarity"
      | "pdp"
      | "define"
      | "likes"
      | "tastesuggestions",
    eventName?: string
  ): Observable<any> {
    const mtmEventDto: MtmEventDto = {
      entrypoint: this.$user.entrypoint || "",
      userId: this.$user.userId,
      widgetVersionName: process.env.VUE_APP_WIDGET_VERSION_NAME,
      category: category,
      monetaryValue: "0",
      url: "http://innofind.ch/" + urlPath,
      vendor: this.$vendor.vendorname,
      deviceType: Device.deviceType,
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventName: eventName
    };

    return from(http.post("/mtm/saveevent", mtmEventDto)).pipe(
      first(),
      timeout(500),
      catchError(error => {
        const errorMessage = `Request timed out after: 500ms - ${error}`;
        console.error(errorMessage);
        return of(errorMessage);
      })
    );
  }

  public addRating(mtmRatingDto: MtmRatingDto): Observable<unknown> {
    return from(http.post("/mtm/addrating", mtmRatingDto)).pipe(
      first(),
      timeout(500),
      catchError(error => {
        const errorMessage = `Request timed out after: 500ms - ${error}`;
        console.error(errorMessage);
        return of(errorMessage);
      })
    );
  }
  public initUrl(mtmEventDto: MtmEventDto): Observable<unknown> {
    return from(http.post("/mtm/initurl", mtmEventDto)).pipe(
      first(),
      timeout(500),
      catchError(error => {
        const errorMessage = `Request timed out after: 500ms - ${error}`;
        console.error(errorMessage);
        return of(errorMessage);
      })
    );
  }

  public abTestingVariant(
    userId: string,
    experimentName: string,
    variationName: string
  ): Observable<unknown> {
    return from(
      http.post("/mtm/abtesting", {
        userId: userId,
        experimentName: experimentName,
        variationName: variationName
      })
    ).pipe(
      first(),
      timeout(500),
      catchError(error => {
        const errorMessage = `Request timed out after: 500ms - ${error}`;
        console.error(errorMessage);
        return of(errorMessage);
      })
    );
  }
}
