import InnoFindVue from "@/components/InnoFindVue";

export interface ConfirmDialogueOpts {
  title?: string;
  message?: string; // Main text content
  okButton?: string; // Text for confirm button; leave it empty because we don't know what we're using it for
  cancelButton: string; // text for cancel button
}

export class ConfirmDialogService extends InnoFindVue {
  constructor(private readonly confirmDialogRef: any) {
    super();
  }

  public show(opts: ConfirmDialogueOpts): Promise<boolean> {
    return this.confirmDialogRef.show(opts);
  }
}
