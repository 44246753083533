export default class Stopwatch {
  private millisecondsAlreadyStopped = 0;

  private startDate: number | undefined = undefined;

  public start() {
    if (this.startDate) {
      console.warn("stopwatch already in progress");
      return;
    }
    this.startDate = Date.now();
  }

  public stop() {
    if (!this.startDate) {
      console.warn("cannot stop Stopwatch if it was never started");
      return;
    }

    const round = Date.now() - this.startDate;
    this.millisecondsAlreadyStopped += round;

    this.startDate = undefined;
  }

  public get milliseconds(): number {
    return (
      this.millisecondsAlreadyStopped +
      (this.startDate ? Date.now() - this.startDate : 0)
    );
  }

  public seconds(): number {
    return this.milliseconds / 1000;
  }
}
