import { UrlParam } from "./url-param";

export class ScrollState {
  private static readonly SEPARATOR = "---";

  static scrollTo(scrollStateString: string | (string | null)[]) {
    const { id, offsetTop } = ScrollState.extractScrollState(
      scrollStateString as string
    );

    const element = document.querySelector(`[data-producttile="${id}"]`);
    if (!element) {
      console.debug("element to scroll to not found!");
      return;
    }

    const scrollTop =
      element.getBoundingClientRect().top + window.scrollY - offsetTop;
    window.scroll({
      top: scrollTop
    });
  }

  static updateUrlParam(
    itemid: string,
    offsetViewportTop: number,
    origin?: "plp" | "tastesuggestions"
  ) {
    const urlParamName =
      origin === "tastesuggestions"
        ? UrlParam.URL_PARAM_NAME_TASTESUGGESTIONS_SCROLL_STATE
        : UrlParam.URL_PARAM_NAME_PLP_SCROLL_STATE;

    UrlParam.updateSearchParam(
      urlParamName,
      this.scrollStateString(itemid, offsetViewportTop)
    );
  }

  static scrollStateString(itemid: string, offsetViewportTop: number): string {
    return itemid + ScrollState.SEPARATOR + offsetViewportTop;
  }

  static extractScrollState(
    scrollStateString: string
  ): { id: string; offsetTop: number } {
    const [id, offsetAsString] = scrollStateString.split(this.SEPARATOR);

    return { id: id, offsetTop: Number.parseFloat(offsetAsString) };
  }

  static ofAnyVisibleProducttile(): string {
    const productTileInViewport = ([
      ...document.querySelectorAll("[data-producttile]")
    ].find(
      it =>
        it.getBoundingClientRect().top > 0 &&
        it.getBoundingClientRect().bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
    ) || document.querySelector("[data-producttile]")) as HTMLElement; // if no producttile found in viewport use any other producttile;

    const itemid = productTileInViewport.dataset?.producttile;
    const offsetViewportTop = productTileInViewport.getBoundingClientRect().top;

    if (!itemid) {
      console.error("can not determin plpScrollState");
      return "";
    }

    return this.scrollStateString(itemid, offsetViewportTop);
  }
}
