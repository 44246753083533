import { IteminfosService } from "@/services/iteminfos-service";
import { Vendor } from "@/vendor/vendor";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Category } from "../category";

export class WidgetConfig {
  private constructor(
    public readonly defaultCategory: string,
    public readonly categoryMenu?: Category[],
    public readonly initialProductOrder?: string[]
  ) {}

  static of(
    categoryMenu: Category[] | undefined,
    defaultCategory: string,
    initialProductOrderString: string | undefined,
    vendor: Vendor,
    iteminfosService: IteminfosService
  ): Observable<WidgetConfig> {
    const initialProductOrder = initialProductOrderString
      ?.split(",")
      .map(itemid => itemid.trim())
      .map(itemid => vendor.prefix + itemid);

    if (!initialProductOrder) {
      return of(new WidgetConfig(defaultCategory, categoryMenu));
    }

    return iteminfosService
      .itemsSubstitutes(initialProductOrder)
      .pipe(
        map(
          (itemids: string[]) =>
            new WidgetConfig(defaultCategory, categoryMenu, itemids)
        )
      );
  }

  hasCategoryMenu(): boolean {
    return !!this.categoryMenu;
  }
}
