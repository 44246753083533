var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"productKachel",class:_vm.$style.maxSizeNoOverflow,attrs:{"data-producttile":_vm.itemId}},[_c('a',{class:_vm.$style.debugSimilarityImagesLink,attrs:{"id":"debug-similar-images-link"},on:{"click":_vm.goToDebugSimilaritiesPage}}),_c(_vm.showDetailLink ? 'a' : 'span',{tag:"a",class:[
      _vm.$style.boxProductLink,
      _vm.handleClickAsLike ? [_vm.$style.pointer] : ''
    ],staticStyle:{"width":"100%"},attrs:{"target":_vm.$vendor.pdpLinkTarget,"href":_vm.detailPageUrl,"disabled":!_vm.showDetailLink},on:{"click":function($event){$event.preventDefault();return _vm.detailPageLinkClicked($event)}}},[(_vm.showDetailLink && _vm.$vendor.showDetailLinkText)?_c('span',{class:_vm.$style.tooltiptext},[_vm._v(_vm._s(_vm.$t("navigation.productdetailHint")))]):_vm._e(),_c('div',{class:_vm.$style.productImageContainer},[_c('product-image',{class:[
          _vm.$style.imageCard,
          _vm.$vendor.isPkz() ? [_vm.$style.pkz] : '',
          _vm.sizeImage == 'small' ? [_vm.$style.small] : '',
          _vm.sizeImage == 'largeMobile' ? [_vm.$style.largeMobile] : ''
        ],attrs:{"styling":'productList',"imgId":_vm.itemId,"useFreistellerImages":_vm.useFreistellerImages}})],1),(_vm.showProductInfos)?_c('product-infos',{attrs:{"itemId":_vm.itemId,"size":_vm.size,"iteminfo":_vm.iteminfoDto}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }