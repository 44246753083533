



















import { TasteSession } from "@/models/taste-session";
import { Component, Prop } from "vue-property-decorator";
import InnoFindVue from "./InnoFindVue";
import RateableProduct, { RatingEvent } from "./RateableProduct.vue";
import RateButtons from "./RateButtons.vue";

@Component({
  components: {
    RateableProduct,
    RateButtons
  }
})
export default class ProductSliderEntry extends InnoFindVue {
  @Prop()
  private tasteSession!: TasteSession;

  @Prop()
  private itemId!: string;

  @Prop({ default: false })
  private rateable!: boolean;

  @Prop({ default: true })
  private showProductInfos!: boolean;

  @Prop({ default: "" })
  private size!: string;

  @Prop()
  private useFreistellerImages!: boolean;

  @Prop({ default: false })
  private handleClickAsLike!: boolean;
  @Prop({ default: true })
  private showDetailLink!: boolean;

  @Prop({ required: false })
  private detailClickOrigin?: string;

  imageRated(event: RatingEvent) {
    this.tasteSession.addRating(
      event.imageId,
      event.rating,
      this.$route.meta?.mtmPath
    );
  }
}
