























export interface SurveyFeedback {
  answer1: string;
  answer2: string;
}

import { Component, Prop } from "vue-property-decorator";
import IconButton from "../IconButton.vue";
import InnoFindVue from "../InnoFindVue";

@Component({
  components: { IconButton }
})
export default class PlpSurveyPageTextQuestions extends InnoFindVue {
  @Prop({ required: true })
  private question1!: string;

  @Prop()
  private question2?: string;

  private answer1 = "";
  private answer2 = "";

  submitted() {
    this.$emit("submitted", {
      answer1: this.answer1,
      answer2: this.answer2
    } as SurveyFeedback);
  }
}
