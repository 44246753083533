import InnoFindVue from "@/components/InnoFindVue";
import { Navigation } from "@/models/navigation";
import { TasteSession } from "@/models/taste-session";
import { User } from "@/user/user";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import http from "../http-commons";

export interface IteminfoDto {
  itemid: string;
  url: string;
  detailUrl: string;
  productId: string;
  title: string;
  substitute: string;
  price: string;
  priceOld: string;
  priceSalesPercentage: number;
}

export class IteminfosService extends InnoFindVue {
  public iteminfoFor(itemid: string): Observable<IteminfoDto> {
    return from(http.get("/item/" + itemid + `?lang=${this.$user.lang}`)).pipe(
      map(result => result.data)
    );
  }

  /**
   * some items have been replaced in the InnoFind system because there were other identical items.
   * Call this method to get the corresponding substitute for a given itemid
   */
  public itemSubstitute(itemid: string): Observable<string> {
    return from(http.get("/item/" + itemid + "/substitute")).pipe(
      map(result => result.data)
    );
  }

  public itemSubstitutePdp(
    itemid: string,
    productId?: string
  ): Observable<string> {
    return from(
      http.get(`/item/${itemid}/pdp/substitute?productId=${productId || ""}`)
    ).pipe(map(result => result.data));
  }

  public itemsSubstitutes(itemIds: string[]): Observable<string[]> {
    const requestParams = {
      params: {
        itemids: itemIds
      }
    };

    return from(http.get("/items/substitutes", requestParams)).pipe(
      map(result => result.data)
    );
  }

  rootCategoryFor(itemid: string): Observable<string | undefined> {
    return from(http.get(`/item/${itemid}/rootcategory`)).pipe(
      map(result => result.data)
    );
  }

  loadDetailurlAndTrackDetailclick(
    itemid: string,
    tasteSession: TasteSession,
    $user: User,
    detailClickOrigin?: string,
    detailClickEventProductPositionIndex?: string
  ): Observable<string> {
    const params: URLSearchParams = Navigation.detailUrlQueryParams(
      tasteSession,
      $user,
      detailClickOrigin,
      detailClickEventProductPositionIndex
    );

    return from(
      http.get(`/item/${itemid}/detailurl?${params.toString()}`)
    ).pipe(map(result => result.data));
  }

  public yourhomeItemOfUrl(): Observable<string> {
    console.log("load yourhome itemid for url", window.parent.location.href);
    return from(
      http.post("/yourhome/url/item", {
        url: window.parent.location.href
      })
    ).pipe(map(result => result.data));
  }
}
