import { initLocationChangeListener as initLocationChangeEvents } from "@/helper/listener";
import WebComponentRoot from "@/WebComponentRoot.vue";
import VueRouter from "vue-router";

export class Ottoversand {
  public static instance: Ottoversand = new Ottoversand();

  private eventListenerToCleanUp: any;
  private currentUrlPathname?: string = undefined;

  /** add event listeners when category changes. Bacause ottoversand does not reload the page on category change and their used dynamic yield version does not allow to rerender the dynamic yield content. So we have to trigger the category change in the widget by our selves */
  initListeners(
    webComponentRoot: WebComponentRoot,
    router: VueRouter,
    entrypoint?: string
  ) {
    if (
      !entrypoint ||
      (!entrypoint.includes("plp") && !entrypoint.includes("pdp"))
    ) {
      return;
    }
    // todo remove as soon as they went to newer dynamic yield version
    initLocationChangeEvents();

    const urlChange = () => {
      if (
        this.currentUrlPathname === window.location.pathname ||
        window.location.pathname.includes("stilfinder") ||
        window.location.hostname === "localhost"
      ) {
        console.log("url did not change since last listener callback");
        return;
      }
      this.currentUrlPathname = window.location.pathname;

      console.log("locationchange - reevaluate category ottoversand");

      webComponentRoot.init(); // webcomponent has to reevaluate if widget should be displayed or not

      router.push({
        name: "define",
        params: {
          category: this.extractCategoryFromUrl() //this.category reingeben oder prioritär verwenden falls gesetzt?
        }
      });
    };

    window.addEventListener("locationchange", urlChange);

    // Useful for cleanup when component is destroyed
    this.eventListenerToCleanUp = urlChange;
  }

  removeListeners() {
    window.removeEventListener("locationchange", this.eventListenerToCleanUp);
  }

  public extractCategoryFromUrl(): string {
    const lookupTableOttoversand = {
      //acrylglasbilder: ["/wohnen/dekoration/bilder/acrylglasbilder/"], // nicht ovrhanden bei uns
      //ballerinas: ["/damen/damenschuhe/ballerinas/"], zu wenig produkte 14
      // gummistiefel: ["/damen/damenschuhe/gummistiefel/"], nicht vorhanden
      // high-heels nicht vorhanden
      // outdoorschuhe nicht vorhanden
      // sicherheitsschuhe nicht vorhanden
      //led-bilder nicht vorhanden
      //bilder-mit-uhr nicht vorhanden
      // metallschilder nicht vorhanden
      // lederteppiche zu wenig produkte
      bilder: ["/wohnen/dekoration/bilder/"],
      // gemälde: ["/wohnen/dekoration/bilder/gemaelde/"], zu wenig produkte 10
      //"bilder mit rahmen": ["/wohnen/dekoration/bilder/bilder-mit-rahmen/"], nicht ovrhanden bei uns
      damenboots: ["/damen/damenschuhe/boots/"],
      // clogs: ["/damen/damenschuhe/clogs/"], zu wenig produkte 28
      /*       damen: [], // ??? */
      damenschuhe: ["/damen/damenschuhe/"],
      //dekoration: ["/wohnen/dekoration/"], // nicht vorhanden
      // esszimmerteppiche: [
      //   "/wohnen/heimtextilien/teppiche/teppiche-nach-raeumen/esszimmerteppiche/"
      // ], zu wenig produkte 20
      // fellteppiche: ["/wohnen/heimtextilien/teppiche/fellteppiche/"], zu wenig produkte 12
      glasbilder: ["/wohnen/dekoration/bilder/glasbilder/"],
      damenhalbschuhe: ["/damen/damenschuhe/halbschuhe/"],
      // hausschuhe: ["/damen/damenschuhe/hausschuhe/"], zu wenig produkte 20
      "hochflor-teppiche": [
        "/wohnen/heimtextilien/teppiche/hochflor-teppiche/"
      ],
      "läufer & bettumrandungen": [
        "/wohnen/heimtextilien/teppiche/laeufer-bettumrandungen/"
      ],
      //heimtextilien: [], // da kommen aktuell nur teppiche
      holzbilder: ["/wohnen/dekoration/bilder/holzbilder/"],
      //kunstdrucke: ["/wohnen/dekoration/bilder/kunstdrucke/"], zu wenig produkte 40
      kinderzimmerteppiche: [
        "/wohnen/heimtextilien/teppiche/teppiche-nach-raeumen/kinderzimmerteppiche/"
      ],
      //küche: [], // da kommen aktuell nur teppiche
      // küchentextilien: [], // da kommen aktuell nur teppiche */
      küchenteppiche: [
        "/wohnen/heimtextilien/teppiche/teppiche-nach-raeumen/kuechenteppiche/"
      ],
      leinwandbilder: ["/wohnen/dekoration/bilder/leinwandbilder/"],
      //metallbilder: ["/wohnen/dekoration/bilder/metallbilder/"], zu wenig produkte 18
      naturteppiche: ["/wohnen/heimtextilien/teppiche/naturteppiche/"],
      // "outdoor-teppich": ["/wohnen/heimtextilien/teppiche/outdoor-teppiche/"], // kategorie nicht vorhanden
      orientteppiche: ["/wohnen/heimtextilien/teppiche/orientteppiche/"],
      damenpantoletten: ["/damen/damenschuhe/pantoletten/"],
      poster: ["/wohnen/dekoration/bilder/poster/"],
      // pumps: ["/damen/damenschuhe/pumps/"], zu wenig produkte 23
      damenriemchensandalen: ["/damen/damenschuhe/sandalen/riemchensandalen/"],
      räume: [],
      damensandalen: [
        "/damen/damenschuhe/sandalen/",
        "/damen/damenschuhe/sandalen/sandaletten/"
      ],
      //sandaletten: ["/damen/damenschuhe/sandalen/sandaletten/"], zu wenig Produkte 2023-01-26, neu sandalen für sandaletten
      "damenschmale schuhweite": [
        "/damen/damenschuhe/sondergroessen/schmale-schuhweite/"
      ],
      seidenteppiche: ["/wohnen/heimtextilien/teppiche/seidenteppiche/"],

      damenschnürboots: ["/damen/damenschuhe/boots/schnuerboots/"],
      // schnürschuhe: ["/damen/damenschuhe/halbschuhe/schnuerschuhe/"], zu wenig produkte 18
      // schuhzubehör: ["/damen/damenschuhe/schuhzubehoer/"], // kategorie gibt es nicht
      // "sehr weite schuhweite": [
      //   "/damen/damenschuhe/sondergroessen/sehr-weite-schuhweite/"
      // ], zu wenig produkte 11
      // slipper: ["/damen/damenschuhe/halbschuhe/slipper/"], zu wenig produkte 33
      damensneaker: ["/damen/damenschuhe/sneaker/"],
      // sondergrößen: ["/damen/damenschuhe/sondergroessen/"], zu wenig produkte 45
      damenstiefel: ["/damen/damenschuhe/stiefel/"],
      damenstiefeletten: ["/damen/damenschuhe/stiefeletten/"],
      schlafzimmerteppiche: [
        "/wohnen/heimtextilien/teppiche/teppiche-nach-raeumen/schlafzimmerteppiche/"
      ],
      teppiche: ["/wohnen/heimtextilien/teppiche/"],
      damenzehentrenner: ["/damen/damenschuhe/zehentrenner/"],
      damenjeans: ["/damen/damenmode/jeans/"],
      damenmäntel: ["damen/damenmode/maentel/"],
      damenröcke: ["/damen/damenmode/roecke/"],
      damentshirts: ["/damen/damenmode/shirts-tops/t-shirts/"],
      damentops: ["/damen/damenmode/shirts-tops/tops/"],
      "damenshirts & tops": ["/damen/damenmode/shirts-tops/"],

      "damenstrickjacken & strickmäntel": [
        "/damen/damenmode/strickjacken-maentel/"
      ],
      "damensweatshirts & sweatjacken": [
        "/damen/damenmode/sweatshirts-jacken/"
      ],
      bettwäsche: [
        "/wohnen/heimtextilien/bettwaesche-leintuecher/bettwaesche/"
      ],
      "bettwäsche & leintücher": [
        "/wohnen/heimtextilien/bettwaesche-leintuecher/"
      ],
      "gardinen & vorhänge": ["/wohnen/heimtextilien/gardinen-vorhaenge/"],

      damenarmbänder: ["/damen/accessoires/schmuck/armbaender/"],
      damenfußkettchen: ["/damen/accessoires/schmuck/fusskettchen/"],
      damenhalsketten: ["/damen/accessoires/schmuck/halsketten/"],
      damenohrringe: ["/damen/accessoires/schmuck/ohrringe/"],
      damenringe: ["/damen/accessoires/schmuck/ringe/"],

      damenschmuck: ["/damen/accessoires/schmuck/"],
      damenhandtaschen: ["/damen/accessoires/taschen-rucksaecke/handtaschen/"],
      "damentaschen & rucksäcke": ["/damen/accessoires/taschen-rucksaecke/"],

      damenquarzuhren: ["/damen/accessoires/uhren/quarzuhren/"],
      damenuhren: ["/damen/accessoires/uhren/"],
      damenblazer: ["/damen/damenmode/blazer/"],
      damenblusen: ["/damen/damenmode/blusen/"],
      "damenshorts & bermudas": ["/damen/damenmode/hosen/shorts-bermudas/"],
      damenleggings: ["/damen/damenmode/hosen/leggings/"],

      damenhosen: ["/damen/damenmode/hosen/"],
      kommoden: ["/wohnen/moebel/kommoden-sideboards/kommoden/"],
      // sideboards: ["/wohnen/moebel/kommoden-sideboards/sideboards/"], Nicht alle im Feed vorhanden
      "kommoden & sideboards": ["/wohnen/moebel/kommoden-sideboards/"],

      damenfunktionsjacken: ["/damen/damenmode/jacken/funktionsjacken/"],
      "damendaunenjacken & steppjacken": [
        "/damen/damenmode/jacken/daunenjacken-steppjacken/"
      ],
      damensweatshirts: ["/damen/damenmode/sweatshirts-jacken/sweatjacken/"],

      einzelsofas: [
        "/wohnen/moebel/sofas-couches/",
        "/wohnen/moebel/sofas-couches/einzelsofas/"
      ],
      "2-sitzer sofas": [
        "/wohnen/moebel/sofas-couches/einzelsofas/2-sitzer-sofas/"
      ],
      "3-sitzer sofas": [
        "/wohnen/moebel/sofas-couches/einzelsofas/3-sitzer-sofas/"
      ],
      schlafsofas: ["/wohnen/moebel/sofas-couches/schlafsofas/"],

      couchgarnituren: ["/wohnen/moebel/sofas-couches/couchgarnituren/"],
      wohnlandschaften: ["/wohnen/moebel/sofas-couches/wohnlandschaften/"],

      //aktuell zu wenig Produkte: badeanzüge, diverse bikini unterkategorien
      damenbadeanzüge: ["/bademode/damen-bademode/badeanzuege/"],
      damenmixkinis: ["/bademode/damen-bademode/mixkinis/"],
      "damenbikini hosen": ["/bademode/damen-bademode/bikinis/bikini-hosen/"],
      "damenbikini oberteile": [
        "/bademode/damen-bademode/bikinis/bikini-oberteile/"
      ],
      damenbikinis: ["/bademode/damen-bademode/bikinis/"],
      damenbademode: ["/bademode/damen-bademode/"],

      // bhs: ["/damen/damenwaesche/bhs/"],
      damenwäsche: ["/damen/damenwaesche/"],

      herrenbademode: ["/bademode/herren-bademode/"],
      herrenbadehosen: ["/bademode/herren-bademode/badehosen/"],
      //herrenbadeschuhe: ["/bademode/herren-bademode/badeschuhe/"], todo run big import
      herrenschuhe: ["/herren/herrenschuhe/"],
      herrenboots: ["/herren/herrenschuhe/boots/"],
      herrenclogs: ["/herren/herrenschuhe/clogs/"],
      herrenhalbschuhe: ["/herren/herrenschuhe/halbschuhe/"],
      herrenpantoletten: ["/herren/herrenschuhe/pantoletten/"],
      herrensandalen: ["/herren/herrenschuhe/sandalen/"],
      herrensneaker: ["/herren/herrenschuhe/sneaker/"],
      herrenzehentrenner: ["/herren/herrenschuhe/zehentrenner/"],
      herrenhemden: ["/herren/herrenmode/hemden/"],
      "herrenbusiness hemden": ["/herren/herrenmode/hemden/business-hemden/"],
      herrenshirts: ["/herren/herrenmode/shirts/"],
      "herrenlangarm shirts": ["/herren/herrenmode/shirts/langarm-shirts/"],
      herrenpoloshirts: ["/herren/herrenmode/shirts/poloshirts/"],
      herrentshirts: ["/herren/herrenmode/shirts/t-shirts/"]

      //herrengummistiefel: [''] // zu wenig produkte
      //hausschuhe // zu wenig produkte
      //herrenoutdoorschuhe // zu wenig produkte
      //flanellhemden // zu wenig produkte

      // "vintage-teppiche": ["/wohnen/heimtextilien/teppiche/vintage-teppiche/"], zu wenig produkte 28
      // wandbilder: ["/wohnen/dekoration/bilder/wandbilder/"],
      // "weite schuhweite": [
      //   "/damen/damenschuhe/sondergroessen/weite-schuhweite/"
      // ], zu wenig produkte 19
      //  wandteppiche: ["/wohnen/heimtextilien/teppiche/wandteppiche/"], zu wenig produkte 20
      // wohnzimmerteppiche: [
      //   "/wohnen/heimtextilien/teppiche/teppiche-nach-raeumen/wohnzimmerteppiche/"
      // ], nicght vorhanden
      // ölbilder: ["/wohnen/dekoration/bilder/oelbilder/"] zu wenig produkte 33
    };

    const urlPath = window.location.pathname.toLowerCase();

    const category = this.categoryFromLookupTable(lookupTableOttoversand);
    if (category) {
      return category;
    }

    //Fallback if its a child category, which is not in our lookuptable
    if (urlPath.indexOf("/damen/damenschuhe") > -1) {
      return "damenschuhe";
    }

    if (urlPath.indexOf("/damen/damenmode/pullover/") > -1) {
      return "damenpullover";
    }
    if (urlPath.indexOf("/damen/damenmode/kleider/") > -1) {
      return "damenkleider";
    }
    if (urlPath.indexOf("/damen/damenmode/jacken/") > -1) {
      return "damenjacken";
    }
    if (urlPath.indexOf("/damen/damenmode/sweatshirts-jacken/") > -1) {
      return "damensweatshirts & sweatjacken";
    }

    if (urlPath.indexOf("/wohnen/dekoration/weihnachtsdekoration") > -1) {
      return "weihnachtsdekoration";
    }

    if (urlPath.indexOf("/wohnen/heimtextilien/teppiche/") > -1) {
      return "teppiche";
    }

    if (urlPath.indexOf("/wohnen/dekoration/bilder/") > -1) {
      return "bilder";
    }

    //Searchpage: show Category if matching category from searchstring:
    // TODO currently we don't show the Widget on the Search Result page
    // if (urlPath.startsWith("/s/")) {
    //   for (const categoryKey of Object.keys(lookupTableOttoversand)) {
    //     if (
    //       urlPath.indexOf(categoryKey) > -1 ||
    //       urlPath.indexOf(categoryKey.replace("damen", "")) > -1 ||
    //       urlPath.indexOf(categoryKey.replace("herren", "")) > -1
    //     ) {
    //       return categoryKey;
    //     }
    //   }

    //   if (urlPath.indexOf("poster") > -1 || urlPath.indexOf("bild") > -1) {
    //     return "bilder";
    //   }

    //   if (urlPath.indexOf("teppich") > -1) {
    //     return "teppiche";
    //   }

    //   if (urlPath.indexOf("weihnachtsdeko") > -1) {
    //     return "weihnachtsdekoration";
    //   }

    //   if (urlPath.indexOf("mantel") > -1) {
    //     return "damenmäntel";
    //   }

    //   if (urlPath.indexOf("handtasche") > -1) {
    //     return "damenhandtaschen";
    //   }

    //   if (urlPath.indexOf("kommode") > -1) {
    //     return "kommoden";
    //   }

    //   if (urlPath.indexOf("sofa") > -1 || urlPath.indexOf("couch") > -1) {
    //     return "einzelsofas";
    //   }

    //   if (urlPath.indexOf("bikini") > -1) {
    //     return "damenbikinis";
    //   }

    //   if (urlPath.indexOf("uhr") > -1) {
    //     return "damenuhren";
    //   }

    //   if (urlPath.indexOf("armband") > -1) {
    //     return "damenarmbänder";
    //   }

    //   if (urlPath.indexOf("fußkette") > -1) {
    //     return "damenfußkettchen";
    //   }

    //   if (urlPath.indexOf("halskette") > -1) {
    //     return "damenhalsketten";
    //   }

    //   if (urlPath.indexOf("ohrring") > -1) {
    //     return "damenohrringe";
    //   }

    //   if (urlPath.indexOf("ring") > -1) {
    //     return "damenringe";
    //   }

    //   if (urlPath.indexOf("kommode") > -1) {
    //     return "kommoden";
    //   }

    //   if (urlPath.indexOf("sideboard") > -1) {
    //     return "kommoden & sideboards";
    //   }

    //   if (urlPath.indexOf("hemd") > -1) {
    //     return "herrenhemden";
    //   }

    //   if (urlPath.indexOf("tshirt") > -1 || urlPath.indexOf("t-shirt") > -1) {
    //     return "damentshirts";
    //   }
    // }

    //Else: Hide Widget
    return "";
  }

  // 69018705 - https://www.ottoversand.at/p/machalke-2-5-sitzer-vivien/AKLBB845387906?lang=de_CH&sku=6901870542-0#sku=6901870542-0
  public extractPDPItemIdFromFromUrl(
    urlQueryString = window.location.search
  ): string {
    const urlParams = new URLSearchParams(urlQueryString);

    let sku = urlParams.get("sku");
    if (sku) {
      sku = sku.split("#")[0];

      if (sku.includes("-")) {
        // wenn es einen bindestrich in der SKU hat, ist der erste Teil direkt unsere itemid
        return sku.split("-")[0];
      }

      // ansonsten ist unsere ID die SKU minus die letzten beiden Ziffern
      return sku.substring(0, sku.length - 2);
    }

    return "";
  }

  public extractPDPProductIdFromFromUrl(): string {
    const pathParts = window.location.pathname.split("/");
    return "otv-" + pathParts[pathParts.length - 1];
  }

  overrideEntrypoint(givenEntrypoint: string | undefined): string | undefined {
    if (
      (givenEntrypoint === "plp-unten" || givenEntrypoint === "plp-mitte") &&
      window.location.pathname.startsWith("/s/")
    ) {
      return "plp-search";
    }
    return givenEntrypoint;
  }

  private categoryFromLookupTable(lookupTable: object): string {
    for (const key in lookupTable) {
      if (
        //@ts-ignore
        lookupTable[key].includes(window.location.pathname)
      ) {
        return key;
      }
    }

    return "";
  }
}
