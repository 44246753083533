
















import { Component } from "vue-property-decorator";
import IconButton from "../IconButton.vue";
import InnoFindVue from "../InnoFindVue";

@Component({
  components: { IconButton }
})
export default class PlpRateHintHeader extends InnoFindVue {}
