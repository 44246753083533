import { GoogleTagManagerService } from "@/google-tag-manager/google-tag-manager-service";
import { MtmService } from "@/services/mtm-service";
import { User } from "@/user/user";
import { Vendor } from "@/vendor/vendor";
import AbTest from "./abtest";

export default class AbTestGAWholeWidget extends AbTest {
  private constructor(
    private readonly userId: string,
    options: object[],
    private readonly abtestNamePostfix: "" | "-pdp",
    mtmService: MtmService,
    private readonly googletagManagerService: GoogleTagManagerService
  ) {
    super(
      "whole_widget", // This name has to be unique across all the tests
      options,
      mtmService
    );
  }

  static of(
    vendor: Vendor,
    entrypoint: string | undefined,
    user: User,
    mtmService: MtmService,
    googleTagManagerService: GoogleTagManagerService
  ): AbTestGAWholeWidget {
    if (new URL(document.location.href).searchParams.get("innoforce")) {
      console.log("disable innofind ab test because of innoforce param");
      return AbTestGAWholeWidget.alwaysOriginal(
        user.userId,
        mtmService,
        googleTagManagerService
      );
    }

    return vendor.abTestWholeWidget &&
      (entrypoint?.startsWith("plp") || entrypoint === "pdp")
      ? AbTestGAWholeWidget.unifiedDistribution(
          user.userId,
          mtmService,
          googleTagManagerService,
          AbTestGAWholeWidget.abtestNamePrefixFromEntrypoint(entrypoint)
        )
      : AbTestGAWholeWidget.alwaysOriginal(
          user.userId,
          mtmService,
          googleTagManagerService
        );
  }
  static abtestNamePrefixFromEntrypoint(entrypoint: string): "-pdp" | "" {
    switch (entrypoint) {
      case "pdp":
        return "-pdp";
      default:
        return "";
    }
  }

  public static unifiedDistribution(
    userId: string,
    mtmService: MtmService,
    googletagManagerService: GoogleTagManagerService,
    abtestNamePostfix: "" | "-pdp"
  ): AbTestGAWholeWidget {
    return new AbTestGAWholeWidget(
      userId,
      [
        {
          name: "Original", // display widget
          weight: 0.5 // If not set, the default value is 0.5
        },
        {
          name: "hide_widget", // hide widget
          weight: 0.5 // If not set, the default value is 0.5
        }
      ],
      abtestNamePostfix,
      mtmService,
      googletagManagerService
    );
  }

  public static alwaysOriginal(
    userId: string,
    mtmService: MtmService,
    googletagManagerService: GoogleTagManagerService
  ): AbTestGAWholeWidget {
    const abTest = new AbTestGAWholeWidget(
      userId,
      [
        {
          name: "Original",
          weight: 1 // If not set, the default value is 0.5
        }
      ],
      "",
      mtmService,
      googletagManagerService
    );
    abTest.sendToServer = function() {
      return;
    }; // dont send abTest to server if always original is used
    abTest.sendGaEvent = function() {
      return;
    }; // dont send abTest ga events if always original is used
    return abTest;
  }

  googleAnalyticsEventName(userid: string): string | undefined {
    switch (this.getGroup(userid)) {
      case "hide_widget":
        return `innofind${this.abtestNamePostfix}-b`;
      case "Original":
        return `innofind${this.abtestNamePostfix}-a`;
      default:
        return undefined;
    }
  }

  sendToServer(userid: string): void {
    // dont send to matomo
    return;
  }

  sendGaEvent(
    userid: string,
    postfix: "-visible" | "",
    category: string
  ): void {
    // if (this.abtestNamePostfix === "-home" && postfix === "") {
    //   // don't send GA event for all users that load startpage widget
    //   return;
    // }
    if (this.abtestNamePostfix === "-pdp" && postfix === "") {
      // don't send GA event for all users that load detail page
      return;
    }

    this.googletagManagerService.sendEventToDatalayer(
      "event",
      this.googleAnalyticsEventName(userid) + postfix,
      category
    );
  }

  getGroup(userid: string, forceSendToServer?: boolean): string {
    return super.getGroup(userid, false);
  }

  public hideWholeWidget(): boolean {
    return this.getGroup(this.userId) === "hide_widget";
  }
}
