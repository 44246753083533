import { Observable, Subject } from "rxjs";
import InnoFindVue from "../InnoFindVue";
import http from "./../../http-commons";
import { CursorInteractionDto } from "./dtos/cursor-interaction.dto";

import ProductTile from "./product-tile";
import InnoFindWidgetAgent from "./innofindwidget-agent";
import CursorInteragtionsAgent from "./interaction.agent";

export default class PLPCursorAgent extends InnoFindVue
  implements CursorInteragtionsAgent {
  private static readonly MIN_MOUSEOVER_SECONDS = 0.5;
  public static readonly MAX_MOUSEOVER_SECONDS = 10;
  private static readonly UPDATE_INTERACTIONS_INTERVALL_MS = 5000;

  private readonly innofindWidgetAgent = new InnoFindWidgetAgent(); // ToDo configurable e.g. in vendor.ts

  private readonly productTiles: Map<string, ProductTile> = new Map();

  private $interval: number | undefined;

  private sessionid: string | undefined;

  private lastSendCursorInteractions: CursorInteractionDto[] = [];

  private userInteractionsSubject = new Subject<CursorInteractionDto[]>();
  constructor() {
    super();
  }

  public start() {
    setTimeout(() => this.attachEventListenersToProductTiles(), 1000);

    this.$interval = setInterval(() => {
      const cursorInteractions = this.relevantCursorInteractions();
      if (
        cursorInteractions.length > 0 &&
        JSON.stringify(cursorInteractions) !==
          JSON.stringify(this.lastSendCursorInteractions)
      ) {
        this.send();
        this.userInteractionsSubject.next(cursorInteractions);
      }

      this.attachEventListenersToProductTiles(); // to enure that dynamic loaded tiles also get event listeners
    }, PLPCursorAgent.UPDATE_INTERACTIONS_INTERVALL_MS);
  }

  cursorInteractionUpdates(): Observable<CursorInteractionDto[]> {
    return this.userInteractionsSubject.asObservable();
  }

  public send() {
    const cursorInteractions = this.relevantCursorInteractions();
    http
      .post("/usertaste/productlist/crs", {
        userid: this.$user.userId,
        sessionid: this.sessionid,
        vendor: this.$vendor.vendorname,
        cursorInteractions: cursorInteractions
      })
      .then(_ => (this.lastSendCursorInteractions = cursorInteractions));
  }

  private removeAllNotConnectedProductTiles() {
    this.productTiles.forEach((productTile: ProductTile, key: string) => {
      if (productTile.notInDom()) {
        productTile.removeEventListener();
        this.productTiles.delete(key);
      }
    });
  }
  private attachEventListenersToProductTiles() {
    this.removeAllNotConnectedProductTiles();

    this.innofindWidgetAgent
      .findAllProductTiles()
      .forEach((htmlElement: HTMLElement) => {
        const productid = htmlElement.dataset["producttile"]!;

        if (!this.productTiles.has(productid)) {
          this.productTiles.set(productid, new ProductTile(htmlElement));
        }
      });
  }

  setSessionid(sessionid: string): void {
    if (this.sessionid) {
      console.warn("sessionid was already set!");
    }
    this.sessionid = sessionid;
  }

  public relevantCursorInteractions(): CursorInteractionDto[] {
    const cursorInteractions: CursorInteractionDto[] = [];

    this.productTiles.forEach((productTile, key) => {
      cursorInteractions.push(productTile.cursorInteraction());
    });
    return cursorInteractions.filter(
      (cursorInteraction: CursorInteractionDto) =>
        cursorInteraction.seconds >= PLPCursorAgent.MIN_MOUSEOVER_SECONDS
    );
  }

  beforeDestroy() {
    this.stop();
  }

  stop() {
    clearInterval(this.$interval);

    for (const productTile of this.productTiles.values()) {
      productTile.removeEventListener();
    }
  }
}
