import axios from "axios";

export default axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL, // "http://localhost:3000/v1"
  headers: {
    "Content-type": "application/json",
    "X-API-KEY": "wdgBkGXuS6Aa3"
  }
});

export const monitoringBackend = axios.create({
  baseURL: process.env.VUE_APP_MONITORING_URL,
  headers: {
    "Content-type": "application/json"
  }
});
