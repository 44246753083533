import { InteractionsDto } from "@/components/agent/interaction.agent";
import InnoFindVue from "@/components/InnoFindVue";
import { shuffled } from "@/helper/utils";
import { Rating } from "@/models/rating";
import { from, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import http from "../http-commons";
import { IteminfoDto } from "./iteminfos-service";

interface PredictRequestDto {
  vendor: string;
  subcategory: string;
  ratings: Rating[];
  sessionId: string;
  userId: string;
  firstRequest: boolean;
  predictionCharacter: PredictionCharacter;
  initialProductOrder: string[];
  interactionDto?: InteractionsDto;
}

export enum PredictionCharacter {
  DEFAULT = "DEFAULT",
  PDP = "PDP"
}

export class PredictService extends InnoFindVue {
  public predictModel(
    sessionId: string,
    userId: string,
    ratings: Rating[],
    category: string,
    predictionCharacter: PredictionCharacter = PredictionCharacter.DEFAULT,
    firstRequest?: boolean,
    ignoreInteractions?: boolean, // to speed up the first request
    initialProductOrder?: string[],
    interactionDto?: InteractionsDto,
    predictModelStrategy?: "smart" | "strict"
  ): Observable<string[]> {
    const params = new URLSearchParams();

    if (ignoreInteractions) {
      params.append("ignoreInteractions", ignoreInteractions.toString()); // TODO check if workds
    }

    const limitResultsPerParentId = this.$vendor.predictLimitSameProductIdPerResponseFor(
      predictionCharacter
    );
    if (limitResultsPerParentId) {
      params.append("limitSameProductId", limitResultsPerParentId.toString());
    }

    if (predictModelStrategy) {
      params.append("predictModelStrategy", predictModelStrategy);
    }

    return from(
      http.post(`/predict?${params.toString()}`, {
        subcategory: category,
        vendor: this.$vendor.vendorname,
        sessionId: sessionId,
        userId: userId,
        ratings: ratings.filter(it => it.rating !== 0), // if you remove this first test szenario like and dislike the same product
        firstRequest: firstRequest,
        predictionCharacter: predictionCharacter,
        initialProductOrder:
          ratings.length == 0 ? initialProductOrder : undefined, // performance optimization - initialProductOrder only necessary if no rating is given
        interactionDto: interactionDto
      } as PredictRequestDto)
    ).pipe(map(result => result.data));
  }

  loadTastePredictions(
    sessionId: string,
    userId: string,
    ratings: Rating[],
    category: string
  ): Observable<string[]> {
    if (!ratings) {
      return of([]);
    }

    return from(
      http.post(`/suggestions`, {
        subcategory: category,
        vendor: this.$vendor.vendorname,
        sessionId: sessionId,
        userId: userId,
        ratings: ratings.filter(it => it.rating !== 0), // if you remove this first test szenario like and dislike the same product
        firstRequest: false,
        predictionCharacter: PredictionCharacter.DEFAULT,
        initialProductOrder: [], // performance optimization - initialProductOrder only necessary if no rating is given
        interactionDto: undefined
      } as PredictRequestDto)
    ).pipe(
      map(result => {
        return result.data;
      })
    );
  }

  loadSimilarImageIds(
    vendor: string,
    category: string,
    imgExtId: string,
    limit?: number
  ): Observable<string[]> {
    if (!imgExtId) {
      return of([]);
    }
    return from(
      http.get(
        `/similar/${vendor}/${category}/${imgExtId}?lang=${this.$user.lang}&limit=${limit}`
      )
    ).pipe(
      map(result => result.data),
      map((iteminfos: IteminfoDto[]) => iteminfos.map(it => it.itemid))
    );
  }
}
